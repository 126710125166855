<div [appLoading]="isLoading" [loaderColor]='loaderSelector' class="projects">
  <div class="projects__approval-workflow" *ngIf="resources.ApprovalWorkflow.Visible == 'Y' && appService.isApprover">
    <app-pending-scenario-list [statusOptions]="statusOptions.options">
    </app-pending-scenario-list>
  </div>
  <div class="projects__header-row zs-card" active>
    <div class="filters-group">
      <div class="filter">
        <p-is-zs-select #mktDpDwn  class="zs-action-field zs-select" mode="action" hide-on-scroll style="max-width: 100%"
          label="Market" *ngIf="TherapyBrands?.length>0" (change)="franchises.selected = mktDpDwn.getValue();allProjectsBrands=[];competitors.selected=''; getFilteredProjects('');" clear>
          <select>
            <option value="" [attr.selected]="franchises?.selected == '' || undefined">All Markets</option>

            <option *ngFor="let therapyOption of TherapyBrands" [value]="therapyOption.Therapy_Area" [attr.selected]="franchises?.selected == therapyOption.Therapy_Area || undefined">
              {{therapyOption.Therapy_Area}}</option>

          </select>

        </p-is-zs-select>
      </div>

      <div class="filter">
        <p-is-zs-select #brandDpDwn  *ngIf="allProjectsBrands?.length>0" class="zs-action-field zs-select" mode="action" hide-on-scroll
          [label]="resourceFields['Brand'].text" (change)="competitors.selected = brandDpDwn.getValue(); getFilteredProjects('');" clear>
          <select>
            <option value="" [attr.selected]="competitors?.selected == '' || undefined">All Brands</option>

              <option *ngFor="let brand of allProjectsBrands" [value]="brand" [attr.selected]="competitors?.selected == brand || undefined">{{brand}} </option>

          </select>
        </p-is-zs-select>

      </div>
      <div class="filter" *ngIf="loggedInUser.team == 'UBC'">
        <p-is-zs-select #channelDpDwn  *ngIf="allProjectsChannels?.length>0" class="zs-action-field zs-select" mode="action" hide-on-scroll
          label="Channel" (change)="channels.selected = channelDpDwn.getValue(); getFilteredProjects('');" clear>
          <select>
            <option value="">All Channels</option>

              <option *ngFor="let channels of allProjectsChannels" [value]="channels">{{channels}} </option>

          </select>
        </p-is-zs-select>
      </div>
      <div class="filter">

        <p-is-zs-select #statusDpDwn  *ngIf="statuses.options?.length>0" class="zs-action-field zs-select" mode="action" hide-on-scroll
          label="Status" (change)="statuses.selected = statusDpDwn.getValue(); getFilteredProjects('');" clear>
          <select>
            <option value="">All Status</option>

              <option *ngFor="let option of statuses?.options | arrayFilter: {Is_Approval_Specific: 'N'}" [value]="option.Status">{{option.Status}} </option>

          </select>
        </p-is-zs-select>
      </div>
    </div>

    <div class="project-search-and-btn">
    <div class="search">
      <!-- <p-is-zs-action-field class="zs-action-field zs-margin-1 zs-action-field-solid zs-border" type="text"
        placeholder="Search..." autocomplete="off" icon="search" action-icon="collapse" [(ngModel)]="searchText"
        (input)="getFilteredProjects('search')" (keypress)="commonService.nameCheck($event)" ngDefaultControl>
      </p-is-zs-action-field> -->
      <p-is-zs-action-field class="zs-action-field zs-margin-1 zs-action-field-solid zs-border" type="text"
      placeholder="Search..." autocomplete="off" icon="search" action-icon="collapse" [(ngModel)]="searchText"
      (input)="onSearch('search')" (keypress)="commonService.nameCheck($event)" ngDefaultControl>
    </p-is-zs-action-field>
    </div>
    <div class="button--cta">
      <button class="zs-button zs-button-solid zs-border-rounded-0" (click)="openCreateProjectModal()"
        *ngIf="resources.Create_Project.Visible=='Y'">Create Project</button>
    </div>
    </div>

  </div>
  <div class="projects__project-table cds-bg-white zs-padding-0-2-2-2">
    <div class="table-aids zs-text-align-right">
      <a href="javascript:void(0)" title="Copy to Clipboard" (click)="copyToExcel('ProjectsGrid',true)">
        <span class="zs-icon zs-size-xl zs-icon-note zs-margin-0-0-0-1"></span>
        <span >Copy</span>
      </a>

    <table class="zs-table zs-table-condense">
      <thead>
        <tr>
          <th></th> <!-- bookmarkicon -->
          <th></th> <!-- expand collapse -->
          <th><a [attr.sort]="sortColumnForParentTable == 'ProjectName' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('ProjectName'); getFilteredProjects('');">Project </a> </th>
          <th><a [attr.sort]="sortColumnForParentTable == 'BrandName' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('BrandName'); getFilteredProjects('');">{{resourceFields['Brand'].text}}
            </a> </th>
          <th *ngIf="loggedInUser.team == 'UBC'"><a
              [attr.sort]="sortColumnForParentTable == 'Channel' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('Channel'); getFilteredProjects('');">Channel </a> </th>
          <th *ngIf="loggedInUser.team == 'PBC'"><a
              [attr.sort]="sortColumnForParentTable == 'ContractType' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('ContractType'); getFilteredProjects('');">Contract Type </a> </th>
          <th><a [attr.sort]="sortColumnForParentTable == 'CreatedBy' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('CreatedBy'); getFilteredProjects('');">Project Owner</a> </th>
          <th><a
              [attr.sort]="sortColumnForParentTable == 'NoOfScenarios' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('NoOfScenarios'); getFilteredProjects('');">#Scenarios </a> </th>
          <th><a
              [attr.sort]="sortColumnForParentTable == 'DateModified' ? sortOrderForParentTable ? 'desc' : 'asc' : ''"
              (click)="performSortForParentTable('DateModified'); getFilteredProjects('');">Scenarios Modified Date </a>
          </th>
          <th></th>
          <th></th>

        </tr>

      </thead>
      <tbody>
        <ng-container *ngFor="let project of projectsList.myProjects; let parentIdx = index;">
          <tr>
            <td> <a (click)='toggleBookmark(project)' href="javascript:void(0)"> <span
                  class="zs-icon zs-size-xl zs-icon-star"
                  [ngClass]="{'zs-icon-star-fill': project.IsBookmarked}"></span> </a></td> <!-- zs-icon-star-fill -->
            <td> <a (click)="toggleAccordian($event)" href="javascript:void(0)"><span
                  class="zs-icon zs-size-xl zs-icon-carat-down"></span></a>
            </td>
            <td>{{project.ProjectName}}</td>
            <td>{{project.BrandName}}</td>
            <td *ngIf="userLoggedinTeam == 'UBC'">{{project.Channel}}</td>
            <td *ngIf="userLoggedinTeam == 'PBC'">{{project.ContractType}}</td>
            <td>{{project.CreatedBy}}</td>
            <td align="center">{{project.NoOfScenarios}}</td>
            <td align="center">{{project.DateModified | date:'dd MMM yyyy'}}</td>
            <td align="right">
              <a href="javascript:void(0);" (click)='openProjectNotes(project,parentIdx);updateProjectDetailsModal.showModal();'> <span
                  class="zs-icon zs-size-xl zs-icon-data-list"></span></a>
              <!-- update scenario details modal -->
              <zs-dialog #updateProjectDetailsModal class="zs-dialog modal"
                role="dialog" modal>
                <header>
                  <h3 class="zs-text-align-left">Project Details</h3>
                  <span close=""
                    (click)="updateProjectDetailsModal.close()">
                    <a href="javascript:void(0);" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                  </span>
                </header>
                <section >
                  <div class="zs-action-field">
                    <label>Project Name</label>
                    <div>
                      <input input="" name="projectName" type="text" #projNameRef="ngModel"
                        [(ngModel)]="projectNameTemp[parentIdx]" ngDefaultControl (keypress)="commonService.nameCheck($event)" required>
                      <div *ngIf="projNameRef.errors && (projNameRef.dirty || projNameRef.touched)">
                        <span class="zs-text-error zs-size-xs zs-font-weight-semi-bold notes-error">Project name can not be
                          empty</span>
                      </div>
                    </div>
                  </div>

                  <div class="notes-update">
                    <label> Project Notes</label>
                    <div>
                      <p-is-zs-textarea label="" cols="70" rows="6" name="projNotes" ngDefaultControl
                        [(ngModel)]="projectNotesTemp[parentIdx]" class="zs-field" clear>
                      </p-is-zs-textarea>
                    </div>
                  </div>


                </section>
                <footer>
                  <button *ngIf="resources.Save_Project.Visible=='Y'"
                    class="zs-button zs-button-action zs-border-rounded-0"
                    (click)="saveProjectNotes(project,parentIdx);updateProjectDetailsModal.close();"
                    [disabled]='!(projectNameTemp[parentIdx] && projectNameTemp[parentIdx].length)'>Save</button>
                  <a class="zs-button zs-border-rounded-0" (click)="updateProjectDetailsModal.close();">Cancel</a>
                </footer>
              </zs-dialog>

            <td><a (click)='deleteProjectModal.showModal()' href="javascript:void(0);"
                *ngIf="(userLoggedin | uppercase) === (project.CreatedBy | uppercase)"> <span
                  class="zs-icon zs-size-xl zs-icon-delete"></span></a>
            </td>
            <!-- delete project modal -->
            <zs-dialog #deleteProjectModal class="zs-dialog modal" role="dialog" modal>
              <header>
                <h3 class="zs-text-align-left">Delete Project</h3>
                <span close="" (click)="deleteProjectModal.close()">
                  <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                </span>
              </header>
              <section>
                <label class="zs-text-align-center">Are you sure you want to delete this Project ?</label>
              </section>
              <footer>
                <button class="zs-button zs-button-action zs-border-rounded-0"
                  (click)="deleteProject(project.ProjectID, project.DateModified)">Delete</button>
                <a class="zs-button zs-border-rounded-0" (click)='deleteProjectModal.close()'>Cancel</a>
              </footer>
            </zs-dialog>
            <!-- delete project modal ends-->
          </tr>

          <!-- accordion block -->
          <tr class="zs-display-none">
            <td colspan="10">
              <table class="zs-table scenarios-table zs-table-condense">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>

                    <th *ngIf="project.ScenarioList.length > 0"><a
                        [attr.sort]="sortColumnForChildTable == 'ScenarioName' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('ScenarioName', project.ProjectID)">Scenario </a></th>
                    <th *ngIf="project.ScenarioList.length > 0"><a *ngIf="loggedInUser.team == 'UBC'"
                        [attr.sort]="sortColumnForChildTable == 'ChildScenarioCount' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('ChildScenarioCount', project.ProjectID)">Count </a>
                    </th>
                    <th *ngIf="userLoggedinTeam == 'UBC' && project.ScenarioList.length > 0"><a sort=""
                        [attr.sort]="sortColumnForChildTable == 'Payer' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('Payer', project.ProjectID)">Contract
                        Owner
                      </a></th>
                    <th *ngIf="project.ScenarioList.length > 0"><a sort=""
                        [attr.sort]="sortColumnForChildTable == 'Status' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('Status', project.ProjectID)">Status </a></th>
                    <th *ngIf="project.ScenarioList.length > 0"><a sort=""
                        [attr.sort]="sortColumnForChildTable == 'AccessType' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('AccessType', project.ProjectID)">Access </a></th>
                    <th *ngIf="project.ScenarioList.length > 0"><a sort=""
                        [attr.sort]="sortColumnForChildTable == 'CreatedBy' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('CreatedBy', project.ProjectID)">Scenario Owner </a></th>
                    <th *ngIf="project.ScenarioList.length > 0" align="center"><a sort=""
                        [attr.sort]="sortColumnForChildTable == 'DateModified' ? sortOrderForChildTable ? 'desc' : 'asc' : ''"
                        (click)="performSortForChildTable('DateModified', project.ProjectID)">Modified </a></th>
                    <th class="create-scenario-btn">
                      <button class=" zs-button zs-border-rounded-0"
                        *ngIf="resources.Create_Scenario.Visible=='Y' && userLoggedinTeam == 'UBC'"
                        (click)="openCreateScenarioModal(project);createScenarioModal.showModal();"><span
                          class="zs-icon zs-icon-add zs-size-extra-large"></span><span>Create
                          Scenario</span></button>

                      <button class=" zs-button zs-border-rounded-0"
                        *ngIf="resources.Create_Scenario.Visible=='Y' && userLoggedinTeam == 'PBC'"
                        (click)="CreateNewMaster(project);"><span
                          class="zs-icon zs-icon-add zs-size-extra-large"></span><span>Create
                          Scenario</span></button>
                      <!-- create scenario modal -->
                      <zs-dialog #createScenarioModal  class="zs-dialog modal" role="dialog"
                        modal>
                        <header>
                          <h3 class="zs-text-align-left">Scenario Type Selection</h3>
                          <span close=""
                            (click)="project.parentName='';project.scenarioType.selected='';createScenarioModal.close()">
                            <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                          </span>
                        </header>
                        <section>

                          <div class="" *ngIf="userLoggedinTeam == 'UBC' && project.scenarioType">
                            <!-- <label class="zs-font-weight-bold"> SCENARIO TYPE</label>
                            <select [(ngModel)]="project.scenarioType.selected">
                              <option *ngFor="let item of scenarioType.options| keyvalue" [value]="item.key">
                                {{item.value}}
                              </option>

                            </select> -->
                            <p-is-zs-select #scenarioTypeDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll label="Scenario Type"
                              (change)="project.scenarioType.selected = scenarioTypeDpDwn.getValue();"
                              clear>
                              <select>
                                <option *ngFor="let item of scenarioType.options|keyvalue: originalOrder" [value]="item.key">
                                  {{item.value}}</option>
                              </select>
                            </p-is-zs-select>
                          </div>
                          <br/>
                          <div class="zs-action-field"
                            *ngIf="project.scenarioType && project.scenarioType.selected=='Master'">
                            <label>Parent Name</label>
                            <div>
                              <input input="" name="parentName" placeholder="Enter Parent Name" type="text"
                                #parentNameRef="ngModel" [(ngModel)]="project.parentName" (keypress)="commonService.nameCheck($event)" required>
                              <div
                                *ngIf="project.scenarioType.selected=='Master' && parentNameRef.errors && (parentNameRef.dirty || parentNameRef.touched)">
                                <span class="zs-text-error zs-size-xs zs-font-weight-semi-bold">Parent name can not be
                                  empty</span>
                              </div>
                            </div>
                          </div>
                          <hr *ngIf="project.scenarioType && project.scenarioType.selected=='Master'" style="border: none !important;">
                        </section>
                        <footer>
                          <button class="zs-button zs-button-action zs-border-rounded-0"
                            *ngIf="resources.Create_Scenario.Visible=='Y' && project.scenarioType && project.scenarioType.selected!='Master'"
                            (click)="CreateNewMaster(project); ">Create</button>

                          <button class="zs-button zs-button-action zs-border-rounded-0"
                            *ngIf="resources.Create_Scenario.Visible=='Y' && project.scenarioType && project.scenarioType.selected=='Master'"
                            (click)="CreateNewMaster(project);" [disabled]="!project.parentName.length">Create</button>

                          <a class="zs-button zs-border-rounded-0"
                            (click)="project.parentName='';project.scenarioType.selected=''; createScenarioModal.close();">Cancel</a>
                        </footer>
                      </zs-dialog>
                      <!-- create scenario modal ends -->
                    </th>
                  </tr>

                </thead>

                <tbody>
                  <tr
                    *ngFor="let scenario of project.ScenarioList; let scIdx= index">
                    <td></td>
                    <td></td>
                    <td>
                      <a href="javascript:void(0)" (click)="openMasterScenario(scenario, project)"
                        *ngIf="project.Team=='UBC' && scenario.ParentType =='Parent'" class="zs-font-weight-bold">{{scenario.ScenarioName}}</a>
                      <a href="javascript:void(0)" (click)="openScenario(scenario.ScenarioID,project)"
                        *ngIf="project.Team=='UBC' && scenario.ParentType !='Parent'" class="zs-font-weight-bold">{{scenario.ScenarioName}}</a>

                      <a href="javascript:void(0)" (click)="openPBCScenario(scenario.ScenarioID, scenario.ScenarioVersion)"
                        *ngIf="project.Team=='PBC'" class="zs-font-weight-bold">{{scenario.ScenarioName}}</a>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0)" *ngIf="project.Team=='UBC'"
                         (click)='scenario.ChildScenarioList.length && openMasterScenariosModal.showModal();'>{{scenario.ChildScenarioList.length == 0? '-':scenario.ChildScenarioCount}}</a>
                         <!-- [ngClass]="{'zs-display-none': scenario.ParentType !='Parent'}" -->
                         <!-- <a href="javascript:void(0)" ng-if="project.Team=='PBC'"
                        [ngClass]="{'zs-display-none': scenario.ChildScenarioCount <= 1}">{{scenario.ChildScenarioCount}}</a> -->

                        <!-- open master scenario modal -->
                      <zs-dialog #openMasterScenariosModal class="zs-dialog modal" role="dialog"
                      modal>
                      <header>
                        <h3 class="zs-text-align-left">Child Scenarios</h3>
                        <span close="" (click)="openMasterScenariosModal.close()">
                          <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                        </span>
                      </header>
                      <section>
                        <div *ngFor="let childScenario of scenario.ChildScenarioList">
                          <div class="child-scenario-margion">
                            <a href="javascript:void(0)"
                                  (click)="openScenario(childScenario.ScenarioID,project)">{{childScenario.ScenarioName}}</a>
                          </div>
                        </div>
                      </section>
                    </zs-dialog>
                    <!-- open master summary scenario ends-->


                      <!-- a zs modal to exist here -->
                    </td>
                    <td *ngIf="userLoggedinTeam == 'UBC'">{{scenario.Payer}}</td>
                    <td>
                      <div class="zs-display-flex">
                        <div>{{scenario.Status}}</div>
                      <div class="padding-left-05"  *ngIf="!(project.Team=='PBC' && scenario.ScenarioVersion < 7) && (resources.ApprovalWorkflow.Visible == 'N' || scenario.ScenarioVersion < 12)">     <a href="javascript:void(0)"
                        (click)='updateScenarioStatusModal.showModal();'> <span
                          class="zs-icon zs-size-xl zs-icon-edit"></span></a></div>
                      </div>




                      <!-- update scenario status modal  -->
                      <zs-dialog #updateScenarioStatusModal  class="zs-dialog modal update-scenario-status"
                        role="dialog" modal>
                        <header>
                          <h3 class="zs-text-align-left">Scenario Type Selection</h3>
                          <span close="" (click)="updateScenarioStatusModal.close()">
                            <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                          </span>
                        </header>
                        <section>

                          <div>
                            <label class="zs-font-weight-bold zs-font-large">Current Status: </label>
                            <div>{{scenario.Status}}</div>
                          </div>
                          <br/>
                          <div>
                            <p-is-zs-select #statusDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll label=" Update To: "
                              (change)="statusOptions.selected = statusDpDwn.getValue();" clear>
                              <select>
                                <option value="">Select Status</option>
                                <option *ngFor="let option of statusOptions.options | arrayFilter: {Is_Approval_Specific: 'N'}" [value]="option.Status">
                                  {{option.Status}}</option>
                              </select>
                            </p-is-zs-select>
                          </div>

                        </section>
                        <footer>
                          <button class="zs-button zs-button-action zs-border-rounded-0"
                            (click)="updateStatus(scenario);updateScenarioStatusModal.close();" [ngClass]="{'zs-disabled': statusOptions.selected == ''}">Update</button>

                          <a class="zs-button zs-border-rounded-0" (click)="updateScenarioStatusModal.close();">Cancel</a>
                        </footer>
                      </zs-dialog>
                      <!-- update scenario status modal ends  -->
                    </td>

                    <td>{{scenario.AccessType}}</td>
                    <td>{{scenario.CreatedBy}}</td>
                    <td align="center">{{scenario.DateModified | date:'dd MMM yyyy'}}</td>
                    <td align="right">
                      <a href="javascript:void(0);" *ngIf="!(project.Team=='PBC' && scenario.ScenarioVersion < 7)" (click)='openScenarioNotes(scenario,parentIdx,scIdx);updateScenarioDetailsModal.showModal();'> <span
                          class="zs-icon zs-size-xl zs-icon-data-list"></span></a>
                      <!-- update scenario details modal -->
                      <zs-dialog #updateScenarioDetailsModal class="zs-dialog modal"
                        role="dialog" modal>
                        <header>
                          <h3 class="zs-text-align-left">Scenario Details</h3>
                          <span close=""
                            (click)="updateScenarioDetailsModal.close()">
                            <a href="javascript:void(0);" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                          </span>
                        </header>
                        <section >
                          <div class="zs-action-field">
                            <label>Scenario Name</label>
                            <div>
                              <input input="" name="scenarioName" type="text" #scNameRef="ngModel"
                                [(ngModel)]="scenarioNameTemp[parentIdx][scIdx]" ngDefaultControl (keypress)="commonService.nameCheck($event)" required>
                              <div *ngIf="scNameRef.errors && (scNameRef.dirty || scNameRef.touched)">
                                <span class="zs-text-error zs-size-xs zs-font-weight-semi-bold notes-error">Scenario name can not be
                                  empty</span>
                              </div>
                            </div>
                          </div>

                          <div class="notes-update">
                            <label> Scenario Notes</label>
                            <div>
                              <p-is-zs-textarea label="" cols="70" rows="6" name="scNotes" ngDefaultControl
                                [(ngModel)]="scenarioNotesTemp[parentIdx][scIdx]" class="zs-field" clear>
                              </p-is-zs-textarea>
                            </div>
                          </div>


                        </section>
                        <footer>
                          <button *ngIf="resources.Save_Scenario.Visible=='Y'"
                            class="zs-button zs-button-action zs-border-rounded-0"
                            (click)="saveScenarioNotes(scenario,parentIdx,scIdx);updateScenarioDetailsModal.close();"
                            [disabled]='!(scenarioNameTemp[parentIdx][scIdx] && scenarioNameTemp[parentIdx][scIdx].length)'>Save</button>

                          <a class="zs-button zs-border-rounded-0" (click)="updateScenarioDetailsModal.close();">Cancel</a>
                        </footer>
                      </zs-dialog>
                      <!-- update scenario detail modal ends  -->
                      <a href="javascript:void(0);"
                        *ngIf="resources.Share_Scenario.Visible==='Y' && (scenario.CreatedBy | uppercase)  === (userLoggedin | uppercase) && !(project.Team=='PBC' && scenario.ScenarioVersion < 7)"
                        (click)='shareScenarioModal.showModal();'> <span
                          class="zs-icon zs-size-xl zs-icon-team"></span></a>
                      <!-- share scenario  modal -->
                      <zs-dialog #shareScenarioModal  class="zs-dialog modal update-scenario-status" role="dialog"
                        modal>
                        <header>
                          <h3 class="zs-text-align-left">Share Scenario </h3>
                          <span close="" (click)="shareScenarioModal.close()">
                            <a href="javascript:void(0);" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                          </span>
                        </header>
                        <section>
                          <div>
                            <p-is-zs-select #selectUserDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll label=" Select user to share with: "
                              (change)="users.selected = selectUserDpDwn.getValue();" clear>
                              <select>
                                <option *ngFor="let option of shareScenarioUsers" [value]="option.UserName">
                                  {{option.UserName}}</option>
                              </select>
                            </p-is-zs-select>
                          </div>
                        </section>
                        <footer>
                          <button *ngIf="resources.Save_Scenario.Visible=='Y'" [disabled]="!users.selected"
                            class="zs-button zs-button-action zs-border-rounded-0"
                            (click)="shareScenario(scenario);shareScenarioModal.close()">Share</button>

                          <a class="zs-button zs-border-rounded-0" (click)="shareScenarioModal.close();">Cancel</a>
                        </footer>
                      </zs-dialog>
                      <!-- share scenario  modal ends  -->
                      <a href="javascript:void(0);" (click)="deleteScenarioModal.showModal();"
                        *ngIf="(scenario.CreatedBy | uppercase)  === (userLoggedin | uppercase)"> <span
                          class="zs-icon zs-size-xl zs-icon-delete"></span></a>
                      <!-- delete scenario modal -->
                      <zs-dialog #deleteScenarioModal  class="zs-dialog modal" role="dialog"
                        modal>
                        <header>
                          <h3 class="zs-text-align-left">Delete Scenario</h3>
                          <span close="" (click)="deleteScenarioModal.close()">
                            <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
                          </span>
                        </header>
                        <section>
                          <label class="zs-text-align-center">Are you sure you want to delete this Scenario ?</label>
                        </section>
                        <footer>
                          <button class="zs-button zs-button-action zs-border-rounded-0"
                            (click)="deleteScenario(scenario)">Delete</button>
                          <a class="zs-button zs-border-rounded-0" (click)='deleteScenarioModal.close()'>Cancel</a>
                        </footer>
                      </zs-dialog>
                      <!-- delete scenario modal ends-->

                    </td>
                    <!-- <td></td> -->
                  </tr>
                </tbody>

              </table>
            </td>
          </tr>
          <!-- accordion block ends-->
        </ng-container>
      </tbody>

    </table>
    <div class="projects__project-table__pagination" *ngIf="!resetProjectPagination">
      <span id="pagination-status">Showing {{paginationOptions.currentPage !=0?((paginationOptions.currentPage - 1)*paginationOptions.pageSize + 1):0}} - {{(paginationOptions.currentPage*paginationOptions.pageSize)>paginationOptions.itemsCount?paginationOptions.itemsCount:(paginationOptions.currentPage*paginationOptions.pageSize)}}
        of
        {{paginationOptions.itemsCount}}&nbsp;&nbsp;&nbsp;</span>
      <zs-pagination id="zsProjectPagination" *ngIf="paginationOptions.itemsCount>0" (pagechange)="pageChange($event)" class="zs-pagination" mode="action"
        [itemsCount]="paginationOptions.itemsCount" [maxDisplayPages]="paginationOptions.maxDisplayPages"
        [pageSize]="paginationOptions.pageSize" [currentPage]="paginationOptions?.currentPage">
      </zs-pagination>
    </div>

  </div>

</div>

<!-- create project modal -->
<zs-dialog #createProjectModal id="createProjectModal" class="zs-dialog modal" role="dialog" modal>
  <header>
    <h3 class="zs-text-align-left">Create New Project</h3>
    <span close="" (click)="closeModal();">
      <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
    </span>
  </header>
  <section *ngIf="openProjectFlag" >

    <div class="zs-action-field project-name">
      <label>Project Name</label>
      <div>
        <input input="" name="projectName" placeholder="Enter Project Name" type="text" #projectNameRef="ngModel"
          [(ngModel)]="projectName" (keypress)="commonService.nameCheck($event)" required>
        <div *ngIf="projectNameRef.errors && (projectNameRef.dirty || projectNameRef.touched)">
          <span class="zs-text-error zs-size-xs zs-font-weight-semi-bold">Project name can not be empty</span>
        </div>
      </div>
    </div>

    <div class="zs-action-field dropdown">
      <p-is-zs-select #therapyDpDwn *ngIf="TherapyBrands?.length>0 && userLoggedinTeam == 'UBC'"  style="max-width: 100%" class="zs-action-field zs-select" mode="action" hide-on-scroll
        [label]="resourceFields['TherapyArea'].text" (change)="therapyArea.selected = therapyDpDwn.getValue(); defaultSelectProductGroupType(); renderCreateProjectDpDwns();">
        <select>
          <option *ngFor="let option of TherapyBrands | unique:'Therapy_Area'" [value]="option.Therapy_Area">
            {{option.Therapy_Area}}</option>
        </select>
      </p-is-zs-select>
      <p-is-zs-select #therapyDpDwn *ngIf="TherapyBrands?.length>0 && userLoggedinTeam == 'PBC'" class="zs-action-field zs-select" mode="action" hide-on-scroll
        [label]="resourceFields['TherapyArea'].text" (change)="therapyArea.selected = therapyDpDwn.getValue(); defaultSelectBrand(); renderCreateProjectDpDwns();" style="max-width: 100%">
        <select>
          <option *ngFor="let option of TherapyBrands | unique:'Therapy_Area'" [value]="option.Therapy_Area">
            {{option.Therapy_Area}}</option>
        </select>
      </p-is-zs-select>
      <!-- <label class="zs-font-weight-bold"> {{resourceFields['TherapyArea'].text | uppercase}}</label>
      <select [(ngModel)]="therapyArea.selected" *ngIf="userLoggedinTeam == 'UBC'"
        (change)="defaultSelectProductGroupType()">
        <option *ngFor="let options of TherapyBrands | unique:'Therapy_Area'" [value]="options.Therapy_Area">
          {{options.Therapy_Area}} </option>
      </select> -->
      <!-- <select [(ngModel)]="therapyArea.selected" *ngIf="userLoggedinTeam == 'PBC'" (change)="defaultSelectBrand()">
        <option *ngFor="let options of TherapyBrands | unique:'Therapy_Area'" [value]="options.Therapy_Area">
          {{options.Therapy_Area}} </option>
      </select> -->
      <!-- <p-is-zs-select class="zs-action-field zs-select" mode="action" hide-on-scroll label="Market" clear>
      <select [(ngModel)]="franchises.selected">
        <option *ngFor="let therapyOption of TherapyBrands" [value]="therapyOption.Therapy_Area">
          {{therapyOption.Therapy_Area}} </option>
        <option value="">All Markets</option>
      </select>

    </p-is-zs-select> -->
    </div>
    <!-- <hr class="project-hr"> -->


    <div class="zs-action-field dropdown" *ngIf="userLoggedinTeam == 'UBC'">
      <!-- <label class="zs-font-weight-bold"> PRODUCT GROUP TYPE</label>
      <select [(ngModel)]="productType.selected" (change)="defaultSelectBrand();">
        <option
          *ngFor="let options of TherapyBrands| arrayFilter: {Therapy_Area: therapyArea.selected} | unique:'Product_Type' "
          [value]="options.Product_Type">
          {{options.Product_Type}}</option>
      </select> -->
      <p-is-zs-select *ngIf="TherapyBrands?.length>0 && isRenderCreateProjectDpDwn" #productDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll
        label="Product Group Type" (change)="productType.selected = productDpDwn.getValue(); defaultSelectBrand(); renderCreateProjectDpDwns();" style="max-width: 100%">
        <select>
          <option *ngFor="let option of TherapyBrands | arrayFilter: {Therapy_Area: therapyArea.selected} | unique:'Product_Type'" [value]="option.Product_Type">
            {{option.Product_Type}}</option>
        </select>
      </p-is-zs-select>
    </div>

    <!-- <hr class="project-hr" *ngIf="userLoggedinTeam == 'UBC'"> -->

    <div class="zs-action-field dropdown">
      <p-is-zs-select #brandDpDwn *ngIf="TherapyBrands?.length>0 && userLoggedinTeam == 'UBC'  && isRenderCreateProjectDpDwn" class="zs-action-field zs-select" mode="action" hide-on-scroll
        [label]="resourceFields['Brand'].text" (change)="brands.selected = brandDpDwn.getValue();" style="max-width: 100%">
        <select>
          <option *ngFor="let option of TherapyBrands | arrayFilter:{Therapy_Area: therapyArea.selected} | arrayFilter: {Product_Type: productType.selected} | unique:'Product_Family'" [value]="option.Product_Family">
            {{option.Product_Family}}</option>
        </select>
      </p-is-zs-select>
      <p-is-zs-select #therapyDpDwn *ngIf="TherapyBrands?.length>0 && userLoggedinTeam == 'PBC'  && isRenderCreateProjectDpDwn" class="zs-action-field zs-select" mode="action" hide-on-scroll
        [label]="resourceFields['Brand'].text" (change)="brands.selected = therapyDpDwn.getValue();" style="max-width: 100%">
        <select>
          <option *ngFor="let option of TherapyBrands | arrayFilter:{Therapy_Area: therapyArea.selected} | unique:'Product_Family'" [value]="option.Product_Family">
            {{option.Product_Family}}</option>
        </select>
      </p-is-zs-select>
      <!-- <label class="zs-font-weight-bold"> {{resourceFields['Brand'].text| uppercase}}</label>
      <select [(ngModel)]="brands.selected" *ngIf="userLoggedinTeam == 'UBC'">
        <option
          *ngFor="let options of TherapyBrands | arrayFilter:{Therapy_Area: therapyArea.selected} | arrayFilter: {Product_Type: productType.selected} | unique:'Product_Family'"
          [value]="options.Product_Family">
          {{options.Product_Family}} </option>
      </select>
      <select [(ngModel)]="brands.selected" *ngIf="userLoggedinTeam == 'PBC'">
        <option
          *ngFor="let options of TherapyBrands | arrayFilter:{Therapy_Area: therapyArea.selected} | unique:'Product_Family'"
          [value]="options.Product_Family">
          {{options.Product_Family}} </option>
      </select> -->
    </div>
    <!-- <hr class="project-hr"> -->

    <div class="zs-action-field dropdown" *ngIf="userLoggedinTeam == 'UBC'">
      <!-- <label class="zs-font-weight-bold"> CHANNEL</label>
      <select [(ngModel)]="selectChannels.selected">
        <option *ngFor="let option of selectChannels.options" [value]="option.Channel_Name">
          {{option.Channel_Name}}</option>
      </select> -->
      <p-is-zs-select *ngIf="selectChannels.options?.length>0" #channelDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll
        label="Channel" (change)="selectChannels.selected = channelDpDwn.getValue()" style="max-width: 100%">
        <select>
          <option *ngFor="let option of selectChannels.options" [value]="option.Channel_Name">
            {{option.Channel_Name}}</option>
        </select>
      </p-is-zs-select>
    </div>

    <!-- <hr class="project-hr" *ngIf="userLoggedinTeam == 'UBC'"> -->

    <div class="zs-action-field dropdown" *ngIf="userLoggedinTeam == 'PBC'">
      <p-is-zs-select *ngIf="contractType.options?.length>0" #contractTypeDpDwn class="zs-action-field zs-select" mode="action" hide-on-scroll
        label="Contract Type" (change)="contractType.selected = contractTypeDpDwn.getValue()" style="max-width: 100%">
        <select>
          <option *ngFor="let option of contractType.options" [value]="option.Contract_Type">
            {{option.Contract_Type}}</option>
        </select>
      </p-is-zs-select>
      <!-- <label class="zs-font-weight-bold"> CONTRACT TYPE</label>
      <select [(ngModel)]="contractType.selected">
        <option *ngFor="let option of contractType.options" [value]="option.Contract_Type">
          {{option.Contract_Type}}</option>
      </select> -->
    </div>

    <!-- <hr class="project-hr" *ngIf="userLoggedinTeam == 'PBC'"> -->

    <div>
      <label> Notes</label>
      <div>
        <p-is-zs-textarea label="" cols="70" rows="6" name="projectNotes" ngDefaultControl [(ngModel)]="projectNotes"
          class="zs-field" clear></p-is-zs-textarea>
      </div>
    </div>
  </section>
  <footer>
    <button *ngIf="userLoggedinTeam == 'UBC'" class="zs-button zs-button-action zs-border-rounded-0"
      [disabled]='!projectName.length'
      (click)="createProject(projectName, brands.selected, selectChannels.selected, projectNotes, $event)">Create</button>
    <button *ngIf="userLoggedinTeam == 'PBC'" class="zs-button zs-button-action zs-border-rounded-0"
      [disabled]='!projectName.length'
      (click)="createProject(projectName, brands.selected, null, projectNotes, $event)">Create</button>

    <a class="zs-button zs-border-rounded-0" (click)='closeModal();'>Cancel</a>
  </footer>
</zs-dialog>
<!-- create project modal ends-->
