import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApiService } from 'src/app/shared/services/server-api.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class HelpTabService {
  helpTabAPIs: any;

  constructor(private http: HttpClient, private apisService: ServerApiService) {
    this.helpTabAPIs = this.apisService.getApi.helpTab;
  }

  getNavigationDirectionData(params): any{
    return this.http.post(this.helpTabAPIs.getNavigationDirectionData, params);
  }

  getInitialFAQData(params): any {
    return this.http.post(this.helpTabAPIs.getInitialFAQData, params);
  }

  getHelpTabFaqData(params): any {
    return this.http.post(this.helpTabAPIs.getHelpTabFaqData, params);
  }

  downloadFile(): any {
    return this.http.get(this.helpTabAPIs.downloadFile);
  }

  removeFile(): any {
    return this.http.get(this.helpTabAPIs.removeFile);
  }

  uploadFile(params): any {
    return this.http.post(this.helpTabAPIs.uploadFile, params);
  }

}
