import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { FileService } from 'src/app/shared/services/file.service';
import { HelpTabService } from './help-tab.service';

@Component({
  selector: 'app-help-tab',
  templateUrl: './help-tab.component.html',
  styleUrls: ['./help-tab.component.scss']
})
export class HelpTabComponent implements OnInit {

  attachFileErrorMsg: string = '';
  attachedFileName: string = '';
  userGuideShow: boolean = false;
  attachmentUserGuideShow: boolean = false;
  attachLinkShow: boolean = false;
  isLoading: boolean = false;
  loaderSelector = '';
  fileList = [];
  fileProperty = {
    isUploaded: false,
    file: {}
  };
  helpTabsData: any = [];
  tabID: any = {
    selected: ''
  };
  helpTabsFaq: any;
  helpTabsLinksData: any;
  userGuideDetails: any;
  helpTabsFaqDataQuestion = {};
  helpTabsFaqDataAnswer = {};
  helpTabsLinks = {};
  @ViewChild('attachedUserGuide')
  attachedUserGuideRef: ElementRef;
  @ViewChild('questionAnswerBlock')
  questionAnswerBlock: ElementRef<any>;
  navigationPath = {
    tab: '',
    questionID: ''
  };

  constructor(public appService: AppService, private helpTabService: HelpTabService, private fileService: FileService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.loaderSelector = this.appService.loaderColors.pine_green;
    let params = {
      direction: this.appService.openFAQSectionDirection
    }
    this.helpTabService.getNavigationDirectionData(params).subscribe(data => {
      let navigationData = data;
      this.setNavigationDirection(navigationData);
      let initialFAQParams = {
        direction: this.navigationPath.tab
      }
  
      this.helpTabService.getInitialFAQData(initialFAQParams).subscribe(data => {
        this.helpTabsData = data.tabsData;
        this.tabID.selected = this.getNavigationTabID(this.helpTabsData, this.navigationPath.tab);
  
        //setting the section tabs visible on the left side of the FAQ modal
        this.helpTabsFaq = data.helpTabsFaq;
        this.setTabQuestionAnswer(this.helpTabsFaq);
  
        //setting the useful links to be displayed in the right side of FAQ modal
        this.helpTabsLinksData = data.tabsLinks;
        this.setTabLinks(this.helpTabsLinksData);
  
        //setting the user guide details
        this.userGuideDetails = data.userGuideDetails;
        this.setUserGuideDetails(this.userGuideDetails, this.appService.loggedInUser.role);
  
        setTimeout(() => {
          this.expandAnswer(this.navigationPath.questionID);
        }, 1);
        this.isLoading = false;
      });
    });
  }

  setTabQuestionAnswer = function (helpTabsFaq) {
    this.helpTabsFaqDataQuestion = {};
    this.helpTabsFaqDataAnswer = {};
    for (var i = 0; i < this.helpTabsFaq.length; i++) {
      this.helpTabsFaqDataQuestion[i] = helpTabsFaq[i].Question;
      this.helpTabsFaqDataAnswer[i] = helpTabsFaq[i].Answer;
    }
  }

  setTabLinks = function (helpTabsLinksData) {
    this.helpTabsLinks = {};
    for (var i = 0; i < helpTabsLinksData.length; i++) {
      helpTabsLinksData[i].Link = helpTabsLinksData[i].Link.replace('zs-icon zs-icon-popout', 'zs-icon zs-size-l zs-icon-frame-expand');
      this.helpTabsLinks[i] = helpTabsLinksData[i].Link;
    }
  }

  setUserGuideDetails = function (userGuideDetails, userRole) {
    if (userRole == 'Admin') {
      this.userGuideShow = true;
      if (userGuideDetails && userGuideDetails.length != 0) {
        this.attachedFileName = 'User Guide';
        this.attachLinkShow = false;
        this.attachmentUserGuideShow = true;
      }
      else {
        this.attachedFileName = '';
        this.attachLinkShow = true;
        this.attachmentUserGuideShow = false;
      }
    }
    else {
      if (userGuideDetails && userGuideDetails.length != 0) {
        this.attachedFileName = 'User Guide';
        this.attachLinkShow = false;
        this.attachmentUserGuideShow = true;
      }
      else {
        this.userGuideShow = true;
        this.attachedFileName = '';
        this.attachLinkShow = false;
        this.attachmentUserGuideShow = false;
      }
    }
  }

  selectTab(id) {
    this.tabID.selected = id;
    var FaqParam = { tabID: id };
    this.isLoading = true;
    this.helpTabService.getHelpTabFaqData(FaqParam).subscribe(data => {
      this.helpTabsFaq = data;
      this.setTabQuestionAnswer(this.helpTabsFaq);
      this.isLoading = false;
    });
  };

  addUserGuide = function (element) {
    if (this.validateFile()) {
      this.fileProperty.isUploaded = true;
      this.fileProperty.file = element.files[0];
      this.attachedFileName = element.files[0].name;
      this.fileList.push(this.fileProperty);
      this.addAttached();
    }
  }

  validateFile = function () {
    var validExtension = this.appService.resourceFields.AcceptedFileFormatForUserGuide.text.split(',');
    this.attachFileErrorMsg = '';
    var file = this.attachedUserGuideRef.nativeElement;
    this.attachFileErrorMsg = this.fileService.getFileErrorMessage(file, validExtension, this.appService.resourceFields.AcceptedFileSizeForHelpTab.text, 100);
    return (this.attachFileErrorMsg == '');
  }

  addAttached = function () {
    var fileName = this.fileList[0].file.name;
    var fileType = this.fileList[0].file.type;
    var reader = new FileReader();
    reader.readAsArrayBuffer(this.fileList[0].file);
    reader.onload = () => {
      var fileArrayBuffer = reader.result;
      var fileBufferData = this.fileService.arrayBufferToBuffer(fileArrayBuffer);
      var uploadFileParams = { fileName: fileName, contentType: fileType, fileData: fileBufferData };
      this.helpTabService.uploadFile(uploadFileParams).subscribe(data => {
        this.userGuideDetails = data;
        this.setUserGuideDetails(this.userGuideDetails, this.appService.loggedInUser.role);
      });
    };
  }

  downloadFile = function () {
    this.helpTabService.downloadFile().subscribe(data => {
      var linkElement = document.createElement('a');
      this.fileService.downloadFileData(data, linkElement);
    });
  };

  removeFileName = function () {
    this.fileProperty.isUploaded = false;
    this.attachedFileName = '';
    this.helpTabService.removeFile().subscribe(data => {
      this.userGuideDetails = data;
      this.setUserGuideDetails(this.userGuideDetails, this.appService.loggedInUser.role);
    });
  };

  closeModal(): void {
    this.appService.closeFAQSection();
  }

  setNavigationDirection(data) {
    if(data.length != 0){
      this.navigationPath.tab = data[0].Tab;
      this.navigationPath.questionID = data[0].Question_id;
    }
    else{
      this.navigationPath.tab = 'HomeScreen';
      this.navigationPath.questionID = '';
    }
  }

  getNavigationTabID(tabsArray, navigationPath) {
    let selectedTab = 0;
    tabsArray.every(tab => {
      if (tab.Line_Item == navigationPath) {
        selectedTab = tab.TabID;
        return false;
      }
      return true;
    });
    return selectedTab;
  }

  expandAnswer(questionID) {
    if (questionID != '') {
      var classString = '.' + questionID;
      this.questionAnswerBlock.nativeElement.querySelector(classString).parentElement.parentElement.parentElement.parentElement.parentElement.expandTarget();
    }
  }

}
