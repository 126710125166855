import { PassDirectionService } from './../../../shared/services/pass-direction.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { ProjectsService } from './projects.service';
import { AppService } from './../../../app.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SortService } from 'src/app/shared/services/sort.service';
import { PassScenarioService } from 'src/app/shared/services/pass-scenario.service';
import { PassMasterScenarioService } from 'src/app/shared/services/pass-master-scenario.service';
import { KeyValue } from '@angular/common';
declare var window: any;


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {

  @ViewChild('createProjectModal')
  createProjectRef: ElementRef<any>;

  @ViewChild('deleteProjectModal')
  deleteProjectModalRef: ElementRef<any>;

  @ViewChild('createScenarioModal')
  createScenarioModalRef: ElementRef<any>;

  // @ViewChild('updateScenarioDetailsModal')
  // updateScenarioDetailsModalRef: ElementRef<any>;

  @ViewChild('deleteScenarioModal')
  deleteScenarioModalRef: ElementRef<any>;

  @ViewChild('shareScenarioModal')
  shareScenarioModalRef: ElementRef<any>;

  @ViewChild('openMasterScenariosModal')
  openMasterScenariosModalRef: ElementRef<any>;

  pageTitle = 'projects & scenarios';
  isLoading = false;
  loaderSelector = '';
  loggedInUser;
  resourceFields;
  resources;

  userLoggedin = '';
  userLoggedinRole = '';
  userLoggedinTeam = '';

  projectNotesTemp = [];
  projectNameTemp = [];
  scenarioNotesTemp = [];
  scenarioNameTemp = [];


  projectName = "";
  projectNotes = "";

  users = {
    selected: '',
    options: []
  }

  franchises = {
    selected: '',
    options: []
  }
  competitors = {
    selected: '',
    options: []
  }

  allProjectsBrands;
  allProjectsChannels;

  channels = {
    selected: '',
    options: []
  }
  statuses = {
    selected: '',
    options: []
  }
  statusOptions = {
    selected: '',
    options: []
  }
  scenarioType = {
    selected: '',
    options: { 'Singleton': 'Single scenario', 'Master': 'Parent/Child scenario' }
  }
  accessLevels = {
    selected: '',
    options: ['Public', 'Private']
  }
  productType = {
    selected: '',
  }
  therapyArea = {
    selected: ''
  }
  brands = {
    selected: '',
  }

  selectChannels = {
    selected: '',
    options: []
  }
  searchText = '';

  projectsList = {
    myProjects: [],
  }

  TherapyBrands = [];


  filteredProjectList = [];

  contractType = {
    selected: '',
    options: []
  }

  shareScenarioUsers = [];

  paginationOptions: { itemsCount: number, pageSize: number, maxDisplayPages: number, currentPage: number } = {

    itemsCount: 15,
    pageSize: 15,
    currentPage: 1,
    maxDisplayPages: 5

  };
  startIndex = 0;
  endIndex = 14;

  sortOrderForParentTable = false;
  sortColumnForParentTable = '';

  sortOrderForChildTable = false;
  sortColumnForChildTable = '';

  //Initializing scenario object to use passscenario service
  scenario = {
    ProjectSummary: { id: "", Projectname: "", ProductType: "", TherapyArea: "", Brand: "", Notes: "", CreatedBy: "", Market: "", Channel: "", ChannelGroup: "", Team: "", ContractType: "" },
    SelectedpayerSummary: { Payer: "", Channel: "", ChannelGroup: "", Lives: "", Plans: "", Control: { ControlType: "", ControlValue: 0 }, userDefinedControl: { ControlType: "", ControlValue: 0 }, "Market TRx": "" },
    ScenarioSummary: {
      ScenarioID: "",
      ScName: "",
      Access: "Private",
      AccessOptions: ["Private", "Public"],
      Notes: "",
      CreatedBy: "",
      CreatedDate: "",
      ModifiedDate: "",
      Status: "",
      MasterScName: "",
      ParentType: "",
      ParentScenarioName: "",
      Scenariofinancialdata: { Id: "", ScenarioName: "", data: [] }
    },
    MasterScenarioSummary: {
      MasterScID: "",
      MasterScName: "",
      Brand: "",
      MasterScAccess: "",
      MasterScStatus: "",
      MasterScOwner: ""
    },
    SelectedAnalogSummary: {
      Analogevents: []
    }
  };

  gridType: string = "ProjectsGrid";
  copyToExcelString: any;
  customCopyFlag: boolean;
  
  //Initializing master scenario object to use passMasterScenario service
  masterScenario: any = {
    MasterID: 0,
    MasterScName: "",
    ChildScenarioCount: 0
  };

  openProjectFlag = false;
  constructor(public appService: AppService, private projectsService: ProjectsService, private sortService: SortService, private route: Router, private passScenarioService: PassScenarioService, private passMasterScenarioService: PassMasterScenarioService, public commonService: CommonService, private direction: PassDirectionService) {
    this.loggedInUser = this.appService.loggedInUser;
    this.userLoggedinTeam = this.loggedInUser.team;
    this.userLoggedin = this.loggedInUser.name;
    this.resourceFields = this.appService.resourceFields;
    this.resources = this.appService.resources;
    document.addEventListener('copy', (e) => {
      if (this.customCopyFlag) {
        this.customCopyFlag = false;
        e.clipboardData.setData('text/plain', this.copyToExcelString);
        e.preventDefault();
      }
    });
  }

  ngOnInit() {
    this.fetchAllProjects();
    this.loaderSelector = this.appService.loaderColors.solid_grey;
    this.sortColumnForChildTable = this.sortColumnForParentTable = 'DateModified';
    this.sortOrderForParentTable = true; //true is desc.
    this.sortOrderForChildTable = false; //false is asc
    this.direction.setAffiliationDirection('');
    this.direction.result && (this.direction.result.direction = '');

  }

  loadDataFromDB = (res) => {
    if (res.channels) {
      this.selectChannels.options = res.channels;
      this.selectChannels.selected = this.selectChannels.options[0].Channel_Name;
    }
    if (res.scenarioStatuses) {
      res.scenarioStatuses.sort((el1, el2) => el1.Status > el2.Status ? 1 : -1);
      this.statusOptions.options = res.scenarioStatuses;
      this.statuses.options = res.scenarioStatuses;


    }
    if (res.contractType) {
      this.contractType.options = res.contractType;
      this.contractType.selected = this.contractType.options[0].Contract_Type;
    }
    if (res.userList) {
      try {


        this.users.options = res.userList.filter((obj) => { return (obj.Roles[0].Name === 'User') });
        this.users.options.sort(this.compare);
        for (var i = 0; i < (this.users.options).length; i++) {
          if (this.users.options[i].UserName !== this.userLoggedin) {
            for (var j = 0; j < (this.users.options[i].Attributes).length; j++) {
              if (this.users.options[i].Attributes[j].Name === 'team') {
                if (this.users.options[i].Attributes[j].Value && this.users.options[i].Attributes[j].Value.toString().includes(this.loggedInUser.team)) {
                  this.shareScenarioUsers.push(this.users.options[i]);
                  break;
                }
              }
            }
          }
        }
        this.users.selected = this.shareScenarioUsers[0].UserName;
      } catch (error) {
        console.log(error);
      }
    }
    if (res.projects && res.allProjectsCount) {
      this.projectsList.myProjects = res.projects;
      this.projectsList.myProjects.map(project => {
        project.isActive = false;
        return project;
      })
      this.paginationOptions.itemsCount = res.allProjectsCount;
      this.initializeTempNotes();
    }
    if (res.therapy) {
      this.TherapyBrands = res.therapy;
      if (this.userLoggedinTeam == 'UBC') {
        this.productType.selected = this.TherapyBrands[0].Product_Type;
        this.therapyArea.selected = this.TherapyBrands.filter((obj) => { return obj.Product_Type === this.productType.selected })[0].Therapy_Area;
        this.brands.selected = this.TherapyBrands.filter((obj) => { return obj.Product_Type === this.productType.selected && obj.Therapy_Area === this.therapyArea.selected })[0].Product_Family;
      }
      else {
        this.therapyArea.selected = this.TherapyBrands[0].Therapy_Area;
        this.brands.selected = this.TherapyBrands.filter((obj) => { return obj.Therapy_Area === this.therapyArea.selected })[0].Product_Family;
      }

    }
    if (res.allProjectsBrands) {
      this.allProjectsBrands = res.allProjectsBrands;
    }
    if (res.allProjectsChannels) {
      this.allProjectsChannels = res.allProjectsChannels;
    }

  }

  compare = (a, b) => {
    if (a.UserName < b.UserName)
      return -1;
    if (a.UserName > b.UserName)
      return 1;
    return 0;
  }

  initializeTempNotes = () => {
    this.projectsList.myProjects.forEach(project => {
      this.projectNotesTemp.push('');	//create array of temp notes to store in and reset values
      var projectScenarioNotes = [];
      var projectScenarioName = [];

      project.ScenarioList.forEach(masterScenario => {
        projectScenarioNotes.push('');
        projectScenarioName.push('');
      });
      this.scenarioNotesTemp.push(projectScenarioNotes);
      this.scenarioNameTemp.push(projectScenarioName);
    });
  }


  toggleAccordian(event) {
    var sourceNode = <HTMLElement>event.target;

    var targetNode = sourceNode.closest('tr').nextElementSibling;
    var isOpen = !targetNode.classList.contains('zs-display-none');
    if (!isOpen) {
      sourceNode.classList.replace('zs-icon-carat-down', 'zs-icon-carat-up');
      targetNode.classList.remove('zs-display-none');
      targetNode.classList.add('zs-bg-surface-2');

    } else {
      sourceNode.classList.replace('zs-icon-carat-up', 'zs-icon-carat-down');
      targetNode.classList.add('zs-display-none');
    }

  }
  openCreateProjectModal() {
    this.openProjectFlag = true;
    this.productType.selected = this.TherapyBrands[0].Product_Type;
    this.therapyArea.selected = this.TherapyBrands.filter((obj) => { return obj.Product_Type === this.productType.selected })[0].Therapy_Area;
    this.brands.selected = this.TherapyBrands.filter((obj) => { return obj.Product_Type === this.productType.selected && obj.Therapy_Area === this.therapyArea.selected })[0].Product_Family;
    this.createProjectRef.nativeElement.showModal();
  }

  resetProjectPagination: boolean = false;

  getFilteredProjects(filterType) {

    // if (filterType == 'search' && this.searchText.length > 0 && this.searchText.length <= 3)
    //   return;

    // (filterType !== 'search' && this.searchText.length > 0 && this.searchText.length <= 3) && (this.searchText = '');
    // if(filterType == 'search' && this.searchText.length == 0){
    //   this.startIndex = 0;
    //   this.endIndex = this.paginationOptions.pageSize - 1;
    //   this.resetProjectPagination = true;
    //   if(this.resetProjectPagination)
    //     setTimeout(() => {
    //       this.resetProjectPagination = false;
    //     }, 1000);
    // }
    var params = {
      searchText: this.searchText || '',
      filters: {
        TherapyArea: this.franchises.selected || '',
        BrandName: this.competitors.selected || '',
        Channel: this.channels.selected || '',
        Status: this.statuses.selected || '',
      },
      sort: {
        columnName: this.sortColumnForParentTable || null,
        order: this.sortOrderForParentTable || null
      },
      pagination: {
        startIndex: this.startIndex || 0,
        endIndex: this.endIndex || 14
      },
    }


    this.isLoading = true;
    this.projectsService.getProjectsData(params).subscribe(res => {

      this.projectsList.myProjects = res.projects;
      //update all brands filter as per market
      this.allProjectsBrands = res.allProjectsBrands;
      this.paginationOptions.itemsCount = res.allProjectsCount;
      setTimeout(() => {
        let paginationNodeRef: any = document.getElementById('zsProjectPagination');
        paginationNodeRef && (this.paginationOptions.currentPage = paginationNodeRef.currentPage);
        this.paginationOptions.itemsCount == 0 && (this.paginationOptions.currentPage = 0);
        this.paginationOptions.itemsCount != 0 && this.paginationOptions.currentPage == 0 && (this.paginationOptions.currentPage = 1);
      }, 500);
      this.isLoading = false;

    });



  };

  isRenderCreateProjectDpDwn = true;
  renderCreateProjectDpDwns() {
    this.isRenderCreateProjectDpDwn = false;
    setTimeout(() => {
      this.isRenderCreateProjectDpDwn = true;
    }, 10);
  }

  pageChange($event) {
    this.paginationOptions.currentPage = $event.detail.newPage;
    var newValue = $event.detail.newPage;
    var pageSize = this.paginationOptions.pageSize;
    var startIndex = pageSize * (newValue - 1);
    var endIndex = startIndex + pageSize - 1;
    var fetchFilteredData = false;
    if (this.startIndex != undefined)
      fetchFilteredData = true;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    if (fetchFilteredData)
      this.getFilteredProjects('pagination');

  }

  //Sorting Functionality
  performSortForParentTable(column) {
    this.sortOrderForParentTable = this.sortService.sortData(column);
    this.sortColumnForParentTable = this.sortService.getSortColumn();
  }


  performSortForChildTable = (column, projectId) => {
    this.sortOrderForChildTable = this.sortService.sortData(column);
    this.sortColumnForChildTable = this.sortService.getSortColumn();

    var scenarioList = this.projectsList.myProjects.find(p => p.ProjectID == projectId).ScenarioList;
    scenarioList.sort((sc1, sc2) => {
      return (sc1[this.sortColumnForChildTable] > sc2[this.sortColumnForChildTable] ? 1 : -1) * (this.sortOrderForChildTable ? -1 : 1);
    })

  }

  defaultSelectProductGroupType = () => {
    this.productType.selected = this.TherapyBrands.filter((obj) => { return obj.Therapy_Area === this.therapyArea.selected })[0].Product_Type;
    this.defaultSelectBrand();
  }

  defaultSelectBrand = () => {
    if (this.userLoggedinTeam == 'UBC') {
      this.brands.selected = this.TherapyBrands.filter((obj) => { return obj.Product_Type === this.productType.selected && obj.Therapy_Area === this.therapyArea.selected })[0].Product_Family;
    }
    else {
      this.brands.selected = this.TherapyBrands.filter((obj) => { return obj.Therapy_Area === this.therapyArea.selected })[0].Product_Family;
    }
  }

  createProject(projectName, brand, channel, projectNotes, event) {

    var projectData = {
      ProjectName: projectName,
      BrandName: brand,
      Channel: channel,
      ChannelGroup: channel != null ? this.selectChannels.options.filter(function (obj) { return obj.Channel_Name === channel })[0].Channel_Group : null,
      IsBookmarked: false,
      CreatedBy: this.userLoggedin,
      Notes: projectNotes,
      ModifiedBy: this.userLoggedin,
      IsActive: 'Y',
      ProductFamilyFlag: this.userLoggedinTeam == 'UBC' ? this.productType.selected : null,
      TherapyArea: this.therapyArea.selected,
      Team: this.userLoggedinTeam,
      ContractType: this.userLoggedinTeam == 'PBC' ? this.contractType.selected : null
    };

    this.projectsService.save(projectData)
      .subscribe(res => {
        this.fetchAllProjects();
        this.appService.showToast('Project created successfully', 'success', true, 3000);
      });

    // To close the modal window  
    this.closeModal();
  }

  closeModal() {
    this.projectName = ""
    this.projectNotes = '';
    this.openProjectFlag = false;
    this.createProjectRef.nativeElement.close();
  }

  deleteProject = (projectId, DateModified) => {
    this.resetProjectPagination = true;
    var projectData = {
      ProjectID: projectId,
      ModifiedBy: this.userLoggedin,
      DateModified
    };
    this.isLoading = true;
    this.projectsService.deleteProject(projectData)
      .subscribe(data => {
        this.isLoading = false;
        if (data === true) {
          // var projectIndex = this.projectsList.myProjects.map(function (obj) { return obj.ProjectID; }).indexOf(projectId);
          // this.projectsList.myProjects.splice(projectIndex, 1);
          this.isLoading = true;
          this.projectsService.getProjectsData(null).subscribe(data => {

            this.loadDataFromDB(data);
            this.isLoading = false;
            this.loaderSelector = this.appService.loaderColors.pine_green;
            this.appService.showToast('Project deleted successfully', 'success', true, 3000);
            this.resetProjectPagination = false;
          });
        }
        else {
          this.appService.showToast('Unable to delete the Project', 'error', true, 3000);
        }

      }, error => {
        this.isLoading = false;
        if (true) {
          // var projectIndex = this.projectsList.myProjects.map(function (obj) { return obj.ProjectID; }).indexOf(projectId);
          // this.projectsList.myProjects.splice(projectIndex, 1);
          this.isLoading = true;
          this.projectsService.getProjectsData(null).subscribe(data => {

            this.loadDataFromDB(data);
            this.isLoading = false;
            this.loaderSelector = this.appService.loaderColors.pine_green;
            this.appService.showToast('Project deleted successfully', 'success', true, 3000);
            this.resetProjectPagination = false;
          });
        }
      });

    this.deleteProjectModalRef.nativeElement.close();
    this.updateNumberOfPages();
  }

  updateNumberOfPages = function () {
    this.paginationOptions.itemsCount = this.filteredProjectList.length;
    this.paginationOptions.currentPage = 1;
  }

  fetchAllProjects() {
    this.isLoading = true;
    if (this.loggedInUser.team !== '') {
      this.appService.clearDataOnClientSession();
      this.projectsService.getProjectsData(null).subscribe(data => {

        this.loadDataFromDB(data);
        this.isLoading = false;
        this.loaderSelector = this.appService.loaderColors.pine_green;

      });
    }
  }

  toggleBookmark = (projectData) => {
    projectData.IsBookmarked = !projectData.IsBookmarked;
    this.isLoading = true;
    this.projectsService.toggleProjectBookmark({ projectId: projectData.ProjectID, isBookmarked: projectData.IsBookmarked })
      .subscribe(res => {
        this.isLoading = false;
      })
  }

  CreateNewMaster = (project) => {
    this.scenario.ProjectSummary.id = project.ProjectID;
    this.scenario.ProjectSummary.Projectname = project.ProjectName;
    this.scenario.ProjectSummary.Brand = project.BrandName;
    this.scenario.ProjectSummary.Notes = project.Notes;
    this.scenario.ProjectSummary.Channel = project.Channel;
    this.scenario.ProjectSummary.ChannelGroup = this.userLoggedinTeam == 'UBC' ? this.selectChannels.options.filter(function (obj) { return obj.Channel_Name === project.Channel })[0].Channel_Group : null;
    this.scenario.ProjectSummary.CreatedBy = project.CreatedBy;
    this.scenario.ProjectSummary.Market = this.TherapyBrands.filter(obj => { return obj.Product_Family === this.scenario.ProjectSummary.Brand })[0] !== undefined ? this.TherapyBrands.filter(obj => { return obj.Product_Family === this.scenario.ProjectSummary.Brand })[0].Therapy_Area : "";
    this.scenario.ProjectSummary.TherapyArea = project.TherapyArea;
    this.scenario.ProjectSummary.ProductType = project.ProductFamilyFlag;
    this.scenario.ProjectSummary.Team = project.Team;
    this.scenario.ProjectSummary.ContractType = project.ContractType;
    this.scenario.ScenarioSummary.CreatedBy = this.appService.loggedInUser.name;
    if (this.userLoggedinTeam == 'UBC') {
      this.scenario.ScenarioSummary.ParentType = project.scenarioType.selected == 'Singleton' ? 'Singleton' : 'Child';
      if (this.scenario.ScenarioSummary.ParentType != 'Singleton') {
        this.scenario.ScenarioSummary.ParentScenarioName = project.parentName;
        this.scenario.ScenarioSummary.MasterScName = project.parentName;
      }
    } else {
      this.scenario.ScenarioSummary.ParentType = 'Singleton';
    }
    this.passScenarioService.setScenario(this.scenario);
    if (this.userLoggedinTeam == 'UBC')
      this.route.navigate(['ubc/createScenario']);
    else
      this.route.navigate(['pbc/createScenario']);

    this.createScenarioModalRef.nativeElement.close();
  }

  openCreateScenarioModal(project) {
    project.scenarioType = {};
    project.parentName = '';
    project.scenarioType.selected = 'Singleton';
    // this.createScenarioModalRef.nativeElement.showModal();
  }

  updateStatus = (scenarioData) => {

    //Update status of the master scenairo
    var oldScenarioStatus = scenarioData.Status;
    scenarioData.Status = this.statusOptions.selected;
    scenarioData.Status_ID = this.statusOptions.options.filter((obj: any) => { return obj.Status === this.statusOptions.selected })[0];
    scenarioData.Status_ID = scenarioData.Status_ID.Status_ID;
    if (scenarioData.ParentType == 'Singleton') {
      let params: any = {
        scenarioID: scenarioData.ScenarioID,
        statusOptionsSelected: this.statusOptions.selected,
        statusId: scenarioData.Status_ID,
        userLoggedin: this.userLoggedin,
        oldScenarioStatus: oldScenarioStatus,
        projectID: scenarioData.ProjectID
      }
      this.isLoading = true;
      this.projectsService.updateScenarioStatusForSingleton(params).subscribe(data => {
        scenarioData.DateModified = data;
        this.isLoading = false;
        this.appService.showToast('Scenario status updated successfully', 'success', true, 3000);
      });

    }
    else {
      let parentParams: any = {
        scenarioID: scenarioData.ScenarioID,
        statusOptionsSelected: this.statusOptions.selected,
        statusId: scenarioData.Status_ID,
        projectID: scenarioData.ProjectID
      }
      this.isLoading = true;
      this.projectsService.parentScenarioUpdate(parentParams).subscribe(data => {
        //For each scenario under this master scenario upadat status
        scenarioData.DateModified = data;
        let childParams = {
          scenarioID: scenarioData.ScenarioID,
          statusOptionsSelected: this.statusOptions.selected,
          statusId: scenarioData.Status_ID,
          oldScenarioStatus: oldScenarioStatus
        }
        this.projectsService.childScenarioUpdate(childParams).subscribe(data => {
          this.isLoading = false;
        });
      });
    }


  }

  openScenarioNotes = (scenarioData, parentIndex, index) => {

    this.scenarioNameTemp[parentIndex][index] = JSON.parse(JSON.stringify(scenarioData.ScenarioName));
    this.scenarioNotesTemp[parentIndex][index] = JSON.parse(JSON.stringify(scenarioData.Notes));
  }

  openProjectNotes = (projectData, parentIndex) => {

    this.projectNotesTemp[parentIndex] = JSON.parse(JSON.stringify(projectData.Notes));
    this.projectNameTemp[parentIndex] = JSON.parse(JSON.stringify(projectData.ProjectName));
  }

  saveScenarioNotes = (scenarioData, parentIndex, index) => {
    var updateMasterNameFlag = false;
    var masterNameParameter = {};

    scenarioData.ModifiedBy = this.userLoggedin;
    scenarioData.Notes = JSON.parse(JSON.stringify(this.scenarioNotesTemp[parentIndex][index]));
    if (scenarioData.ParentType == 'Singleton') {

      let params = {
        projectID: scenarioData.ProjectID,
        scenarioID: scenarioData.ScenarioID,
        scenarioNotesTemp: this.scenarioNotesTemp[parentIndex][index],
        scenarioNameTemp: this.scenarioNameTemp[parentIndex][index],
        userLoggedin: this.userLoggedin,
        oldMasterScName: scenarioData.ScenarioName,
        newMasterScName: this.scenarioNameTemp[parentIndex][index]
      }
      scenarioData.ScenarioName = JSON.parse(JSON.stringify(this.scenarioNameTemp[parentIndex][index]));
      this.isLoading = true;
      this.projectsService.updateScenarionameNotesForSingleton(params)
        .subscribe(data => {
          scenarioData.DateModified = data;
          this.appService.showToast('Scenario details updated successfully', 'success', true, 3000);
          this.isLoading = false;
        });

    }
    else {
      if (scenarioData.ScenarioName != this.scenarioNameTemp[parentIndex][index]) {
        updateMasterNameFlag = true;
        masterNameParameter = { scenarioID: scenarioData.ScenarioID, oldMasterScName: scenarioData.ScenarioName, newMasterScName: this.scenarioNameTemp[parentIndex][index] };
      }
      var oldScenarioName = scenarioData.ScenarioName;
      scenarioData.ScenarioName = JSON.parse(JSON.stringify(this.scenarioNameTemp[parentIndex][index]));
      let params = {
        projectID: scenarioData.ProjectID,
        scenarioID: scenarioData.ScenarioID,
        scenarioNotesTemp: this.scenarioNotesTemp[parentIndex][index],
        scenarioNameTemp: this.scenarioNameTemp[parentIndex][index],
        userLoggedin: this.userLoggedin,
        oldScenarioName: oldScenarioName
      }
      this.isLoading = true;
      this.projectsService.scenarioUpdateFromProjectScreen(params).subscribe(data => {
        if (updateMasterNameFlag) {
          this.projectsService.scenarioUpdateInJSON(masterNameParameter)
            .subscribe(data => {
              this.appService.showToast('Scenario details updated successfully', 'success', true, 3000);

            });
        } else {
          this.appService.showToast('Scenario details updated successfully', 'success', true, 3000);

        }
        scenarioData.DateModified = data;
        this.isLoading = false;
      });
    }

    // this.updateScenarioDetailsModalRef.nativeElement.close();
  }




  //Actions on projects data
  saveProjectNotes(projectData, index) {
    var updateProjectNameFlag = false;
    var projectNameParameter = {};
    projectData.Notes = JSON.parse(JSON.stringify(this.projectNotesTemp[index]));
    projectData.ModifiedBy = this.userLoggedin;
    if (projectData.ProjectName != this.projectNameTemp[index]) {
      updateProjectNameFlag = true;
      projectNameParameter = { projectID: projectData.ProjectID, projectName: projectData.ProjectName, projectNameTemp: this.projectNameTemp[index] };
    }
    projectData.ProjectName = JSON.parse(JSON.stringify(this.projectNameTemp[index]));

    this.projectsService.updateProject(projectData)
      .subscribe((data) => {
        if (updateProjectNameFlag) {
          this.projectsService.updateProjectInJSON(projectNameParameter)
            .subscribe((data) => {
              this.appService.showToast('Project details updated successfully', 'success', true, 3000);
            });

        } else {
          this.appService.showToast('Notes saved successfully to Project', 'success', true, 3000);
        }
      });
  }


  deleteScenario = (scenarioData) => {
    var projectIndex = this.projectsList.myProjects.map((obj) => { return obj.ProjectID; }).indexOf(scenarioData.ProjectID);
    var scenarioIndex = this.projectsList.myProjects[projectIndex].ScenarioList.map((obj) => { return obj.ScenarioID; }).indexOf(scenarioData.ScenarioID);

    scenarioData.ModifiedBy = this.userLoggedin;

    if (scenarioData.ParentType == 'Singleton') {

      var params = {
        ScenarioID: scenarioData.ScenarioID,
        ModifiedBy: scenarioData.ModifiedBy,
        projectID: scenarioData.ProjectID
      };

      this.isLoading = true;
      this.projectsService.deleteSingletonScenario(params).subscribe(data => {

        if (data === true) {
          this.projectsList.myProjects[projectIndex].ScenarioList.splice(scenarioIndex, 1);
          this.projectsList.myProjects[projectIndex].NoOfScenarios = this.projectsList.myProjects[projectIndex].NoOfScenarios - 1;
          this.appService.showToast('Scenario deleted successfully', 'success', true, 3000);
        }
        else {
          this.appService.showToast('Unable to delete the Scenario', 'error', true, 3000);
        }
        this.isLoading = false;

      });
    }
    else {
      var params = {
        ScenarioID: scenarioData.ScenarioID,
        ModifiedBy: scenarioData.ModifiedBy,
        projectID: scenarioData.ProjectID
      };
      this.isLoading = true;
      this.projectsService.masterScenarioDelete(params)
        .subscribe(data => {
          this.projectsList.myProjects[projectIndex].ScenarioList.splice(scenarioIndex, 1);
          this.projectsList.myProjects[projectIndex].NoOfScenarios = this.projectsList.myProjects[projectIndex].NoOfScenarios - 1;
          this.isLoading = false;
          this.appService.showToast('Scenario deleted successfully', 'success', true, 3000);
        });

    }

    this.deleteScenarioModalRef.nativeElement.close();

  }

  shareScenario = (scenarioData) => {

    var accessType = 'Shared';
    var params = { ScenarioID: scenarioData.ScenarioID, UserSelected: this.users.selected };
    this.isLoading = true;
    this.projectsService.shareScenario(params).subscribe(data => {
      this.isLoading = false;
      if (scenarioData.AccessType != accessType) {
        scenarioData.AccessType = accessType;
      }
      this.appService.showToast('Scenario shared successfully with user ' + this.users.selected, 'success', true, 3000);

    });
    this.shareScenarioModalRef.nativeElement.close();


  }

  openScenario = (scenarioId,project) => {
    this.masterScenario.project = {};
    this.masterScenario.project.id = project.ProjectID;
    this.masterScenario.project.Projectname = project.ProjectName;
    this.masterScenario.project.Brand = project.BrandName;
    this.masterScenario.project.TherapyArea = project.TherapyArea;
    this.masterScenario.project.ProductType = project.ProductFamilyFlag;
    this.passMasterScenarioService.setScenario(this.masterScenario);
    this.route.navigate(['/ubc/scenario', btoa(scenarioId)]);

  }

  copyToExcel = function (gridType, shouldFetchStringText) {
    this.gridType = gridType;
    if (window.clipboardData) {
      var string = this.getCopiedData();
      window.clipboardData.setData('Text', string);
    } else {
      this.customCopyFlag = (shouldFetchStringText == false) ? shouldFetchStringText : true;
      this.copyToExcelString = this.getCopiedData(); 
      
    }
  }

  getCopiedData() {
    switch (this.gridType) {
      case 'ProjectsGrid':
        return this.getCopiedDataForGrid();
        break;
      default:
    }
  }

  getCopiedDataForGrid() {
    
    var params = {
      searchText: this.searchText || '',
      filters: {
        TherapyArea: this.franchises.selected || '',
        BrandName: this.competitors.selected || '',
        Channel: this.channels.selected || '',
        Status: this.statuses.selected || '',
      },
      sort: {
        columnName: this.sortColumnForParentTable || null,
        order: this.sortOrderForParentTable || null
      },
      brandText: this.resourceFields['Brand'].text
    }
    this.isLoading = true;
    this.projectsService.copyAllProjects(params).subscribe((data) => {
      this.copyToExcelString = data;
      document.execCommand('copy');
      //this.copyToExcel('analogEventsGrid', false); 
      this.isLoading = false;
    });
  }

  openMasterScenario(scenario, project) {
    this.masterScenario.MasterID = scenario.ScenarioID;
    this.masterScenario.MasterScName = scenario.ScenarioName;
    this.masterScenario.MetricType = scenario.MetricType;
    this.masterScenario.ChildScenarioCount = scenario.ChildScenarioCount;
    this.masterScenario.ChildScenarioList = scenario.ChildScenarioList;
    this.masterScenario.project = {};
    this.masterScenario.project.id = project.ProjectID;
    this.masterScenario.project.Projectname = project.ProjectName;
    this.masterScenario.project.Brand = project.BrandName;
    this.masterScenario.project.Notes = project.Notes;
    this.masterScenario.project.CreatedBy = project.CreatedBy;
    this.masterScenario.project.Channel = project.Channel;
    this.masterScenario.project.Market = project.TherapyArea;
    this.masterScenario.project.TherapyArea = project.TherapyArea;
    this.masterScenario.project.ProductType = project.ProductFamilyFlag;
    this.masterScenario.project.ChannelGroup = this.userLoggedinTeam == 'UBC' ? this.selectChannels.options.filter((obj) => { return obj.Channel_Name === project.Channel })[0].Channel_Group : null;
    this.masterScenario.project.Team = project.Team;
    this.masterScenario.project.ContractType = project.ContractType;
    this.passMasterScenarioService.setScenario(this.masterScenario);
    this.route.navigate(['/ubc/masterScenario', btoa(this.masterScenario.MasterID)]);
  }

  openPBCScenario(scenarioId, scenarioVersion) {
    (scenarioVersion >= 7) ? this.route.navigate(['/pbc/scenario', btoa(scenarioId)]) : this.route.navigate(['/pbcold/scenario', btoa(scenarioId)]);

  }

  // searchScenarioAndPayer = (scList, projectName, searchText, userLoggedinTeam) => {
  //   return function () {
  //     return scList.filter(item => {
  //       if (searchText == undefined) {
  //         return true;
  //       } else {
  //         if (projectName && projectName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
  //           return true;
  //         if (item.ScenarioName && item.ScenarioName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || (userLoggedinTeam == 'UBC' ? item.Payer &&
  //           item.Payer.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 : false)) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });



  //   }


  // }

  // Preserve original property order
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  onSearch: Function = this.debounceSearch(this.getFilteredProjects, 1200);

  debounceSearch(fn, delay) {
    let timer;
    return (param) => {
      let context = this, args = param;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.call(context, param);
      }, delay);
    }
  }

}
