import { ScenarioComparisonService } from './scenario-comparison.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScenarioComparisonComponent } from './scenario-comparison.component';
import { RouterModule } from '@angular/router';
import { ScenarioComparisonRoutingModule } from './scenario-comparison-routing.module';



@NgModule({
  declarations: [ScenarioComparisonComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ScenarioComparisonRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
  ],
  providers: [ScenarioComparisonService]
})
export class ScenarioComparisonModule { }
