import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ToggleAppComponent } from './shared/components/toggle-app/toggle-app.component';

const routes: Routes = [


    {path:'dashboard',loadChildren: () => import('./modules/common/dashboard/dashboard.module').then(m => m.DashboardModule)},
    {path:'reports',loadChildren: () => import('./modules/common/reports/reports.module').then(m => m.ReportsModule)},
    {path:'analogs',loadChildren: () => import('./modules/common/analog-events/analog-events.module').then(m => m.AnalogEventsModule)},
    {path:'globalInput',loadChildren: () => import('./modules/common/global-input/global-input.module').then(m => m.GlobalInputModule)},
    {path:'dataLoad',loadChildren: () => import('./modules/common/data-load/data-load.module').then(m => m.DataLoadModule)},
    {path:'adminTab',loadChildren: () => import('./modules/common/admin-tab/admin-tab.module').then(m => m.AdminTabModule)},
    {path:'ubc',loadChildren: () => import('./modules/ubc/ubc.module').then(m => m.UbcModule)},
    {path:'pbc',loadChildren: () => import('./modules/pbc/pbc.module').then(m => m.PbcModule)},
    {path:'pbcold',loadChildren: () => import('./modules/pbc-old/pbc-old.module').then(m => m.PbcOldModule)},
    {path:'gtn',loadChildren: () => import('./modules/gtn/gtn.module').then(m => m.GtnModule)},

    { path: 'selectApplication', component: ToggleAppComponent },
    { path: '', redirectTo: '/projects', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
