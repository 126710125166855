import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApiService } from 'src/app/shared/services/server-api.service';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class ScenarioComparisonService {
  scenarioComparisonAPIs: any;

  constructor(private http: HttpClient, private apisService: ServerApiService) {
    this.scenarioComparisonAPIs = this.apisService.getApi.scenarioComparison;
  }

  getAllScenariosForComparison() {
    return this.http.get(this.scenarioComparisonAPIs.getAllScenariosForComparison);
  }

  getFilteredScenarios(params) {
    return this.http.post(this.scenarioComparisonAPIs.getFilteredScenarios, params);
  }

  confirmScenariosForComparison(params) {
    return this.http.post(this.scenarioComparisonAPIs.confirmScenariosForComparison, params);
  }

  getScenariosFromComparisonTable(params) {
    return this.http.post(this.scenarioComparisonAPIs.getScenariosFromComparisonTable, params);
  }

}
