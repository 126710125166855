<span class="footer">
  <a href="javascript:void(0)">
    <!-- <span class="footer__icon">&#169;</span> -->
    <span class="footer__text">

      <a href="http://www.zsservices.com/hosted/privacy.htm" target="_blank"
      *ngIf="resources.ShowPrivacyPolicyLink.Visible=='Y' && resources.ShowPopupBasedPrivacyPolicyLink.Visible=='N'"><span class="footer__icon">&#169;</span>{{resourceFields.PrivacyPolicyLink.text}}</a>

    <a href="javascript:void(0)" class="page-modal-trigger" (click)="privacyPolicyModal.showModal();"
    *ngIf="resources.ShowPrivacyPolicyLink.Visible=='Y' && resources.ShowPopupBasedPrivacyPolicyLink.Visible=='Y'"><span class="footer__icon">&#169;</span>{{resourceFields.PrivacyPolicyLink.text}}</a>


    </span>

  </a>



</span>

  <!-- privacy policy modal -->
  <zs-dialog #privacyPolicyModal class="zs-dialog modal" role="dialog" modal>
    <header>
      <h3 class="zs-padding-2-2-0-2 zs-text-align-left">{{resourceFields.PrivacyPolicyLink.text}}</h3>
      <span close="" (click)="privacyPolicyModal.close()">
        <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
      </span>
    </header>
    <section>
      <h4 class="zs-body-small">{{resourceFields.PrivacyPolicyDisclaimerHeader.text}}</h4>
      <hr>
      <span class="zs-body-small">{{resourceFields.PrivacyPolicyDisclaimer.text}}
      </span>
      <span class="zs-body-small"><b><u><a href="https://www.zs.com/zs-online-privacy-policy-for-hosted-applications" target="_blank">ZS Privacy Policy
            </a></u></b></span>
    </section>

  </zs-dialog>
  <!-- privacy policy modal ends-->

