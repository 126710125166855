import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScenarioComparisonComponent } from '../modules/common/scenario-comparison/scenario-comparison.component';
import { ReusableTableComponent } from './components/reusable-table/reusable-table.component';

const routes: Routes = [
  { path: 'scenarioComparison', component: ScenarioComparisonComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
