import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }


  numberCheck = function (e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    if (!(k == 45 || k == 46 || (k >= 48 && k <= 57)))  // ASCII 65 to 90 for A-Z , 97 to 122 for a-z , 8 for backspace, 32 for space, 48 to 57 for 0-9
    {
      e.preventDefault();
    }
  }

  deepCopy = function (obj) {
    obj = (obj == undefined) ? "" : obj;
    return (JSON.parse(JSON.stringify(obj)));
  }

  isUndefined = function (value) {
    if (value == undefined)
      return true;
    else
      return false;
  }

    //Function to restrict user from entering special characters in scenario name and project name
    nameCheck (e) {
      var k;
      document.all ? k = e.keyCode : k = e.which;

      if (!((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 37 || k == 45))  // ASCII 65 to 90 for A-Z , 97 to 122 for a-z , 8 for backspace, 32 for space, 48 to 57 for 0-9, 37 for %, 45 for -
      {
        e.preventDefault();
      }
    }

}
