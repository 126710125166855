import { AppService } from "./app.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BnNgIdleService } from "bn-ng-idle";

declare var Highcharts: any;

Highcharts.setOptions({
  colors: [
    "#2D8B93",
    "#FF9836",
    "#5AD767",
    "#EB6620",
    "#6328B8",
    "#FD595F",
    "#FFE949",
    "#5648FB",
    "#5AD767",
    "#ED39DB",
    "#70C2FE",
    "#E1F65D",
    "#FFC62B",
  ],
});

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  pageTitle: string;
  isLoading: boolean;
  code: string;
  idmLaunchbar: any;
  constructor(
    public appService: AppService,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private route: ActivatedRoute
  ) {
    // this.bnIdle.startWatching(1500).subscribe((res) => {
    //   if(res) {
    //       console.log("Session will expire in 5 minutes");
    //       if(confirm("Your session will expire in 5 minute(s) due to inactivity. Click OK to extend the session"))
    //       {
    //         this.appService.getLoggedInUser().subscribe(data => {
    //            console.log(data)
    //            this.bnIdle.resetTimer();
    //         });
    //       }
    //   }
    // })
  }

  // Function to set the cookie
  setCookie(name: string, value: string, days: number): void {
    // const date = new Date();
    // date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    // const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + ";path=/";
    //+ "domain="+window.origin;
  }

  fetchLoggedInUser() {
    this.isLoading = true;
    this.appService.getLoggedInUser().subscribe((data) => {
      this.appService.setLoggedInUser(data);

      this.appService.showToggleApp = data[4];

      this.appService.getIDMLaunchbar().subscribe((data) => {
        this.appService.idmLaunchbar = data;
      });
      let params: {} = { role: data[1], channelGroup: "na", team: "na" };

      this.appService.getRoleResourceMapping(params).subscribe((data) => {
        this.appService.setRoleResourceMapping(data);
        this.appService.getResourceFieldMapping().subscribe((data) => {
          this.appService.setResourceFieldMapping(data);
          if (this.appService.loggedInUser.team != "IPnC")
            this.setApprovalWorkflowInitialData().then(() => {
              this.performToggleAppSpecificOperations();
            });
        });
      });

      this.improveScrollPerformanceOnMouseWheel();

      this.appService.getUserIdNamesMapping().subscribe((data) => {
        this.appService.setUserIdNamesMapping(data);
      });
      this.isLoading = false;
    });
  }
  fetchLoggedInUserForPingAuth() {
    this.isLoading = true;
    this.appService.getLoggedInUser().subscribe((data) => {
      this.appService.setLoggedInUser(data);
      this.router.navigateByUrl("");
      this.isLoading = false;
    });
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }
  clearCookie() {
    this.setCookie("code", "", 0);
  }
  ngOnInit(): void {
    setTimeout(() => {
      const codeCookie = this.getCookie("code");
      if (!codeCookie) {
        this.route.queryParamMap.subscribe((params) => {
          this.code = params.get("code");
          if (this.code) {
            this.setCookie("code", this.code, 1);
            this.fetchLoggedInUserForPingAuth();
          } else {
            this.fetchLoggedInUser();
          }
        });
      } else {
        this.fetchLoggedInUser();
      }
    });
  }

  updatePageTitle(componentReference) {
    this.pageTitle = componentReference.pageTitle;
  }

  setApprovalWorkflowInitialData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.appService.resources.ApprovalWorkflow.Visible == "Y") {
        this.appService
          .userSpecificApprovalWorkflowActions()
          .subscribe((data) => {
            this.appService.approversList = data.approversList;
            this.appService.tempApproversList = data.approversList;
            this.appService.usersNameObj = data.usersObj;
            this.appService.isApprover = data.isApprover;
            this.appService.defaultApprover = data.defaultApprover
              ? data.defaultApprover
              : "";
            this.appService.userActionList = data.userActionList;
            resolve("success");
          });
      } else {
        resolve("success");
      }
    });
  }

  performToggleAppSpecificOperations() {
    if (this.appService.loggedInUser.team === "") {
      this.router.navigate(["/selectApplication"]);
    } else {
      if (
        !window ||
        window.location.pathname == "/" ||
        window.location.pathname == ""
      ) {
        this.router.navigate(["/projects"]);
      }
      this.appService.getLastDataRefreshDate().subscribe((data) => {
        this.appService.lastDataRefreshDate = data[0].refreshDate;
      });
    }
  }

  improveScrollPerformanceOnMouseWheel() {
    setTimeout(() => {
      //prevent mousewheel event bubbling up from main page. This is to stop the propagation so ancestor event listeners over mousewheel won't execute and
      // fill up call back queue
      let rootPageRef = document.querySelector(".main-page");
      if (rootPageRef) {
        rootPageRef.addEventListener(
          "mousewheel",
          function (event) {
            event.stopPropagation();
          },
          { passive: true } //a passive event listener to let modern browser know that this listener is not going to use preventDefault hence do a scroll first and not wait for callback to be executed
        );
      }
    }, 0);
  }
}
