import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CommonService } from './common.service';
import { ServerApiService } from './server-api.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowService {
  approvalWorkflowAPIs: any;

  constructor(private appService: AppService, private http: HttpClient, private apisService: ServerApiService, private commonService: CommonService) {
    this.approvalWorkflowAPIs = apisService.getApi.approvalWorkflow;
  }

  getStatusList = (): Observable<any> => this.http.get(this.approvalWorkflowAPIs.getStatusList);
  updateStatusForSingleton = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.updateStatusForSingleton, { params });
  submitScenarioForApproval = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.submitScenarioForApproval, params);
  updateScenarioDataOnUserAction = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.updateScenarioDataOnUserAction, params);
  getStatusLogs = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.getStatusLogs, params);
  getApproverScenarios = (): Observable<any> => this.http.get(this.approvalWorkflowAPIs.getApproverScenarios);
  getScenarioApproversList = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.getScenarioApproversList, params);
  getScenarioType = (params): Observable<any> => this.http.post(this.approvalWorkflowAPIs.getScenarioType, params);

  setApprovalSpecificScenarioData(scenarioParams): any {

    let res = {
      readOnlyMode: false,
      previousApproversList: [],
      currentScenarioApprover: '',
      pendingApprover: '',
      isScenarioApprover: false
    }

    return new Promise((resolve, reject) => {
      let params = {
        scenarioID: scenarioParams.scenarioID
      }
      scenarioParams.httpReqSub?.next(1);
      this.getScenarioType(params).subscribe(result => {
        if (result.ParentType == 'Child') {
          params.scenarioID = result.MasterID;
        }

        res.readOnlyMode = false;
        scenarioParams.httpReqSub?.next(1);
        this.appService.tempApproversList = this.commonService.deepCopy(this.appService.approversList);
        this.getScenarioApproversList(params).subscribe(data => {
          let currentStatus = 1;
          //status dependent block
          if (data.length > 0 && scenarioParams.scenarioID) {
            currentStatus = data[0].Status_ID;
            res.pendingApprover = data.filter(a => a.isActive == 'Y')[0]?.approver;
            res.previousApproversList = [...new Set(data.map(approverMapping => approverMapping.approver))];
            res.currentScenarioApprover = (currentStatus == 8 || currentStatus == 6) ? res.previousApproversList[0] : '';
            this.appService.tempApproversList = this.commonService.deepCopy([res.currentScenarioApprover]);
          }
          else {
            res.previousApproversList = [];
            res.currentScenarioApprover = '';
          }

          //user role dependent block
          if (res.previousApproversList.indexOf(this.appService.loggedInUser.name) > -1) {
            res.isScenarioApprover = true;
            res.currentScenarioApprover = this.getCurrentApproverForAnotherApprover(res.previousApproversList);
            this.appService.tempApproversList = (res.currentScenarioApprover == '') ? this.commonService.deepCopy(this.appService.approversList) : this.commonService.deepCopy([res.currentScenarioApprover]);
          }

          //setting read-only mode when required
          if (res.isScenarioApprover || (currentStatus != 6 && currentStatus != 8 && currentStatus != 1)) {
            res.readOnlyMode = true;
          }
          scenarioParams.httpReqSub?.next(-1);
          resolve(res);
        });
        scenarioParams.httpReqSub?.next(-1);
      });
    });
  }

  getCurrentApproverForAnotherApprover(previousApproversList) {
    let approver = '';
    for (let idx = 0; idx < previousApproversList.length; idx++) {
      if (this.appService.approversList.indexOf(previousApproversList[idx]) > -1) {
        approver = previousApproversList[idx];
        break;
      }
    }
    return approver;
  }

}
