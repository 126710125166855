import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassDirectionService {

  constructor() { }

  //normal direction
  result = {
    productIndex: 0,
    direction: "",
    impactValue: 0
  };
  //used for affiliations
  direction = "";

  setDirection = function (obj) {
    this.result = obj;
  };

  getDirection = function () {
    return this.result;
  };

  setAffiliationDirection = function (obj) {
    this.direction = obj;
  };

  getAffiliationDirection = function () {
    return this.direction;
  };

  clearDirections (){
    this.result = {
      productIndex: 0,
      direction: "",
      impactValue: 0
    };
    this.direction = '';
  }
}
