<div class='help-tab' [appLoading]="isLoading" [loaderColor]='loaderSelector' [helpTabLoader]='true'>

    <zs-dialog modal open class="zs-dialog modal" #helpTabModalRef role="dialog" >
        <header>
            <h4>Help Section</h4>
            <span class="close-icon" close="" onclick="this.parentNode.parentNode.close()" (click)="closeModal()">
                <a href="javascript:void(0)" class="zs-interactive-primary"><span
                        class="zs-icon zs-size-xl zs-icon-close-circle-fill"></span></a>
            </span>
        </header>
        <section class="content-section zs-padding-0-2-2-2">
            <div *ngIf="helpTabsData.length>0" class="zs-grid-row">
                <div class="navigation-column">
                    <nav id="main-navigation" *ngFor="let row of helpTabsData; let tabIndex = index;"
                        [ngClass]="{'zs-border-top': tabIndex==0}"
                        class="tab-row zs-toolbar zs-border-bottom zs-toolbar-vertical zs-toolbar-labels-below">
                        <a [ngClass]="{'zs-active': row.TabID==tabID.selected}" href="javascript:"
                            class="tab-row__text zs-link-action" (click)="selectTab(row.TabID)">{{row.Item_Text}}</a>
                    </nav>
                </div>
                <div #questionAnswerBlock class="question-answer-column">
                    <ng-container *ngFor="let questionSection of helpTabsFaq; let row = index;">
                        <zs-accordion class="zs-accordion zs-display-block" animate="">
                            <div class="question-answer-column__question zs-bg-surface-standard zs-cursor-pointer"
                                expandable-header="">
                                <span class="question-answer-column__question__text-section">
                                    <a href="javascript:void(0)"
                                        class="zs-icon zs-icon-arrow-down zs-size-xl zs-text-caption"
                                        expandable-icon=""></a>
                                    <div class="zs-margin-0-0-0-2">
                                        <span [innerHtml]="helpTabsFaqDataQuestion[row]"
                                            class="zs-size-s zs-font-weight-semi-bold" expandable-header-text=""></span>
                                    </div>
                                </span>
                            </div>
                            <div [innerHtml]="helpTabsFaqDataAnswer[row]"
                                class="question-answer-column__answer zs-bg-surface-1 zs-display-none"
                                expandable-body="">
                            </div>
                        </zs-accordion>
                        <br>
                    </ng-container>
                </div>
                <div class="others-column">
                    <div class="others-column__guide-section" *ngIf="userGuideShow">
                        <fieldset class="others-column__guide-section__field">
                            <legend>User Guide</legend>
                            <br />
                            <div class="file-section">
                                <label *ngIf="attachLinkShow">
                                    <span>
                                        <input type="file" #attachedUserGuide class="attachedUserGuide" (change)="addUserGuide($event.target)" accept=".pdf, .doc, .docx, .txt, .ppt, .pptx, .xls, .xlsx">
                                    </span>
                                    <span class="error-message">{{attachFileErrorMsg}}</span>
                                </label>
                                <div *ngIf="attachmentUserGuideShow" class="user-guide-details">
                                    <div class="file-name">
                                        <label >
                                            <a (click)="downloadFile()">{{userGuideDetails[0].File_Name}}</a>
                                        </label>    
                                    </div>
                                    
                                    <div class="remove-text" (click)="removeFileName()" *ngIf="appService.loggedInUser.role=='Admin'">
                                        <span class="zs-icon zs-size-l zs-icon-delete" >

                                        </span>
                                        <span>Remove</span>
                                        <br /><br />
                                    </div>
                                        
                                </div>
                                
                            </div>
                        </fieldset>
                    </div>
                    <div class="others-column__link-section">
                        <fieldset class="others-column__link-section__field">
                            <legend>Useful links</legend>
                            <div class="links">
                                <ng-container *ngFor="let link of helpTabsLinksData; let row = index;">
                                    <br />
                                    <span [innerHtml]="helpTabsLinks[row]"></span>
                                </ng-container>
                                <br />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </section>
        <footer class="footer zs-text-align-center">
        </footer>
    </zs-dialog>

</div>
