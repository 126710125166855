import { AppService } from './../../../app.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  constructor(public appService: AppService) { }

  ngOnInit(): void {
    if (this.appService.showAlert.message && this.appService.showAlert.messageType && this.appService.showAlert.show) {
      this.appService.showAlert.show = true;
      setTimeout(() => {
        this.appService.showAlert.show = false;
      }, this.appService.showAlert.timer);
    }
  }

  getClasses() {
    let iconClasses: string = '';
    let bgAndBorderClasses: string = '';

    switch (this.appService.showAlert.messageType) {
      case 'success':
        iconClasses = 'zs-icon-check-circle-fill zs-text-success';
        bgAndBorderClasses = 'zs-bg-success-secondary zs-border-color-success-secondary';
        break;

      case 'error':
        iconClasses = 'zs-icon-error-triangle-fill zs-text-error';
        bgAndBorderClasses = 'zs-bg-error-secondary zs-border-color-error-secondary';
        break;
      default:
        break;
    }

    return {
      iconClasses,
      bgAndBorderClasses
    };
  }


}
