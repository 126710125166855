<!-- for 404 error -->
<section>
  <h2>Page Not Found.</h2>
  <p>Sorry, the content you're looking for can't be located. Please try one of the following:</p>
  <ul>
    <li><a onclick="window.history.back()" href="javascript:void(0)">Go back</a> and try again.</li>
    <li>Double check your information.</li>
    <li>Send technical support an <a href="mailto:support@zs.com" target="_top">email</a>.</li>
  </ul>
</section>


<!-- for 5XX errors -->
<!-- <section>
  <h2>Service Unavailable.</h2>
  <p>Sorry, our server is currently busy. Please try one of the following:</p>
  <ul>
    <li><a onclick="window.history.back()" href="javascript:void(0)">Go back</a> and try again.</li>
    <li>Double check your information.</li>
    <li>Send technical support an <a href="mailto:support@zs.com" target="_top">email</a>.</li>
  </ul>
</section> -->
