import { Injectable } from '@angular/core';
import { PerCapitaData } from '../../models/perCapitaData.model';

@Injectable({
  providedIn: 'root',
})
export class PassScenarioService {
  constructor() {}

  scenario = {
    ProjectSummary: {
      id: '',
      Projectname: '',
      Brand: '',
      Notes: '',
      CreatedBy: '',
    },
    SelectedpayerSummary: {
      Payer: '',
      Channel: '',
      ChannelGroup: '',
      Lives: '',
      selectedPlans: [],
      Plans: '',
      Control: { ControlType: '', ControlValue: 0 },
      userDefinedControl: { ControlType: '', ControlValue: 0 },
      'Market TRx': '',
      Source: '',
      nationalConcessionsData: [],
      aspEligibleData: [],
    },
    ScenarioSummary: {
      ScName: '',
      Access: 'Private',
      AccessOptions: ['Private', 'Public'],
      Notes: '',
      CreatedBy: '',
      CreatedDate: '',
      ModifiedDate: '',
      Status_ID: 0,
      Status: '',
      ParentType: '',
      MasterScName: '',
      EnableRefresh: '',
      EnableConfigRefresh: '',
      PBCSummary: {},
      ScenarioVersion: '',
      FilterJson: {},
    },
    MasterScenarioSummary: {
      MasterScID: '',
      MasterScName: '',
      Brand: '',
      MasterScAccess: '',
      MasterScStatus: '',
      MasterScOwner: '',
    },
    //check why the default value of Analogevents property was set as blank array
    SelectedAnalogSummary: { Analogevents: [{ deal: [], noDeal: [] }] },
    // SelectedAnalogSummary: { Analogevents: [] },
    ProductList: {},
    FamilyMarket: {},
    RedirectingFrom: '',
    ScenarioSource: '',
    ScenarioSourceData: '',
    userDefindedTimeSpec: [],
    userDefinedBaselineMonth: { selected: 5 },
    perCapitaPassData: {
      eligiblePatient: {},
      units: {},
      TRx: {},
      TRxSharePercent: {},
      livesSharePercent: {},
      adjustedPayerLives: 0,
      selectedChannel: '',
      selectedTherapyArea: '',
      contractPeriod: {},
      unitsToTrx: {},
      livesToUnits: {},
      dollarPerUnit: {},
      selectedMode: '',
      patientRecordData: [],
      scalingFactor: []
    },
  };

  financialData = {
    ScenarioName: '',
    data: {
      financialConfiguration: [],
      financialElements: [],
      CompanyProductList: {},
      CompanyProductCount: '',
      SelectedPrdIndex: '',
      financialInputConfiguration: [],
      CompanyProductListWithoutAggregated: {},
    },
  };

  customerPOVData = {
    ScenarioName: '',
    data: {
      customerPOVConfiguration: [],
      customerPOVElements: [],
      tempCustomerPOVCalculationData: [],
      povInputDetails: [],
    },
  };

  passingData = {};
  baselineData = {};

  competitorPOVData = {
    data: {
      competitorPOVConfiguration: [],
      competitorPOVElements: [],
      tempCompetitorPOVCalcData: [],
      IncrementalRebate: 0,
      index: 0,
      CompPovMetrics: {
        timeCategory: {
          selected: 'Quarterly',
          options: ['Quarterly', 'Calendar Year', 'Contract Year'],
        },
        dealNoDeal: {
          selected: 'dealNoDeal',
          options: {
            dealNoDeal: 'Deal/No Deal',
            delta: 'Delta(Deal minus No Deal)',
          },
        },
      },
    },
  };

  sensitivityData = {
    ScenarioName: '',
    data: {
      InputMetricStructure: [],
      SAOutputJson: {},
      inputDropDownlist: [],
      RelativeAbsoluteFlag: 'Relative',
    },
  };

  dynamicCalc = {};

  private perCapita: PerCapitaData = {
    selectedMode: '',
    eligiblePatient: {},
    units: {},
    TRx: {},
    TRxSharePercent: {},
    livesSharePercent: {},
    adjustedPayerLives: 0,
    selectedChannel: '',
    selectedTherapyArea: '',
    contractPeriod: {},
    unitsToTrx: {},
    livesToUnits: {},
    dollarPerUnit: {},
    patientRecordData: [],
    scalingFactor: []
  };

  setFinancialData = function (obj) {
    this.financialData = obj;
  };

  getFinancialData = function () {
    return this.financialData;
  };

  setCustomerPOVData = function (obj) {
    this.customerPOVData = obj;
  };

  getCustomerPOVData = function () {
    return this.customerPOVData;
  };

  setCompetitorPOVData = function (obj) {
    this.competitorPOVData = obj;
  };

  getCompetitorPOVData = function () {
    return this.competitorPOVData;
  };

  setSensitivityData = function (obj) {
    this.sensitivityData = obj;
  };

  getSensitivityData = function () {
    return this.sensitivityData;
  };

  setScenario = function (obj) {
    this.scenario = obj;
  };
  getScenario = function () {
    return this.scenario;
  };

  setPassingData = function (obj) {
    this.passingData = obj;
  };

  getPassingData = function () {
    return this.passingData;
  };

  setDynamicCalc(data) {
    this.dynamicCalc = data;
  }

  getDynamicCalc() {
    return this.dynamicCalc;
  }

  clearScenarioData() {
    this.scenario = {
      ProjectSummary: {
        id: '',
        Projectname: '',
        Brand: '',
        Notes: '',
        CreatedBy: '',
      },
      SelectedpayerSummary: {
        Payer: '',
        Channel: '',
        ChannelGroup: '',
        Lives: '',
        selectedPlans: [],
        Plans: '',
        Control: { ControlType: '', ControlValue: 0 },
        userDefinedControl: { ControlType: '', ControlValue: 0 },
        'Market TRx': '',
        Source: '',
        nationalConcessionsData: [],
        aspEligibleData: [],
      },
      ScenarioSummary: {
        ScName: '',
        Access: 'Private',
        AccessOptions: ['Private', 'Public'],
        Notes: '',
        CreatedBy: '',
        CreatedDate: '',
        ModifiedDate: '',
        Status_ID: 0,
        Status: '',
        ParentType: '',
        MasterScName: '',
        EnableRefresh: '',
        EnableConfigRefresh: '',
        PBCSummary: {},
        ScenarioVersion: '',
        FilterJson: {},
      },
      MasterScenarioSummary: {
        MasterScID: '',
        MasterScName: '',
        Brand: '',
        MasterScAccess: '',
        MasterScStatus: '',
        MasterScOwner: '',
      },
      //check why the default value of Analogevents property was set as blank array
      SelectedAnalogSummary: { Analogevents: [{ deal: [], noDeal: [] }] },
      // SelectedAnalogSummary: { Analogevents: [] },
      ProductList: {},
      FamilyMarket: {},
      RedirectingFrom: '',
      ScenarioSource: '',
      ScenarioSourceData: '',
      userDefindedTimeSpec: [],
      userDefinedBaselineMonth: { selected: 5 },
      perCapitaPassData: {
        eligiblePatient: {},
        units: {},
        TRx: {},
        TRxSharePercent: {},
        livesSharePercent: {},
        adjustedPayerLives: 0,
        selectedChannel: '',
        selectedTherapyArea: '',
        contractPeriod: {},
        unitsToTrx: {},
        livesToUnits: {},
        dollarPerUnit: {},
        selectedMode: '',
        patientRecordData: [],
        scalingFactor: []
      },
    };

    this.financialData = {
      ScenarioName: '',
      data: {
        financialConfiguration: [],
        financialElements: [],
        CompanyProductList: {},
        CompanyProductCount: '',
        SelectedPrdIndex: '',
        financialInputConfiguration: [],
        CompanyProductListWithoutAggregated: {},
      },
    };

    this.customerPOVData = {
      ScenarioName: '',
      data: {
        customerPOVConfiguration: [],
        customerPOVElements: [],
        tempCustomerPOVCalculationData: [],
        povInputDetails: [],
      },
    };

    this.passingData = {};

    this.competitorPOVData = {
      data: {
        competitorPOVConfiguration: [],
        competitorPOVElements: [],
        tempCompetitorPOVCalcData: [],
        IncrementalRebate: 0,
        index: 0,
        CompPovMetrics: {
          timeCategory: {
            selected: 'Quarterly',
            options: ['Quarterly', 'Calendar Year', 'Contract Year'],
          },
          dealNoDeal: {
            selected: 'dealNoDeal',
            options: {
              dealNoDeal: 'Deal/No Deal',
              delta: 'Delta(Deal minus No Deal)',
            },
          },
        },
      },
    };

    this.sensitivityData = {
      ScenarioName: '',
      data: {
        InputMetricStructure: [],
        SAOutputJson: {},
        inputDropDownlist: [],
        RelativeAbsoluteFlag: 'Relative',
      },
    };

    this.dynamicCalc = {};
  }

  public set perCapitaData(perCapitaData: PerCapitaData) {
    this.perCapita = perCapitaData;
  }

  public get perCapitaData() {
    return this.perCapita;
  }

  setBaseline = function (obj) {
    this.baselineData = obj;
  };
  getBaseline = function () {
    return this.baselineData;
  };
}
