import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  getFileErrorMessage = function (file, validExtensions, acceptedFileSize, maxFileNameCharLength, attachedFileNames, maxLimitOfFiles) {
    let fileLocation = file.value;
    if (fileLocation != '') {

      //1. allowed max limit of files check
      if (attachedFileNames && attachedFileNames.length >= maxLimitOfFiles) {
        return ("Maximum number of files allowed are: " + maxLimitOfFiles.toString());
      }

      //2. valid extension check
      let isValidExt = false;
      isValidExt = validExtensions.indexOf(fileLocation.substring(fileLocation.lastIndexOf(".") + 1)) >= 0 ? true : false;

      if (!isValidExt) {
        return ("Invalid file type. Accepted types: " + validExtensions);
      }

      //3. max allowable size check
      if (file.files[0].size > acceptedFileSize) {
        return ("File size should be less than " + (acceptedFileSize / 1000000).toString() + " MB");
      }

      //4. max allowable file name length check            
      var fileName = file.files[0].name.split('.')[0];
      if (fileName.length > maxFileNameCharLength) {
        return ("File name exceeding " + maxFileNameCharLength + " characters not allowed");
      }

      //5. allowed special chars check
      var isValidInp = true;
      for (var idx = 0; idx < fileName.length; idx++) {
        var k = fileName.charCodeAt(idx);
        if (!((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || k == 32 || (k >= 48 && k <= 57) || k == 37 || k == 45 || k == 46 || k == 40 || k == 41 || k == 95))  // ASCII 65 to 90 for A-Z , 97 to 122 for a-z , 32 for space, 48 to 57 for 0-9, 37 for %, 45 for -, 46 for ., 40 for (, 41 for ), 95 for _
        {
          isValidInp = false;
          break;
        }
      }
      if (!isValidInp) {
        return ("Invalid file name.Accepted special characters: '%', '-', '.', '(', ')', '_'");
      }

      //6. duplicate file name check
      if (attachedFileNames) {
        for (var i = 0; i < attachedFileNames.length; i++) {
          if (fileName == attachedFileNames[i].split('.')[0] && (attachedFileNames[i].split('.')[1] == fileLocation.split('.')[1])) {
            return ("Duplicate file");
          }
        }
      }

      return '';
    }
    else {
      return ("Please select a file");
    }
  }

  downloadFileData = function (file, linkElement) {
    var fileName = file.fileName;
    var contentType = file.contentType;
    var arrayBufferData = this.bufferToArrayBuffer(file.fileData.data);

    try {
      var blob = new Blob([arrayBufferData], { type: contentType });
      var url = window.URL.createObjectURL(blob);

      linkElement.setAttribute('href', url);
      linkElement.setAttribute("download", fileName);

      var clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      linkElement.dispatchEvent(clickEvent);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  arrayBufferToBuffer = function (arrayBuffer) {
    var bufferData = new Array(arrayBuffer.byteLength);
    var view = new Uint8Array(arrayBuffer);
    for (var i = 0; i < bufferData.length; ++i) {
      bufferData[i] = view[i];
    }
    var buffer = {
      type: 'buffer',
      data: bufferData
    }
    return buffer;
  }

  bufferToArrayBuffer = function (buffer) {
    var arrayBuffer = new ArrayBuffer(buffer.length);
    var view = new Uint8Array(arrayBuffer);
    for (var i = 0; i < buffer.length; ++i) {
      view[i] = buffer[i];
    }
    return arrayBuffer;
  }
}
