import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

import { AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-toggle-app',
  templateUrl: './toggle-app.component.html',
  styleUrls: ['./toggle-app.component.scss']
})
export class ToggleAppComponent implements OnInit, AfterViewInit {
  applicationsList: string[];
  selectedApp: string;
  UBCPlaceholder: string;
  PBCPlaceholder: string;
  GTNPlaceholder: string;
  teamApplicationList = {}

  teamSelected: string;
  @ViewChild('toggleApplicationModalRef')
  toggleApplicationModalRef: ElementRef;


  constructor(public appService: AppService, private router: Router) {
    //TODO::RM- Remove the hardcoded values when DB is updated with migration
    // this.appService.resourceFields.UBCTeamTogglePlaceholder.text = 'Payer';
    // this.appService.resourceFields.PBCTeamTogglePlaceholder.text = 'Provider';
    this.UBCPlaceholder = this.appService.resourceFields.UBCTeamTogglePlaceholder.text;
    this.PBCPlaceholder = this.appService.resourceFields.PBCTeamTogglePlaceholder.text;
    this.GTNPlaceholder = this.appService.resourceFields.GTNTeamTogglePlaceholder.text;

    this.teamApplicationList = {
      'UBC': this.UBCPlaceholder,
      'PBC': this.PBCPlaceholder,
      'IPnC': this.GTNPlaceholder
    }

    switch (this.appService.loggedInUser.team) {
      case 'UBC':
        this.teamSelected = this.UBCPlaceholder;
        break;
      case 'PBC':
        this.teamSelected = this.PBCPlaceholder;
        break;
      case 'IPnC':
        this.teamSelected = this.GTNPlaceholder;
        break;

      default:
        break;
    }
    // this.teamSelected = this.appService.loggedInUser.team == 'UBC' ? this.UBCPlaceholder : this.appService.loggedInUser.team == 'PBC' ? this.PBCPlaceholder : '';
  }

  ngOnInit(): void {
    this.selectedApp = this.teamSelected;
    // this.applicationsList = [this.UBCPlaceholder, this.PBCPlaceholder, this.GTNPlaceholder];
    this.applicationsList = this.appService.loggedInUser.teamList;
  }

  ngAfterViewInit(): void {
    this.toggleApplicationModalRef.nativeElement.showModal();
  }

  selectApplication(application): void {
    this.selectedApp = application;
  }

  async confirmApplication() {
    let selectedTeam = this.selectedApp === this.UBCPlaceholder ? 'UBC' : this.selectedApp === this.PBCPlaceholder ? 'PBC' : '';
    (this.selectedApp == 'IPnC') && (selectedTeam = this.GTNPlaceholder);

    let params = { selectedTeam };
    this.appService.setSelectedApplication(params).subscribe(async data => {
      this.appService.setLoggedInUser(data);

      this.appService.getLastDataRefreshDate().subscribe(data => {
        this.appService.lastDataRefreshDate = data[0].refreshDate;
      });
      if (this.appService.loggedInUser.team == 'IPnC') {
        await this.setResourcesGTN();
        this.router.navigate(['\gtn']);
      } else
        this.router.navigate(['\projects']);
    });
  }

  closeModal(): void {
    this.appService.loggedInUser.team == 'IPnC' ? this.router.navigate(['\gtn']) : this.router.navigate(['\projects']);
  }

  setResourcesGTN() {
    return new Promise((resolve, rejects) => {
      let params: {} = { role: this.appService.loggedInUser.role, channelGroup: 'na', team: 'na' };
      this.appService.getRoleResourceMappingGTN(params).subscribe(data => {
        this.appService.setRoleResourceMappingGTN(data);
        this.appService.getResourceFieldMappingGTN().subscribe(data => {
          this.appService.setResourceFieldMapping(data);
          resolve('success');
        });
      });
    })
  }
}
