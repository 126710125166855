import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassMasterScenarioService {

  constructor() { }

  MasterScenario = {
    MasterID: 0,
    MasterScName: "",
    ChildScenarioCount: 0
  };

  setScenario = function (obj) {
    this.MasterScenario = obj;
  };

  getScenario = function () {
    return this.MasterScenario;
  };

}
