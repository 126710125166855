import { PassDirectionService } from './shared/services/pass-direction.service';
import { PassScenarioService } from './shared/services/pass-scenario.service';
import { ResourcesJsonInitService } from './shared/services/resources-json-init.service';
import { ServerApiService } from './shared/services/server-api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  loggedInUser = { name: '', role: '', team: '', teamList: [] };
  homeApis;
  resources;
  resourceFields;
  userGroupID = "null";
  lastDataRefreshDate = '';
  openFAQSectionFlag: boolean = false;
  openFAQSectionDirection: string = '';
  showAlert: any = {
    message: null,
    messageType: null,
    show: false,
    timer: 0
  };
  loaderColors: { solid_grey: string; pine_green: string; } = {
    solid_grey: '#e4e4e4',
    pine_green: 'rgba(2, 45, 66, 0.4)'
  };
  showToggleApp: boolean = false;
  TimeData: any = []; //UCLng please remove from here and use from rspc myscenario service
  idmLaunchbar: any;
  userTier: string = '';
  userActionList = [];
  approversList = [];
  tempApproversList = [];
  usersNameObj = {};
  isApprover: boolean = false;
  defaultApprover: string = '';

  constructor(private http: HttpClient, private apisService: ServerApiService, private initiateResourcesJsonService: ResourcesJsonInitService,
    private passScenarioService: PassScenarioService, private passDirectionService: PassDirectionService
  ) {
    this.homeApis = this.apisService.getApi.home;
    this.resources = this.initiateResourcesJsonService.resources;
    this.resourceFields = this.initiateResourcesJsonService.resourceFields;
  }

  getLoggedInUser() {
    return this.http.get(this.homeApis.getLoggedInUser, { withCredentials: true });
  }

  setLoggedInUser(userDetails) {
    this.loggedInUser.name = userDetails[0];
    this.loggedInUser.role = userDetails[1];
    this.loggedInUser.team = userDetails[2];
    this.loggedInUser.teamList = userDetails[3];
  }

  getRoleResourceMapping(params) {
    return this.http.post(this.homeApis.getRoleResourceMapping, params, { withCredentials: true });
  }

  getAllUsers() {
    return this.http.get(this.apisService.getApi.users.getAllUsers , { withCredentials: true })
  }

  addUser(params) {
    return this.http.post(this.apisService.getApi.users.addUser ,params , { withCredentials: true })
  }

  updateUser(params) {
    return this.http.put(this.apisService.getApi.users.updateUser ,params , { withCredentials: true })
  }

  dropdownConfig() {
    return this.http.get(this.apisService.getApi.users.getDropdownConfig ,{withCredentials : true})
  }

  deleteUser(params) {
    return this.http.post(this.apisService.getApi.users.deleteUser ,params , { withCredentials: true })
  }

  getRoleResourceMappingGTN(params) {
    return this.http.post(this.homeApis.getRoleResourceMapping, params, { withCredentials: true });
  }

  setRoleResourceMapping(dataFromDB) {
    Object.keys(this.resources).forEach(key => {
      this.resources[key].Visible = 'N';
    });

    if (dataFromDB.length !== 0) {
      let iterated = false;
      Object.keys(this.resources).forEach(key => {
        iterated = false;
        dataFromDB.forEach(resourceDB => {
          if (iterated === false) {
            if (resourceDB.Resource_ID === this.resources[key].ID) {
              this.resources[key].Visible = resourceDB.Is_Visible;
              iterated = true;
            }
          }
        });
      });
    }
  }

  setRoleResourceMappingGTN(dataFromDB){
    if (this.loggedInUser.team == 'IPnC') {
      this.resources = this.initiateResourcesJsonService.resourcesGTN;

      Object.keys(this.resources).forEach(key => {
        this.resources[key].Visible = 'N';
      });

      if (dataFromDB.length !== 0) {
        let iterated = false;
        Object.keys(this.resources).forEach(key => {
          iterated = false;
          dataFromDB.forEach(resourceDB => {
            if (iterated === false) {
              if (resourceDB.resource_ID === this.resources[key].ID) {
                this.resources[key].Visible = resourceDB.is_visible;
                iterated = true;
              }
            }
          });
        });
      }
    }
  }

  getResourceFieldMapping() {
    return this.http.get(this.homeApis.getResourceFieldMapping, { withCredentials: true });
  }

  getResourceFieldMappingGTN() {
    return this.http.get(this.homeApis.getResourceFieldMapping, { withCredentials: true });
  }

  setResourceFieldMapping(dataFromDB) {
    if(this.loggedInUser.team == 'IPnC'){
      this.resourceFields = this.initiateResourcesJsonService.resourceFieldsGTN;
    }

    if (dataFromDB.length !== 0) {
      Object.keys(this.resourceFields).forEach(key => {
        let resource = this.resourceFields[key];
        let iterated = false;
        dataFromDB.forEach(resourceDB => {
          if (iterated === false) {
            if (this.loggedInUser.team == 'IPnC') {
              if (resource.fieldName.toUpperCase() === resourceDB.field_name.toUpperCase()) {
                resource.text = resourceDB.field_text;
                iterated = true;
              }
            } else
              if (resource.fieldName.toUpperCase() === resourceDB.FIELD_NAME.toUpperCase()) {
                resource.text = resourceDB.FIELD_TEXT;
                iterated = true;
              }
          }
        });
      });
    }
  };

  getLastDataRefreshDate() {
    return this.http.get(this.homeApis.getDataRefreshDate);
  }

  setSelectedApplication(params) {
    return this.http.post(this.homeApis.setSelectedApplication, params);
  }
  getIDMLaunchbar() {
    return this.http.get(this.homeApis.getIDMLaunchbar, { withCredentials: true });
  }

  openFAQSection(direction) {
    this.openFAQSectionFlag = true;
    this.openFAQSectionDirection = direction;
  }

  closeFAQSection() {
    this.openFAQSectionFlag = false;
    this.openFAQSectionDirection = '';
  }

  showToast(message: string, messageType: 'success' | 'warning' | 'error' | 'info', show: boolean, timer: number) {
    this.showAlert.message = message;
    this.showAlert.messageType = messageType;
    this.showAlert.show = show;
    this.showAlert.timer = timer;
  }

  clearDataOnClientSession() {
    this.passScenarioService.clearScenarioData();
    this.passDirectionService.clearDirections();
  }

  private allUserIdNamesMapping = {};
  getUserNameFromId(userId: string){
    return this.allUserIdNamesMapping[userId.toLowerCase()] || userId;
  }

  getIdFromUserName(name: string){

    for(const key in this.allUserIdNamesMapping){
      if(this.allUserIdNamesMapping[key] == name)
        name = key;
    }
    return name;
  }
  getUserIdNamesMapping(){
    return this.http.get(this.homeApis.getUserIdNamesMapping, { withCredentials: true });
  }
  setUserIdNamesMapping(data) {
    this.allUserIdNamesMapping = data;
  }

  /**
 * SK: To swap user Ids(ISID's) with user actual names and vice-versa
 */
  swapUserIdAndNames(cb: Function) {
    return (dataSrc: string | Array<string>) => {
      var res;
      var isCommaSparatedString = false;
      if (typeof (dataSrc) === 'string') {
        isCommaSparatedString = true;
        dataSrc = dataSrc.split(',');
      }

      dataSrc = dataSrc.map(userId => {
        /**
         * The first params is a reference to what this refers to inside callback method
         */
        return cb.call(this, userId);
      });

      if (isCommaSparatedString)
        dataSrc = dataSrc.toString();

      res = dataSrc;
      return res;
    }
  };

  /**
   * SK: Holds the reference to inner function on memory execution phase.
   * When called (code execution phase) the inner function takes callback method
   */

  replaceAllUserIdsWithNames: Function = this.swapUserIdAndNames(this.getUserNameFromId);

  /**
  * SK: Holds the reference to inner function on memory execution phase.
  * When called (code execution phase) the inner function takes callback method
  */
  replaceAllNamesWithUserIds: Function = this.swapUserIdAndNames(this.getIdFromUserName);

  userSpecificApprovalWorkflowActions(): any {
    return this.http.get(this.homeApis.userSpecificApprovalWorkflowActions);
  }

  toggleAccordian(event) {
    var sourceNode = <HTMLElement>event.target;
    //to make caret icon clickable
    if (sourceNode.className.includes("zs-icon"))
      sourceNode = <HTMLElement>sourceNode.nextSibling;
    var targetNode = sourceNode.parentElement.nextElementSibling;
    var isOpen = !targetNode.classList.contains('zs-display-none');
    if (!isOpen) {
      sourceNode.previousElementSibling.classList.replace('zs-icon-carat-down', 'zs-icon-carat-up');
      targetNode.classList.remove('zs-display-none');
      sourceNode.innerText = " Hide Chart";
      // targetNode.classList.add('zs-bg-surface-2');

    } else {
      sourceNode.previousElementSibling.classList.replace('zs-icon-carat-up', 'zs-icon-carat-down');
      targetNode.classList.add('zs-display-none');
      sourceNode.innerText = " Show Chart";
    }
  }

}
