import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ScenarioComparisonService } from './scenario-comparison.service';
declare var Highcharts: any;
declare var window: any;
Highcharts.setOptions({
  colors: ['#2D8B93', '#FF9836', '#5AD767', '#EB6620', '#5648FB', '#18F3CC', '#FF9655', '#FFF263', '#6AF9C4']
});

@Component({
  selector: 'app-scenario-comparison',
  templateUrl: './scenario-comparison.component.html',
  styleUrls: ['./scenario-comparison.component.scss']
})
export class ScenarioComparisonComponent implements OnInit {

  pageTitle: string = 'scenario comparison';
  isLoading: boolean = false;
  @ViewChild('addScenarioRef')
  addScenarioRef: ElementRef<any>;
  @ViewChild('chartContainer')
  chartContainer: ElementRef<any>;
  chartOptions = {};

  rawDataForComparison: any = {};
  paginationOptions: { itemsCount: number, pageSize: number, currentPage: number, maxDisplayPages: number } = {
    itemsCount: 10,
    pageSize: 5,
    currentPage: 1,
    maxDisplayPages: 5
  }
  scenarioListForComparison = [];
  scenariosToCompare = [];
  comparisonOutputGrid = [];
  brandList = [];
  ChannelList = [];
  productList = [];
  selectedBrandName = '';
  selectedChannel = '';
  selectedProductName = '';
  searchComparisonScenarios = '';
  sortColumnForParentTable = 'DateModified';
  sortOrderForParentTable = true;   //false: will sort in ascending , true: will sort in descending
  counterForCheckedScenariosCount = 0;
  counterForCheckedScenariosCountSave = 0;
  anchorScenarioID = 0;
  startIndex = 0;
  endIndex = 0;
  previousStartIndex = 0;
  previousEndIndex = 0;
  showComparisonOutput = false;
  gridType: string = "";
  copyToExcelString: string;
  customCopyFlag: boolean;

  constructor(private appService: AppService, private scenarioComparisonService: ScenarioComparisonService, private router: Router) {
    document.addEventListener('copy', (e) => {
      if (this.customCopyFlag) {
        this.copyToExcelString = this.getCopiedData();
        this.customCopyFlag = false;
        e.clipboardData.setData('text/plain', this.copyToExcelString);
        e.preventDefault();
      }
    });
  }

  pageChange($event) {
    this.paginationOptions.currentPage = $event.detail.newPage;
    var newValue = $event.detail.newPage;
    var pageSize = this.paginationOptions.pageSize;
    var startIndex = pageSize * (newValue - 1);
    var endIndex = startIndex + pageSize - 1;
    var fetchFilteredData = false;
    if (this.startIndex != undefined)
      fetchFilteredData = true;
    this.previousStartIndex = this.startIndex;
    this.previousEndIndex = this.endIndex;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    if (fetchFilteredData)
      this.getFilteredScenarios('pagination');

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.scenarioComparisonService.getAllScenariosForComparison().subscribe(data => {
      this.openScenarioComparison(data);
      this.isLoading = false;
    });
  }

  //called when scenario comparison tab is clicked
  openScenarioComparison = function (data) {

    this.paginationOptions.itemsCount = data.itemsCount;
    this.paginationOptions.pageSize = 5;
    this.paginationOptions.currentPage = 1;
    this.brandList = data.allBrands;
    this.ChannelList = data.allChannels;
    this.selectedBrandName = this.brandList[0];
    this.selectedChannel = this.ChannelList[0];

  }

  addScenarioForComparison = function () {
    this.counterForCheckedScenariosCount = this.counterForCheckedScenariosCountSave;
    this.getFilteredScenarios('addScenario');
    this.searchComparisonScenarios = '';
    this.addScenarioRef.nativeElement.showModal();

  }

  getFilteredScenarios = function (filterType) {

    if (filterType == "search" && this.searchComparisonScenarios.length > 0 && this.searchComparisonScenarios.length <= 3)
      return;

    var params = {
      searchText: this.searchComparisonScenarios || '',
      filters: {
        BrandName: this.selectedBrandName || '',
        Channel: this.selectedChannel || ''
      },
      sort: {
        columnName: this.sortColumnForParentTable || null,
        order: this.sortOrderForParentTable || null
      },
      pagination: {
        startIndex: this.startIndex || 0,
        endIndex: this.endIndex || 5,
        previousStartIndex: this.previousStartIndex || 0,
        previousEndIndex: this.previousEndIndex || 5
      },
      filterType: filterType || '',
      displayData: this.scenarioListForComparison || []

    }
    this.isLoading = true;
    this.scenarioComparisonService.getFilteredScenarios(params).subscribe(res => {
      this.scenarioListForComparison = res.displayScenarios;
      this.paginationOptions.itemsCount = res.scenarioCount;

      this.previousStartIndex = this.startIndex = res.startIndex;
      this.previousEndIndex = this.endIndex = res.endIndex;
      this.isLoading = false;
    });

  }

  counterForCheckedScenarios = function (scenario) {
    if (scenario['checkedForComparison']) {
      this.counterForCheckedScenariosCount += 1;
    } else {
      this.counterForCheckedScenariosCount -= 1;
    }
  }

  resetSelectedScenarioCount = function () {
    var filteredScenarios = this.scenarioListForComparison.filter((obj) => { return (obj.BrandName == this.selectedBrandName) && (obj.Channel == this.selectedChannel) });
    this.counterForCheckedScenariosCount = 0;
    for (let i = 0; i < filteredScenarios.length; ++i) {
      if (filteredScenarios[i].checkedForComparison) {
        this.counterForCheckedScenariosCount += 1;
      }
    }
  }

  confirmScenarioComparison = function () {

    this.counterForCheckedScenariosCountSave = this.counterForCheckedScenariosCount;
    this.showComparisonOutput = false;

    const params = {
      displayData: this.scenarioListForComparison,
      pagination: {
        previousStartIndex: this.previousStartIndex || 0,
        previousEndIndex: this.previousEndIndex || 5
      }
    }
    this.isLoading = true;
    this.scenarioComparisonService.confirmScenariosForComparison(params).subscribe(data => {
      this.scenariosToCompare = data;
      this.addScenarioRef.nativeElement.close();
      this.isLoading = false;
    });

  }

  removeScenarioFromComparison = function (scenarioId) {
    let tempScenariosToCompare = JSON.parse(JSON.stringify(this.scenariosToCompare));
    this.scenariosToCompare = [];
    for (let i = 0; i < tempScenariosToCompare.length; ++i) {
      if (tempScenariosToCompare[i].ScenarioID != scenarioId) {
        this.scenariosToCompare.push(tempScenariosToCompare[i]);
      }
    }
  }

  anchorScenarioChecked = function (rowIndex) {
    if (this.scenariosToCompare[rowIndex].anchor == true) {
      for (let idx = 0; idx < this.scenariosToCompare.length; ++idx) {
        if (idx != rowIndex) {
          this.scenariosToCompare[idx].anchor = false;
        }
      }
    }
  }

  compareScenarios = function () {

    let parameters = { scenariosToCompare: this.scenariosToCompare };
    this.isLoading = true;
    this.scenarioComparisonService.getScenariosFromComparisonTable(parameters).subscribe(data => {

      this.rawDataForComparison = data.rawDataForComparison;
      this.comparisonOutputGrid = data.comparisonOutputGrid;
      this.productList = data.productList;
      this.anchorScenarioID = data.anchorScenarioID;
      this.selectedProductName = data.productList[0];
      this.fillScenarioComparisonGraph();
      this.showComparisonOutput = true;
      this.isLoading = false;
    });
  }

  createOutputGridForComparison = function () {
    let parameters = { selectedProductName: this.selectedProductName, anchorScenarioID: this.anchorScenarioID, rawDataForComparison: this.rawDataForComparison, scenariosToCompare: this.scenariosToCompare }
    this.scenarioComparisonService.createOutputGridForComparison(parameters).subscribe(data => {
      this.comparisonOutputGrid = data;
      this.fillScenarioComparisonGraph();
    });
  }

  fillScenarioComparisonGraph() {

    let tempData = this.rawDataForComparison.filter((obj) => { return obj.product_name == this.selectedProductName });
    var product = tempData[0].product_family;
    //fill series Data
    //get min and max contract quarters
    var minIndex = 999;
    var maxIndex = 0;
    for (var s = 0; s < tempData.length; s++) {
      var quarterDates = JSON.parse(tempData[s].QuarterInfoDates);
      for (var q = 0; q < quarterDates.length; q++) {
        if (quarterDates[q].Contract == 1) {
          if (q < minIndex) {
            minIndex = q;
          }

          let endOfContractFound = false;
          for (var j = q; j < quarterDates.length; j++) {
            if (quarterDates[j].Contract == 0) {
              endOfContractFound = true;
              if (j > maxIndex) {
                maxIndex = j - 1;
              }
              break;
            }
          }
          if (endOfContractFound == false) {
            if (quarterDates.length > maxIndex) {
              maxIndex = quarterDates.length - 1;
            }
          }

          break;
        }
      }
    }

    var seriesData = [];
    let anchorScenarioData = tempData.filter((obj) => { return obj.scenario_id == this.anchorScenarioID })[0];
    var anchoreSeriesData = {
      name: anchorScenarioData.scenario_name + " - No Deal",
      data: []
    };

    var contractExpData = JSON.parse(anchorScenarioData.contract_expectations_data);
    var quarterDates = JSON.parse(anchorScenarioData.QuarterInfoDates);
    var m = 0;
    for (var q = minIndex; q <= maxIndex; q++) {
      if (quarterDates[q].Contract == 1) {
        if (tempData[0].product_name == 'Aggregated') {
          anchoreSeriesData.data.push(parseFloat((contractExpData.Data.noDeal.ShareData[m] * 100).toFixed(2)));
          m = m + 1;
        } else {
          anchoreSeriesData.data.push(parseFloat((contractExpData.noDeal.ShareData[q]).toFixed(2)));
        }
      } else {
        anchoreSeriesData.data.push(0);
      }
    }
    seriesData.push(anchoreSeriesData);

    for (var s = 0; s < tempData.length; s++) {

      let scenarioObject = this.scenariosToCompare.filter((obj) => { return obj.ScenarioID == tempData[s].scenario_id })[0];
      if (scenarioObject.deal == true) {
        var dummySeriesData = {
          name: tempData[s].scenario_name + " - Deal",
          data: []
        };
        var contractExpData = JSON.parse(tempData[s].contract_expectations_data);
        var quarterDates = JSON.parse(tempData[s].QuarterInfoDates);
        var m = 0;
        for (var q = minIndex; q <= maxIndex; q++) {
          if (quarterDates[q].Contract == 1) {
            if (tempData[0].product_name == 'Aggregated') {
              dummySeriesData.data.push(parseFloat((contractExpData.Data.deal.ShareData[m] * 100).toFixed(2)));
              m = m + 1;
            } else {
              dummySeriesData.data.push(parseFloat((contractExpData.deal.ShareData[q]).toFixed(2)));
            }
          } else {
            dummySeriesData.data.push(0);
          }
        }
        seriesData.push(dummySeriesData);
      }
    }



    var xAxisCategories = [];
    var quarterDates = JSON.parse(tempData[0].QuarterInfoDates);
    for (var x = minIndex; x <= maxIndex; x++) {
      xAxisCategories.push(quarterDates[x].qtr);

    }

    this.chartOptions = {

      title: {
        text: product
      },
      credits: {
        enabled: false
      },

      yAxis: {
        title: {
          text: '% Market Share'
        }
      },
      xAxis: {
        categories: xAxisCategories,
        labels: {
          rotation: -45,
        },
        plotLines: [
        ],
      },
      legend: {
        enabled: true,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
        }
      },

      series: seriesData,

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    };
    setTimeout(() => {
      Highcharts.chart(this.chartContainer.nativeElement, this.chartOptions);
    }, 1)
  }

  disableCompareScenariosButton = function () {

    if (this.scenariosToCompare.length == 0) {
      return true;
    }
    let scenariosCheckedForComparison = 0;
    let anchorScenario = 0;
    for (let i = 0; i < this.scenariosToCompare.length; ++i) {
      if (this.scenariosToCompare[i].deal) {
        scenariosCheckedForComparison += 1;
      }
      if (this.scenariosToCompare[i].anchor) {
        anchorScenario += 1;
      }
    }
    if (scenariosCheckedForComparison == 0 || anchorScenario == 0) {
      return true;
    }
    return false;

  }

  openScenario(scenarioId) {
    this.appService.clearDataOnClientSession();
    this.router.navigate(['/ubc/scenario', btoa(scenarioId)]);
  }

  copyToExcel(gridType, shouldFetchStringText) {
    this.gridType = gridType;
    if (window.clipboardData) {
      var string = this.getCopiedData();
      window.clipboardData.setData('Text', string);
    } else {
      this.customCopyFlag = shouldFetchStringText;
      document.execCommand('copy');
    }
  }

  getCopiedData(): string {
    switch (this.gridType) {
      case 'scenarioComparison':
        return this.getScenarioComparisonGrid();
        break;
      default:
    }
  }

  getScenarioComparisonGrid = function () {
    var string = "";
    string += "Scenario Name\tTotal Scenario Net Sales(in $)\tDelta Scenario Net Sales (Compared to Anchor)\tROC (Compared to respective No Deal)\tExit Share %\tDiscretionary Rebate %\tTotal Rebate %\n";
    for (var idx = 0; idx < this.comparisonOutputGrid.length; idx++) {
      if (idx === 0) {
        string += this.comparisonOutputGrid[idx].ScenarioName + "- No Deal\t";
      }
      else {
        string += this.comparisonOutputGrid[idx].ScenarioName + "- Deal\t";
      }
      string += this.comparisonOutputGrid[idx].NetSales + "\t" + this.comparisonOutputGrid[idx].DeltaNetSales + "\t" + this.comparisonOutputGrid[idx].ROC + "\t " + this.comparisonOutputGrid[idx].ExitShare + "%\t" + this.comparisonOutputGrid[idx].DiscretionaryRebates + "%\t" + this.comparisonOutputGrid[idx].TotalRebate + "%\n";
    }
    return string;
  }

}
