<nav class="zs-inverse zs-position-relative zs-layer-5">
    <div>
        <nav id="main-navigation" class="zs-toolbar zs-toolbar-vertical zs-toolbar-labels-below">
            <a *ngIf="appService.loggedInUser.team == 'IPnC'" routerLink="gtn/projects" routerLinkActive="zs-active"
                class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-reports"></span><span class="zs-size-s zs-font-weight-bold">
                    Projects
                    &<br>Scenarios
                </span>
            </a>
            <a *ngIf="appService.loggedInUser.team != 'IPnC'" routerLink="projects" routerLinkActive="zs-active"
                class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-reports"></span><span class="zs-size-s zs-font-weight-bold">
                    Projects
                    &<br>Scenarios
                </span>
            </a>
            <a *ngIf="(appService.loggedInUser.team === 'UBC' && appService.resources.Enable_UBC_Analogs.Visible === 'Y') || (appService.loggedInUser.team === 'PBC' && appService.resources.Enable_PBC_Analogs.Visible === 'Y')"
                [routerLink]="['/analogs']" [queryParams]="{isTabClick: true}" routerLinkActive="zs-active"
                class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-diagram-view"></span><span
                    class="zs-size-s zs-font-weight-bold">Analogs</span>
            </a>
            <a *ngIf="appService.loggedInUser.team != 'IPnC'" routerLink="/globalInput" routerLinkActive="zs-active"
                class="zs-link-action">
                <span class="zs-icon zs-size-xl zs-icon-globe"></span><span class="zs-size-s zs-font-weight-bold">
                    Global
                    <br>Inputs
                </span>
            </a>
            <a *ngIf="appService.loggedInUser.team != 'IPnC' && (appService.loggedInUser.role).toUpperCase() == 'ADMIN'"
                routerLink="/dataLoad" routerLinkActive="zs-active" class="zs-link-action">
                <span class="zs-icon zs-size-xl zs-icon-data-publish-done"></span><span
                    class="zs-size-s zs-font-weight-bold">
                    Data
                    Load
                </span>
            </a>
            <a *ngIf="appService.loggedInUser.team == 'PBC'" routerLink="/dashboard" routerLinkActive="zs-active"
                class="zs-link-action">
                <span class="zs-icon zs-size-xl zs-icon-report-dashboard"></span><span
                    class="zs-size-s zs-font-weight-bold">Dashboard</span>
            </a>
            <a *ngIf="appService.loggedInUser.team == 'UBC'" routerLink="/reports" routerLinkActive="zs-active"
                class="zs-link-action">
                <span class="zs-icon zs-size-xl zs-icon-report-dashboard"></span><span
                    class="zs-size-s zs-font-weight-bold">Reports</span>
            </a>
            <a *ngIf="appService.loggedInUser.team == 'UBC'" routerLink="/scenarioComparison"
                routerLinkActive="zs-active" class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-file-copy"></span><span
                    class="zs-size-s zs-font-weight-bold">Scenario<br>Comparison</span>
            </a>
            <a *ngIf="appService.loggedInUser.team == 'IPnC'" routerLink="/gtn/scenarioComparison"
                routerLinkActive="zs-active" class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-file-copy"></span><span
                    class="zs-size-s zs-font-weight-bold">Scenario<br>Comparison</span>
            </a>
            <!-- <a *ngIf="(appService.loggedInUser.role).toUpperCase() == 'SU' && appService.loggedInUser.team != 'IPnC'" routerLink="/adminTab"
             routerLinkActive="zs-active" class="zs-link-action "> -->
            <a *ngIf="(appService.loggedInUser.role).toUpperCase() == 'SU'" routerLink="/adminTab"
                routerLinkActive="zs-active" class="zs-link-action ">
                <span class="zs-icon zs-size-xl zs-icon-file-copy"></span><span
                    class="zs-size-s zs-font-weight-bold">Admin<br>Tab</span>
            </a>
        </nav>
    </div>
</nav>