<div class='toggle-app'>

	<zs-dialog modal class="zs-dialog modal" #toggleApplicationModalRef role="dialog">
		<header>
			<h3 class="zs-text-align-center">Select Application</h3>
			<span *ngIf="appService.loggedInUser.team.length>0" class="close-icon" close="" (click)="closeModal()"
				onclick="this.parentNode.parentNode.close()">
				<a href="javascript:void(0)" class="zs-interactive-primary"><span
						class="zs-icon zs-size-xl zs-icon-close-circle-fill"></span></a>
			</span>
		</header>
		<section class="application-selection">
			<div class="application-selection__list zs-card-list-container">
				<zs-card *ngFor="let application of applicationsList" (click)="selectApplication(teamApplicationList[application])"
					[ngClass]="((selectedApp) == teamApplicationList[application]) ? 'selected-card': 'deselected-card'" class="zs-card"
					tabindex="0">
					<span *ngIf="teamApplicationList[application] && application == 'IPnC'">IP&C</span>
					<span *ngIf="teamApplicationList[application] && application != 'IPnC'">{{teamApplicationList[application]}}</span>
				</zs-card>
			</div>
		</section>
		<footer class="footer zs-text-align-right">
			<a *ngIf="appService.loggedInUser.team.length>0" class="footer__cancel-btn zs-button" href="javascript:;"
				(click)="closeModal()" onclick="this.parentNode.parentNode.close()">Cancel</a>
			<button
				[ngClass]="{'zs-disabled': (selectedApp) == undefined || (selectedApp) == null || teamSelected == selectedApp}"
				class="zs-button zs-button-action zs-border-rounded-0" (click)="confirmApplication()">Confirm</button>
		</footer>
	</zs-dialog>

</div>