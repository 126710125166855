import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  	//Variale for Sort
	sortOrder = false;
  sortColumn = '';

  constructor() { }



//Sort Functions
  sortData = function (column) {
      this.sortOrder = (this.sortColumn === column) ? !this.sortOrder : false;
      this.setSortColumn(column);
      return this.sortOrder;
  }

  getSortColumn = function() {
      return this.sortColumn;
  }
  setSortColumn = function(column) {
    this.sortColumn = column;
  }

}
