import { AuthGuardService as TeamGuard} from './../../../core/auth-guard.service';
import { ProjectsComponent } from './projects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';



const routes: Routes = [
  {
    path: 'projects',
    component: ProjectsComponent,
    //Sk- use team auth guard like this on ubc,pbc specific routes
    canActivate: [TeamGuard],
    data: {
      expectedTeam: ['UBC', 'PBC']
    }
  },
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
