import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ServerApiService {
  constructor() {}

  // port should match for backend code running locally ie: 4200
  localHostUrl = 'http://app.zs.local:4200';

  getApi = {
    
   serverHostUrl: window.location.origin.includes('local')? this.localHostUrl + "/api/" : window.location.origin.toString()  + "/api/", 

    home: {
      getLoggedInUser: "Home/GetLoggedInUser/",
      getRoleResourceMapping: "Home/GetRoleResourceMapping/",
      getDataRefreshDate: "Home/GetDataRefreshDate/",
      getResourceFieldMapping: "Home/GetResourceFieldMapping/",
      getIDMLaunchbar: "Home/getIdmLaunchbar/",
      setSelectedApplication: "Home/setSelectedApplication/",
      userSpecificApprovalWorkflowActions:
        "approvalWorkflow/userSpecificApprovalWorkflowActions",
      getUserIdNamesMapping: "Home/getUserIdNamesMapping/",
    },
    projects: {
      getProjectsData: "Projects/GetProjectsData/",
      updateProject: "Projects/UpdateProject/",
      updateProjectInJSON: "Projects/UpdateProjectInJSON/",
      save: "Projects/save/",
      deleteProject: "Projects/DeleteProject/",
      scenarioUpdateFromProjectScreen:
        "Projects/ScenarioUpdateFromProjectScreen/",
      scenarioUpdateInJSON: "Projects/ScenarioUpdateInJSON/",
      updateScenarioStatusForSingleton:
        "Projects/UpdateScenarioStatusForSingleton/",
      updateScenarionameNotesForSingleton:
        "Projects/UpdateScenarionameNotesForSingleton/",
      parentScenarioUpdate: "Projects/ParentScenarioUpdate/",
      childScenarioUpdate: "Projects/ChildScenarioUpdate/",
      deleteSingletonScenario: "Projects/DeleteSingletonScenario/",
      masterScenarioDelete: "Projects/MasterScenarioDelete/",
      shareScenario: "Projects/ShareScenario/",
      toggleProjectBookmark: "Projects/ToggleProjectBookmark/",
      copyAllProjects: "Projects/CopyAllProjects",
    },
    projectsGTN: {
      getProjectsData: "ProjectsGTN/GetProjectsData/",
      updateProject: "ProjectsGTN/UpdateProject/",
      updateProjectNameNotes: "ProjectsGTN/updateProjectNameNotes/",
      save: "ProjectsGTN/save/",
      deleteProject: "ProjectsGTN/DeleteProject/",
      deleteScenario: "ProjectsGTN/DeleteScenario/",
      scenarioUpdateFromProjectScreen:
        "ProjectsGTN/ScenarioUpdateFromProjectScreen/",
      scenarioUpdateInJSON: "ProjectsGTN/ScenarioUpdateInJSON/",
      updateScenarioStatusForSingleton:
        "ProjectsGTN/UpdateScenarioStatusForSingleton/",
      updateScenarioNameNotes: "ProjectsGTN/updateScenarioNameNotes/",
      parentScenarioUpdate: "ProjectsGTN/ParentScenarioUpdate/",
      childScenarioUpdate: "ProjectsGTN/ChildScenarioUpdate/",
      masterScenarioDelete: "ProjectsGTN/MasterScenarioDelete/",
      shareScenario: "ProjectsGTN/ShareScenario/",
      toggleProjectBookmark: "ProjectsGTN/ToggleProjectBookmark/",
      getAllMktsPrdBenefitTypeLists:
        "ProjectsGTN/getAllMktsPrdBenefitTypeLists",
      copyScenario: "ProjectsGTN/copyScenario",
      getScenarioNotes: "ProjectsGTN/getScenarioNotes/",
      updateScenarioNotes: "ProjectsGTN/updateScenarioNotes/",
      getCDSScenarioData: "ProjectsGTN/getCDSScenarioData",
      updateCDSScenarioStatus: "ProjectsGTN/updateCDSScenarioStatus/",
    },
    analogs: {
      getTableColumnsInOrder: "analogEvents/getTableColumnsInOrder/",
      getAllFilters: "analogEvents/getAllFilters/",
      getPartialAnalogsData: "analogEvents/getPartialAnalogsData/",
      getAnalogsData: "analogEvents/getAnalogsData/",
      getFilteredAnalogsData: "analogEvents/getFilteredAnalogsData/",
      deleteFilter: "analogEvents/deleteFilter/",
      addFilter: "analogEvents/addFilter/",
      updateFilter: "analogEvents/updateFilter/",
      getAnalogMarketShare: "analogEvents/getAnalogMarketShare/",
      getCopiedDataForAnalogsGrid: "analogEvents/getCopiedDataForAnalogsGrid/",
      calculateSummaryOfSelectedAnalogs:
        "analogEvents/calculateSummaryOfSelectedAnalogs/",
      getAnalogRelativeShareImpact:
        "analogEvents/getAnalogRelativeShareImpact/",
      toggleAnalogSelection: "analogEvents/toggleAnalogSelection/",
      validateAnalogWeightage: "analogEvents/validateAnalogWeightage/",
      unCheckAllSelected: "analogEvents/unCheckAllSelected/",
      applyAnalog: "analogEvents/applyAnalog/",
    },
    helpTab: {
      getInitialFAQData: "helpTab/GetInitialFAQData",
      getHelpTabFaqData: "helpTab/GetHelpTabFaqData",
      checkIfUserGuideExists: "helpTab/CheckIfUserGuideExists",
      downloadFile: "helpTab/DownloadFile",
      removeFile: "helpTab/RemoveFile",
      uploadFile: "helpTab/UploadFile",
      getNavigationDirectionData: "helpTab/getNavigationDirectionData",
    },
    globalInputs: {
      getGlobalInputsData: "globalInputs/getGlobalInputsData",
      getRoleResourceMapping: "Home/getRoleResourceMapping/",
      getGIForecast: "globalInputs/getGIForecast",
      getGIDataSummary: "globalInputs/getGIDataSummary",
      getGIUnitsPerRx: "globalInputs/getGIUnitsPerRx",
      getGIMedicaid: "globalInputs/getGIMedicaid",
      getGIProductPricing: "globalInputs/getGIProductPricing",
      getGIExpenses: "globalInputs/getGIExpenses",

      getGICopayCard: "globalInputs/getGICopayCard",
      getGIDeductibles: "globalInputs/getGIDeductibles",
      getGIMedicare: "globalInputs/getGIMedicare",
      getGIMedicareContribution: "globalInputs/getGIMedicareContribution",
      getGICoverageCatastrophicDistribution:
        "globalInputs/getGICoverageCatastrophicDistribution",
      getGINationalConcession: "globalInputs/getGINationalConcession",
      getGIASPData: "globalInputs/getGIASPData",
      getGISpilloverRebate: "globalInputs/getGISpilloverRebate",
      getUOMData: "globalInputs/getUOMData",
      getGIPatientRecord: "globalInputs/getGIPatientRecord",
      updateGIPatientRecord: "globalInputs/updateGIPatientRecord",
      updateGIPatientGrowth: "globalInputs/updateGIPatientGrowth",
    },
    reportsPBC: {
      getMinRangeScoreCardPBC: "reportsPBC/getMinRangeScoreCardPBC",
      getReportsPBCInitData: "reportsPBC/getReportsPBCInitData",
      getCalculatedScoreCardDataPBC: "reportsPBC/getCalculatedScoreCardDataPBC",
    },
    reportsUBC: {
      getReportsLastRefreshdate: "reportsUbc/getReportsLastRefreshdate",
      getMinRangeScoreCardUBC: "reportsUbc/getMinRangeScoreCardUBC",
      getScorecardInitData: "reportsUbc/getScorecardInitData",
      getCalculatedScoreCardDataUbc: "reportsUbc/getCalculatedScoreCardDataUbc",
      getBrandChannelInitData: "reportsUBC/getBrandChannelInitData",
      getScenarioSummaryInitData: "reportsUBC/getScenarioSummaryInitData",
      getFilteredScenarioSummaryData:
        "reportsUBC/getFilteredScenarioSummaryData",
      getScenarioSummaryDetailsGrid: "reportsUBC/getScenarioSummaryDetailsGrid",
      getProjectDetailsFromScenario: "reportsUBC/getProjectDetailsFromScenario"
    },
    scenarioComparison: {
      getAllScenariosForComparison:
        "scenarioComparison/getAllScenariosForComparison",
      getFilteredScenarios: "scenarioComparison/getFilteredScenarios",
      confirmScenariosForComparison:
        "scenarioComparison/confirmScenariosForComparison",
      getScenariosFromComparisonTable:
        "scenarioComparison/getScenariosFromComparisonTable",
      createOutputGridForComparison:
        "scenarioComparison/createOutputGridForComparison",
    },
    exportToExcel: {
      fetchDataToExport: "exportToExcel/fetchDataToExport",
      exportData: "exportToExcel/exportData",
      exportMasterScenarioData: "exportToExcel/exportMasterScenarioData",
      fetchDataToExportPBC: "exportToExcel/fetchDataToExportPBC",
    },
    createScenario: {
      getCreateScenarioInitData: "createScenario/getCreateScenarioInitData",
      getScenarioBrandGrid: "createScenario/getScenarioBrandGrid",
      getPlansIDList: "createScenario/getPlansIDList",
      getAffiliationIdList: "createScenario/getAffiliationIdList",
      getHistoricRxByMonthsData: "createScenario/getHistoricRxByMonthsData",
      getCalculatedUnitsPerRx: "createScenario/getCalculatedUnitsPerRx",
      getHistoricalGrossSales: "createScenario/getHistoricalGrossSales",
      getWacRxData: "createScenario/getWacRxData",
      getForecastRxByMonthsForBaselinePreview:
        "createScenario/getForecastRxByMonthsForBaselinePreview",
      getGIForecast: "createScenario/getGIForecast",
      getPayerdata: "createScenario/getPayerdata",
      launchPrdToggleFn: "createScenario/launchPrdToggleFn",
      getFormularyDataForPerCapita:
        "createScenario/getFormularyDataForPerCapita",
    },
    affiliations: {
      getInitialAffiliationData: "affiliations/getInitialAffiliationData",
      getPayerPlanAffilliationsGrid:
        "affiliations/getPayerPlanAffilliationsGrid",
      getAffiliationsDataUBC: "affiliations/getAffiliationsDataUBC",
      fetchAllSelectedAffiliations: "affiliations/fetchAllSelectedAffiliations",
      selectDeselectAffiliation: "affiliations/selectDeselectAffiliation",
      addFilter: "affiliations/addFilter",
      fetchSavedFilters: "affiliations/fetchSavedFilters",
      updateFilter: "affiliations/updateFilter",
      deleteFilter: "affiliations/deleteFilter",
    },
    myScenario: {
      getAllOptions: "ubc/myScenario/getAllOptions",
      getAffiliationIdList: "ubc/myScenario/getAffiliationIdList",
      getPayerPlanAffilliationsGrid:
        "ubc/myScenario/getPayerPlanAffilliationsGrid",

      getBaselineData: "ubc/scenarioCalculation/baseline/getBaselineData",
      calculateBaselineAndExpectations:
        "ubc/scenarioCalculation/baseline/calculateBaselineAndExpectations",

      getEditBaselineData:
        "ubc/scenarioCalculation/editBaseline/getEditBaselineData",
      changeSelectedProduct:
        "ubc/scenarioCalculation/editBaseline/changeSelectedProduct",
      calculateBaselineAndExpectationsForEditBaseline:
        "ubc/scenarioCalculation/editBaseline/calculateBaselineAndExpectationsForEditBaseline",
      resetEditBaselineUnitRxData:
        "ubc/scenarioCalculation/editBaseline/resetEditBaselineUnitRxData",
      reProjectForecast:
        "ubc/scenarioCalculation/editBaseline/reProjectForecast",
      renderForecastChartData:
        "ubc/scenarioCalculation/editBaseline/renderForecastChartData",
      getForecastRxByMonthsData:
        "ubc/scenarioCalculation/editBaseline/getForecastRxByMonthsData",
      applyLivesData: "ubc/scenarioCalculation/editBaseline/applyLivesData",
      saveEditBaselineChanges:
        "ubc/scenarioCalculation/editBaseline/saveEditBaselineChanges",
      updateWACData: "ubc/scenarioCalculation/editBaseline/updateWACData",
      updateUnitsRx: "ubc/scenarioCalculation/editBaseline/updateUnitsRx",
      getInitialForecastDataProjectionValues:
        "ubc/scenarioCalculation/editBaseline/getInitialForecastDataProjectionValues",
      updateProjectionData:
        "ubc/scenarioCalculation/editBaseline/updateProjectionData",
      updateHistoricalData:
        "ubc/scenarioCalculation/editBaseline/updateHistoricalData",
      applyProjectionData:
        "ubc/scenarioCalculation/editBaseline/applyProjectionData",
      getMedicareNegotiatedDiscounts: "ubc/scenarioCalculation/financials/getMedicareNegotiatedDiscounts",
      getFinancials: "ubc/scenarioCalculation/financials/getFinancials",
      calculateFinancials:
        "ubc/scenarioCalculation/financials/calculateFinancials",
      calculateAggregatedFinancials:
        "ubc/scenarioCalculation/financials/calculateAggregatedFinancials",
      calculateRebatePercent:
        "ubc/scenarioCalculation/financials/calculateRebatePercent",
      calculateBreakEvenRebateData:
        "ubc/scenarioCalculation/financials/calculateBreakEvenRebateData",
      calculateReturnOnContract:
        "ubc/scenarioCalculation/financials/calculateReturnOnContract",

      calculateCustomerPOV:
        "ubc/scenarioCalculation/customerPOV/calculateCustomerPOV",
      calculateQuarterlyYearlyTotalPovDetailsAndSummaryData:
        "ubc/scenarioCalculation/customerPOV/calculateQuarterlyYearlyTotalPovDetailsAndSummaryData",

      calculateSummaryTabData:
        "ubc/scenarioCalculation/summary/calculateSummaryTabData",
      calculateSharesGraphDataOfSummaryTab:
        "ubc/scenarioCalculation/summary/calculateSharesGraphDataOfSummaryTab",

      calculateSensitivityData:
        "ubc/scenarioCalculation/sensitivity/calculateSensitivityData",
      calculateSensitivityInputsType2:
        "ubc/scenarioCalculation/sensitivity/calculateSensitivityInputsType2",

      calculateCompetitorPOV:
        "ubc/scenarioCalculation/competitorPOV/calculateCompetitorPOV",
      calculateCompIncrementalRebate:
        "ubc/scenarioCalculation/competitorPOV/calculateCompIncrementalRebate",

      saveScenario: "ubc/myScenario/saveScenario",

      duplicateScenario: "ubc/myScenario/duplicateScenario",
      saveFlags: "ubc/myScenario/saveFlags",

      getSensitivityType2Configuration:
        "ubc/myScenario/getSensitivityType2Configuration",
      getScenarioDataFromAffiliations:
        "ubc/myScenario/getScenarioDataFromAffiliations",
      getMoreInfoData: "ubc/myScenario/getMoreInfoData",
      attachFile: "ubc/myScenario/attachFile",
      uploadFile: "ubc/myScenario/uploadFile",
      removeFile: "ubc/myScenario/removeFile",
      downloadFile: "ubc/myScenario/downloadFile",
      saveMoreInfo: "ubc/myScenario/saveMoreInfo",
      updateFileMappingForDulplicatedScenario:
        "ubc/myScenario/updateFileMappingForDulplicatedScenario",

      getScenarioById: "ubc/myScenario/getScenarioById",
      getStaticDataForExistingScenario:
        "ubc/myScenario/getStaticDataForExistingScenario",
      getRxDataTableVolumeTypeMapping:
        "ubc/myScenario/getRxDataTableVolumeTypeMapping",
      insertIntoScenarioComparsion:
        "ubc/myScenario/insertIntoScenarioComparsion",

      getFormularyData: "ubc/scenarioCalculation/baseline/getFormularyData",
      getShareshiftImpactData:
        "ubc/scenarioCalculation/baseline/getShareshiftImpactData",

      deleteScenario: "ubc/myScenario/deleteScenario",
      getCalculatedUnitsRxOnTimeSpecChange:
        "ubc/scenarioCalculation/baseline/getCalculatedUnitsRxOnTimeSpecChange",
      getCopayCardPrograms:
        "ubc/scenarioCalculation/baseline/getCopayCardPrograms",
      getCopayCardRedemptionRate:
        "ubc/scenarioCalculation/baseline/getCopayCardRedemptionRate",
      getContractChannelFinancialConfigMappingData:
        "ubc/myScenario/getContractChannelFinancialConfigMappingData",
      getCustomerPOVInputConfigMappingData:
        "ubc/myScenario/getCustomerPOVInputConfigMappingData",
    },
    masterScenario: {
      getAllMasterData: "ubc/masterScenario/getAllMasterData",
      getProjectListForParentScDuplicate:
        "ubc/masterScenario/getProjectListForParentScDuplicate",
      insertDuplicateScenario: "ubc/masterScenario/insertDuplicateScenario",
      getTotalPOVData: "ubc/masterScenario/getTotalPOVData",
      setSummaryMetricsData: "ubc/masterScenario/setSummaryMetricsData",
      getExpensesData: "ubc/masterScenario/getExpensesData",
      getContractChannelFinancialConfigMappingData:
        "ubc/myScenario/getContractChannelFinancialConfigMappingData",
    },
    createScenarioPBC: {
      getOverviewAndAttributesData:
        "pbc/createScenario/getOverviewAndAttributesData",
      fetchAccountFacilityFilters:
        "pbc/createScenario/fetchAccountFacilityFilters",
      getAffiliations: "pbc/createScenario/getAffiliations",
      applyAffiliationValue: "pbc/createScenario/applyAffiliationValue",
      affiliationsData: "pbc/createScenario/affiliationsData",
      updateScenarioTemplates: "pbc/myScenario/updateScenarioTemplates",
      applyAffiliationData: "pbc/createScenario/applyAffiliationData",
      calculateSegmentSummary: "pbc/createScenario/calculateSegmentSummary",
      getCustomScenarioTemplates:
        "pbc/createScenario/getCustomScenarioTemplates",
      getSelectedCustomScenarioTemplatesData:
        "pbc/createScenario/getSelectedCustomScenarioTemplatesData",
      getAllUserCustomScenarioTemplates:
        "pbc/createScenario/getAllUserCustomScenarioTemplates",
      saveCustomScenarioTemplate:
        "pbc/createScenario/saveCustomScenarioTemplate",
      checkIfAffiliationDataIsUpdated:
        "pbc/createScenario/checkIfAffiliationDataIsUpdated",
      fetchSavedFilters: "pbc/createScenario/fetchSavedFilters",
      addFilter: "pbc/createScenario/addFilter",
      updateFilter: "pbc/createScenario/updateFilter",
      deleteFilter: "pbc/createScenario/deleteFilter",
      copyAllAffiliationsData: "pbc/createScenario/copyAllAffiliationsData",
      copyFromExcelAffiliationData:
        "pbc/createScenario/copyFromExcelAffiliationData",
      getHistoricalData: "pbc/createScenario/getHistoricalData",
      getWACData: "pbc/createScenario/getWACData",
      displayBaselinePreviewData:
        "pbc/createScenario/displayBaselinePreviewData",
      checkIfGetHistoricalDataTriggerComplete:
        "pbc/createScenario/checkIfGetHistoricalDataTriggerComplete",
      triggerGetHistoricalDataCall:
        "pbc/createScenario/triggerGetHistoricalDataCall",
      getInitialScenarioData: "pbc/myScenario/getInitialScenarioData",
      getHistoricalBaselineData: "pbc/createScenario/getHistoricalBaselineData",
      getForecastRxByMonthsForBaselinePreview:
        "pbc/createScenario/getForecastRxByMonthsForBaselinePreview",
    },
    myScenarioPBC: {
      getInitialScenarioData: "pbc/myScenario/getInitialScenarioData",
      getAdoptionCurveData: "pbc/myScenario/getAdoptionCurveData",
      getShareMatrixImpactModel: "pbc/myScenario/getShareMatrixImpactModel",
      checkIfGetHistoricalDataTriggerComplete:
        "pbc/myScenario/checkIfGetHistoricalDataTriggerComplete",
      triggerGetHistoricalDataCall:
        "pbc/myScenario/triggerGetHistoricalDataCall",
      getPBCHistoricalData: "pbc/myScenario/getPBCHistoricalData",
      calculateInitialBaselineAndForecastData:
        "pbc/myScenario/calculateInitialBaselineAndForecastData",
      updateAffiliationMonthlyQuarterlyData:
        "pbc/myScenario/updateAffiliationMonthlyQuarterlyData",
      getPBCCustomerPOVElements: "pbc/myScenario/getPBCCustomerPOVElements",

      getDealNoDealPBCTRx: "pbc/myScenario/getDealNoDealPBCTRx",
      calculateTotalDiscountOrRebate:
        "pbc/myScenario/calculateTotalDiscountOrRebate",
      calculateTotalAdminFee: "pbc/myScenario/calculateTotalAdminFee",
      calculateFinancialsForPBC: "pbc/myScenario/calculateFinancialsForPBC",
      calculateReturnOnContract: "pbc/myScenario/calculateReturnOnContract",
      calculateBreakEvenRebate: "pbc/myScenario/calculateBreakEvenRebate",
      calculateTierSummaryDataForExpectations:
        "pbc/myScenario/calculateTierSummaryDataForExpectations",
      calculateCustomerPOV: "pbc/myScenario/calculateCustomerPOV",
      calculateSummaryTabDetails: "pbc/myScenario/calculateSummaryTabDetails",
      createSensitivityInputs: "pbc/myScenario/createSensitivityInputs",
      createSensitivityOutputs: "pbc/myScenario/createSensitivityOutputs",

      getEditBaselineData:
        "pbc/scenarioCalculation/editBaseline/getEditBaselineData",
      calculateOriginalDataForEditBaseline:
        "pbc/scenarioCalculation/editBaseline/calculateOriginalDataForEditBaseline",
      changeProductSelection:
        "pbc/scenarioCalculation/editBaseline/changeProductSelection",
      recalculateOverrides:
        "pbc/scenarioCalculation/editBaseline/recalculateOverrides",
      applyUOM: "pbc/scenarioCalculation/editBaseline/applyUOM",
      updateForecast: "pbc/scenarioCalculation/editBaseline/updateForecast",
      saveEditChanges: "pbc/scenarioCalculation/editBaseline/saveEditChanges",
      updateWacAndCalculateGrossSales:
        "pbc/scenarioCalculation/editBaseline/updateWacAndCalculateGrossSales",
      resetBaselineData:
        "pbc/scenarioCalculation/editBaseline/resetBaselineData",
      getInitialForecastDataProjectionValues:
        "pbc/scenarioCalculation/editBaseline/getInitialForecastDataProjectionValues",
      updateProjectionData:
        "pbc/scenarioCalculation/editBaseline/updateProjectionData",
      updateHistoricalData:
        "pbc/scenarioCalculation/editBaseline/updateHistoricalData",
      applyProjectionData:
        "pbc/scenarioCalculation/editBaseline/applyProjectionData",
      getbackwardCompatibilityProjectionValues:
        "pbc/scenarioCalculation/editBaseline/getbackwardCompatibilityProjectionValues",

      getMoreInfo: "pbc/myScenario/getMoreInfo",
      attachFile: "pbc/myScenario/attachFile",
      saveAllFiles: "pbc/myScenario/saveAllFiles",
      downloadFile: "pbc/myScenario/downloadFile",
      removeFile: "pbc/myScenario/removeFile",

      getScenarioTemplates: "pbc/myScenario/getScenarioTemplates",
      getSelectedScenarioTemplatesData:
        "pbc/myScenario/getSelectedScenarioTemplatesData",
      updateScenarioTemplates: "pbc/myScenario/updateScenarioTemplates",
      getAllUserScenarioTemplates: "pbc/myScenario/getAllUserScenarioTemplates",
      saveScenarioTemplate: "pbc/myScenario/saveScenarioTemplate",

      duplicateScenario: "pbc/myScenario/duplicateScenario",
      getInitialScenarioDataForCustom:
        "pbc/myScenario/getInitialScenarioDataForCustom",
      setDataForCustom: "pbc/myScenario/setDataForCustom",
      setNationalDataForCustom: "pbc/myScenario/setNationalDataForCustom",
      saveScenario: "pbc/myScenario/saveScenario",
      getScenario: "pbc/myScenario/getScenario",
      getDataAfterRefresh: "pbc/myScenario/getDataAfterRefresh",
      saveScenarioNotes: "pbc/myScenario/saveScenarioNotes",
      getContractChannelFinancialConfigMappingData:
        "pbc/myScenario/getContractChannelFinancialConfigMappingData",
      getOverviewAndAttributesData:
        "pbc/createScenario/getOverviewAndAttributesData",
      getAffiliations: "pbc/createScenario/getAffiliations",
    },
    dynamicCalc: {
      getDynamicCalcDefaults: "dynamicCalc/getDynamicCalcDefaults",
      getDynamicCalcByScenarioId: "dynamicCalc/getDynamicCalcByScenarioId",
      saveDynamicCalc: "dynamicCalc/saveDynamicCalc",
    },
    dataLoad: {
      getSchemaTableList: "dataLoad/getSchemaTableList",
      getFileList: "dataLoad/getFileList",
      downloadFiles: "dataLoad/downloadFiles",
      downloadZipFile: "downloadZipFile",
      uploadFile: "dataLoad/uploadFile",
    },
    approvalWorkflow: {
      getStatusList: "approvalWorkflow/getStatusList",
      updateStatusForSingleton: "Projects/UpdateScenarioStatusForSingleton/",
      submitScenarioForApproval: "approvalWorkflow/submitScenarioForApproval",
      updateScenarioDataOnUserAction:
        "approvalWorkflow/updateScenarioDataOnUserAction",
      getStatusLogs: "approvalWorkflow/getStatusLogs",
      getApproverScenarios: "approvalWorkflow/getApproverScenarios",
      getScenarioApproversList: "approvalWorkflow/getScenarioApproversList",
      getScenarioType: "approvalWorkflow/getScenarioType",
    },
    adminTab: {
      getScenarioRefreshStatus: "adminTab/getScenarioRefreshStatus",
      updateRefreshScenarioFlagDate: "adminTab/updateRefreshScenarioFlagDate",
      updatePayerProviderMasterData: "adminTab/updatePayerProviderMasterData",
      createPBCScorecardData: "reportsPBC/createPBCScorecardData",
    },
    users: {
      getAllUsers: "userAuth/users",
      addUser: "userAuth/users",
      updateUser: "userAuth/users",
      deleteUser : "userAuth/delete/users",
      getDropdownConfig : "userAuth/getDropdownConfig"
    },
    myScenarioGTN: {
      fetchScenarioData: "gtn/myScenario/fetchScenarioData",
      recalculate: "gtn/myScenario/recalculate",
      save: "gtn/myScenario/save",
      getDeltaAgainstScenarioListData:
        "gtn/myScenario/getDeltaAgainstScenarioListData",
      getScenarioById: "gtn/myScenario/getScenarioById",
      getDeltaAgainstSelectedScenarioData:
        "gtn/myScenario/getDeltaAgainstSelectedScenarioData",
      saveTemplate: "gtn/myScenario/saveTemplate",
      getTemplates: "gtn/myScenario/getTemplates",
      updateTemplate: "gtn/myScenario/updateTemplate",
      editPayerGroupings: "gtn/myScenario/editPayerGroupings",
      editProviderGroupings: "gtn/myScenario/editProviderGroupings",
      curveBasedOnUserInputData: "gtn/myScenario/curveBasedOnUserInputData",
      applyCurve: "gtn/myScenario/applyCurve",
      payerCurveBasedOnUserInputData:
        "gtn/myScenario/payerCurveBasedOnUserInputData",
      applyPayerCurve: "gtn/myScenario/applyPayerCurve",
    },
    compareScenarioGTN: {
      fetchAllScenarioData: "gtn/compareScenario/fetchAllScenarioData",
      saveScenarioComparison: "gtn/compareScenario/saveScenarioComparison",
      getScenarioComparisonList:
        "gtn/compareScenario/getScenarioComparisonList",
      getScenarioComparisonDetailsByScenarioIds:
        "gtn/compareScenario/getScenarioComparisonDetailsByScenarioIds",
      getCDSScenarioData: "gtn/compareScenario/getCDSPayerProviderScenarioData",
      setPayerProviderData: "gtn/compareScenario/setPayerProviderData",
      getCDSPayerProviderDataByIds:
        "gtn/compareScenario/getCDSPayerProviderDataByIds",
    },
  };
}
