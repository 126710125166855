import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServerApiService } from 'src/app/shared/services/server-api.service';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class ProjectsService {
  projectsApis;

  constructor(private http: HttpClient, private apisService: ServerApiService) {
    this.projectsApis = this.apisService.getApi.projects;

  }

  getProjectsData(params): any {
    if (!params)
      return this.http.get(this.projectsApis.getProjectsData);

    return this.http.post(this.projectsApis.getProjectsData, params);
  }

  updateProject(projectData) {
    return this.http.post(this.projectsApis.updateProject, projectData)
  }
  updateProjectInJSON(projectNameParameter) { return this.http.post(this.projectsApis.updateProjectInJSON, projectNameParameter) };
  save(projectData) { return this.http.post(this.projectsApis.save, projectData); }
  deleteProject(projectData) { return this.http.post(this.projectsApis.deleteProject, projectData); }
  updateScenarionameNotesForSingleton(params) { return this.http.post(this.projectsApis.updateScenarionameNotesForSingleton, params); }
  scenarioUpdateFromProjectScreen(params) { return this.http.post(this.projectsApis.scenarioUpdateFromProjectScreen, params); }
  scenarioUpdateInJSON(masterNameParameter) { return this.http.post(this.projectsApis.scenarioUpdateInJSON, masterNameParameter); }
  shareScenario(params) { return this.http.post(this.projectsApis.shareScenario, params); }
  updateScenarioStatusForSingleton(params) { return this.http.post(this.projectsApis.updateScenarioStatusForSingleton, { params }); }
  parentScenarioUpdate(params) { return this.http.post(this.projectsApis.parentScenarioUpdate, { params }); }
  childScenarioUpdate(params) { return this.http.post(this.projectsApis.childScenarioUpdate, { params }); }
  deleteSingletonScenario(params) { return this.http.post(this.projectsApis.deleteSingletonScenario, params); }
  masterScenarioDelete(scenarioData) { return this.http.post(this.projectsApis.masterScenarioDelete, scenarioData); }
  toggleProjectBookmark(params) { return this.http.post(this.projectsApis.toggleProjectBookmark, params); }
  copyAllProjects(params) { return this.http.post(this.projectsApis.copyAllProjects, params); }
}