<div class="pending-scenario-list zs-margin-0-0-2-0 zs-text-align-left"
    *ngIf="(pendingScenariosList.length>0 || completedScenariosList.length>0) && statusOptions.length > 0"
    [ngClass]="{'pending-notification': (pendingScenariosList.length > 0)}">
    <div class="message-banner zs-padding-2">
        <a class="notification-text" href="javascript:void(0)" (click)="toggleListSection($event)">
            <span class="carat-icon zs-icon zs-size-xl zs-icon-carat-down">
            </span>
            <span>Scenarios pending for Approval: {{pendingScenariosList.length}}
            </span>
        </a>
    </div>
    <div #listSection class="list-section zs-display-none">
        <zs-tabs-container class="zs-tabs zs-tabs-solid">
            <div class="list-section__tabs zs-padding-0-2">
                <zs-tab class="zs-tab" target-id='pendingScenarios' active>Scenarios Pending for Approval</zs-tab>
                <zs-tab class="zs-tab" target-id='completedScenarios'>Action Taken</zs-tab>
            </div>
            <main>
                <div source-id="pendingScenarios" active>
                    <div class="grid">
                        <table *ngIf="pendingScenariosList.length > 0"
                            class="zs-table zs-table-condense zs-margin-0 zs-width-100">
                            <thead>
                                <tr>
                                    <th>Scenario</th>
                                    <th>Project Name</th>
                                    <th>Brand</th>
                                    <th *ngIf="appService.loggedInUser.team.toLowerCase()=='ubc'">Channel</th>
                                    <th>Scenario Owner</th>
                                    <th class="zs-text-align-right">Sent for Approval</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let scenario of pendingScenariosList; let index=index">
                                    <tr *ngIf="index <= endIndexPending && index >= startIndexPending">
                                        <td>
                                            <a href="javascript:void(0)" (click)="openScenario(scenario)"
                                                class="zs-font-weight-bold">{{scenario.ScenarioName}}
                                            </a>
                                        </td>
                                        <td>{{scenario.ProjectName}}</td>
                                        <td>{{scenario.BrandName}}</td>
                                        <td *ngIf="appService.loggedInUser.team.toLowerCase()=='ubc'">
                                            {{scenario.Channel}}</td>
                                        <td>{{scenario.CreatedBy}}</td>
                                        <td class="zs-text-align-right">{{scenario.DateModified | date:'dd MMM yyyy'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf="pendingScenariosList.length > 0" class="zs-text-align-right zs-margin-1-0-0-0">
                            <zs-pagination (pagechange)="pageChangePending($event)" class="zs-pagination" mode="action"
                                [itemsCount]="paginationOptionsPending.itemsCount"
                                [maxDisplayPages]="paginationOptionsPending.maxDisplayPages"
                                [pageSize]="paginationOptionsPending.pageSize"
                                [currentPage]="paginationOptionsPending?.currentPage">
                            </zs-pagination>
                        </div>
                        <div *ngIf="pendingScenariosList.length == 0"
                            class="zs-layout-flex zs-flex-align-center zs-border zs-bg-success-secondary zs-border-color-success-secondary zs-margin-0">
                            <span class="zs-icon zs-size-l zs-icon-error-circle-fill zs-text-success"></span>
                            <p class="zs-margin-0-0-0-1 zs-text-align-left zs-width-100">
                                <span class="zs-body">No scenarios pending for approval</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div source-id="completedScenarios">
                    <div class="grid">
                        <table *ngIf="completedScenariosList.length > 0"
                            class="zs-table zs-table-condense zs-margin-0 zs-width-100">
                            <thead>
                                <tr>
                                    <th>Scenario</th>
                                    <th>Project Name</th>
                                    <th>Brand</th>
                                    <th *ngIf="appService.loggedInUser.team.toLowerCase()=='ubc'">Channel</th>
                                    <th>Scenario Owner</th>
                                    <th>Status</th>
                                    <th class="zs-text-align-right">Modified Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let scenario of completedScenariosList; let index=index">
                                    <tr *ngIf="index <= endIndexActionTaken && index >= startIndexActionTaken">
                                        <td>
                                            <a href="javascript:void(0)" (click)="openScenario(scenario)"
                                                class="zs-font-weight-bold">{{scenario.ScenarioName}}
                                            </a>
                                        </td>
                                        <td>{{scenario.ProjectName}}</td>
                                        <td>{{scenario.BrandName}}</td>
                                        <td *ngIf="appService.loggedInUser.team.toLowerCase()=='ubc'">
                                            {{scenario.Channel}}</td>
                                        <td>{{scenario.CreatedBy}}</td>
                                        <td>{{getStatusFromID(scenario.StatusID)}}</td>
                                        <td class="zs-text-align-right">{{scenario.DateModified | date:'dd MMM yyyy'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf="completedScenariosList.length > 0" class="zs-text-align-right zs-margin-1-0-0-0">
                            <zs-pagination (pagechange)="pageChangeActionTaken($event)" class="zs-pagination" mode="action"
                                [itemsCount]="paginationOptionsActionTaken.itemsCount"
                                [maxDisplayPages]="paginationOptionsActionTaken.maxDisplayPages"
                                [pageSize]="paginationOptionsActionTaken.pageSize"
                                [currentPage]="paginationOptionsActionTaken?.currentPage">
                            </zs-pagination>
                        </div>
                        <div *ngIf="completedScenariosList.length == 0"
                            class="zs-layout-flex zs-flex-align-center zs-border zs-bg-warning-secondary zs-border-color-warning-secondary zs-margin-0">
                            <span class="zs-icon zs-size-l zs-icon-error-circle-fill zs-text-warning"></span>
                            <p class="zs-margin-0-0-0-1 zs-text-align-left zs-width-100">
                                <span class="zs-body">No action has been taken for any scenario pending for
                                    approval.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </zs-tabs-container>

    </div>
</div>