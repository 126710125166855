import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  projectsRoute = "";
  constructor(public appService:AppService) {

   }

  ngOnInit(): void {
    if(this.appService.loggedInUser.team == 'IPnC'){
      this.projectsRoute = 'gtn/projects';
    }else{
      this.projectsRoute = 'projects';
    }
  }

}
