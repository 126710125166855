import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResourcesJsonInitService {

  constructor() { }

  resources = {
    FileUpload: { ID: 1, Visible: 'N' },
    ENUP_GNUP: { ID: 2, Visible: 'N' },
    Manual_Inputs: { ID: 3, Visible: 'N' },
    Shareshift_Analysis: { ID: 4, Visible: 'N' },
    Selected_Analogs: { ID: 5, Visible: 'N' },
    Percent_To_Nation: { ID: 6, Visible: 'N' },
    Spillover: { ID: 7, Visible: 'N' },
    Copay: { ID: 8, Visible: 'N' },
    Populate: { ID: 9, Visible: 'N' },
    Annual_PPT: { ID: 10, Visible: 'N' },
    Semi_Annual_PPT: { ID: 11, Visible: 'N' },
    Quarter_PPT: { ID: 12, Visible: 'N' },
    Global_Inputs: { ID: 13, Visible: 'N' },
    Customer_POV: { ID: 14, Visible: 'N' },
    Global_Inputs_Copay: { ID: 15, Visible: 'N' },
    Insured_Lives: { ID: 16, Visible: 'N' },
    Fit_Score: { ID: 17, Visible: 'N' },
    Analog_Notes: { ID: 18, Visible: 'N' },
    Scenario_Notes: { ID: 19, Visible: 'N' },
    Control_Slider: { ID: 20, Visible: 'N' },
    Wac_Unit: { ID: 21, Visible: 'N' },
    Source: { ID: 22, Visible: 'N' },
    Analog_Filter: { ID: 23, Visible: 'N' },
    Fingertip_Formulary: { ID: 24, Visible: 'N' },
    Copay_Card_Type: { ID: 25, Visible: 'N' },
    Medicaid_Grid: { ID: 26, Visible: 'N' },
    Share_Scenario: { ID: 27, Visible: 'N' },
    Admin_Tab: { ID: 28, Visible: 'N' },
    Create_Project: { ID: 29, Visible: 'N' },
    Create_Scenario: { ID: 30, Visible: 'N' },
    Action_Dropdown: { ID: 31, Visible: 'N' },
    Save_Project: { ID: 32, Visible: 'N' },
    Save_Scenario: { ID: 33, Visible: 'N' },
    Delete_Scenario: { ID: 34, Visible: 'N' },
    Duplicate_Scenario: { ID: 35, Visible: 'N' },
    Export_Scenario: { ID: 36, Visible: 'N' },
    Recalculate: { ID: 37, Visible: 'N' },
    LivesGrid: { ID: 38, Visible: 'N' },
    Save_Analog_Filter: { ID: 39, Visible: 'N' },
    Update_Affiliation: { ID: 40, Visible: 'N' },
    Metric_Type_Dropdown: { ID: 41, Visible: 'N' },
    Reports_Tab: { ID: 42, Visible: 'N' },
    Analog_Summary_Tab: { ID: 43, Visible: 'N' },
    Save_Flag: { ID: 44, Visible: 'N' },
    Save_Edit: { ID: 45, Visible: 'N' },
    BuyAndBill: { ID: 46, Visible: 'N' },
    ClassCompression: { ID: 47, Visible: 'N' },
    ShowPrivacyPolicyLink: { ID: 48, Visible: 'N' },
    ShowPopupBasedPrivacyPolicyLink: { ID: 49, Visible: 'N' },
    ShowPriceProtection: { ID: 50, Visible: 'N' },
    SensitivityType1: { ID: 51, Visible: 'N' },
    SensitivityType2: { ID: 52, Visible: 'N' },

    Global_Inputs_Channel_TRx_Tab: { ID: 53, Visible: 'N' },
    Global_Inputs_Channel_Units_Tab: { ID: 54, Visible: 'N' },
    Global_Inputs_Channel_UnitsRx_Tab: { ID: 55, Visible: 'N' },
    Global_Inputs_Channel_RebateMedicade_Tab: { ID: 56, Visible: 'N' },
    Global_Inputs_Channel_Deductible_Tab: { ID: 57, Visible: 'N' },
    Global_Inputs_Channel_CoverageGapDiscount_Tab: { ID: 58, Visible: 'N' },
    Global_Inputs_Channel_CoverageGapPatientPercent_Tab: { ID: 59, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicCarePatientPercent_Tab: { ID: 60, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicCarePatientLISPercent_Tab: { ID: 61, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicCarePayerPercent_Tab: { ID: 62, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicCarePayerLISPercent_Tab: { ID: 63, Visible: 'N' },
    Global_Inputs_Channel_CoverageGapTRx_Tab: { ID: 64, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicTRx_Tab: { ID: 65, Visible: 'N' },
    Global_Inputs_Channel_SpilloverRebate_Tab: { ID: 66, Visible: 'N' },
    Global_Inputs_Channel_CopayCardRates_Tab: { ID: 67, Visible: 'N' },
    Global_Inputs_Channel_CatastrophicPhaseDiscount_Tab: { ID: 67, Visible: 'N' },
    Global_Inputs_Channel_InitialCoveragePhaseDiscount_Tab: { ID: 102, Visible: 'N' },


    Global_Inputs_NonChannel_WacPrice_Tab: { ID: 68, Visible: 'N' },
    Global_Inputs_NonChannel_URA_Tab: { ID: 69, Visible: 'N' },
    Global_Inputs_NonChannel_FinanceRates_Tab: { ID: 70, Visible: 'N' },
    Global_Inputs_NonChannel_NationalConcession_Tab: { ID: 71, Visible: 'N' },
    Global_Inputs_NonChannel_ASPData_Tab: { ID: 72, Visible: 'N' },
    Export_Master_Scenario: { ID: 73, Visible: 'N' },
    Non_Segment_Level_Dropdowns: { ID: 74, Visible: 'N' },
    Adoption_Curve_Modeling_Type: { ID: 75, Visible: 'N' },
    PBC_Custom_Data_Source_Link: { ID: 76, Visible: 'N' },
    PBC_Analog_Summary_Tab: { ID: 77, Visible: 'N' },
    PBC_Fit_Score: { ID: 78, Visible: 'N' },
    ShowPriceProtectionIRR: { ID: 79, Visible: 'N' },
    BERCalculationLogic1: { ID: 80, Visible: 'N' },
    BERCalculationLogic2: { ID: 81, Visible: 'N' },
    MasterROCCalculationLogic1: { ID: 82, Visible: 'N' },
    MasterROCCalculationLogic2: { ID: 83, Visible: 'N' },
    ShwoEventType: { ID: 84, Visible: 'N' },
    DuplicateParentScenario: { ID: 85, Visible: 'N' },
    ShowFileUploadFeatureUBC: { ID: 86, Visible: 'N' },
    LaunchProductToggle: { ID: 87, Visible: 'N' },
    Enable_PBC_Analogs: { ID: 88, Visible: 'N' },
    Enable_UBC_Analogs: { ID: 89, Visible: 'N' },
    EnableNationalForecastDropdown: { ID: 90, Visible: 'N' },
    EnableDisableLISZeroForQuarterError: { ID: 91, Visible: 'N' },
    LaunchProductTogglePBC: { ID: 92, Visible: 'N' },
    ShowFileUploadFeaturePBC: { ID: 93, Visible: 'N' },
    EnableCustomErrorPage: { ID: 94, Visible: 'N' },
    ShowVolumeDropDownUBC: { ID: 95, Visible: 'N' },
    ShowHelpTabNavigationsUBC: { ID: 96, Visible: 'N' },
    dynamicCalcUBC: { ID: 97, Visible: 'N' },
    ApprovalWorkflow: { ID: 98, Visible: 'N' },
    ProjectForecastDataBaselinePBC: { ID: 99, Visible: 'N' },
    ClassCompressionPBC: { ID: 100, Visible: 'N' },
    ShowHelpTabNavigationsPBC: { ID: 101, Visible: 'N' },
    ProjectForecastDataBaselineUBC: { ID: 99, Visible: 'N' },
    Global_Inputs_Channel_patientRecord_Tab: { ID: 100, Visible: 'N' },
    UserManagementTab: { ID: 102, Visible: 'N' },
    RefreshDataTab : {ID : 103 , Visible : 'N'}
}

resourceFields =  {
  baseline: { fieldName: 'baseline', text: '' },
  AbsoluteShareImpact: { fieldName: 'AbsoluteShareImpact', text: '' },
  RelativeShareImpact: { fieldName: 'RelativeShareImpact', text: '' },
  Profitability: { fieldName: 'Profitability', text: '' },
  PayerColumnName: { fieldName: 'PayerColumnName', text: '' },
  Client_Name: { fieldName: 'ClientName', text: '' },
  ClientLogo: { fieldName: 'ClientLogo', text: '' },
  CopayBuyDown: { fieldName: 'CopayBuyDown', text: '' },
  MaxDiscount: { fieldName: 'MaxDiscount', text: '' },
  ProgramReach: { fieldName: 'ProgramReach', text: '' },
  TermStartDate: { fieldName: 'TermStartDate', text: '' },
  CurrentScenarioVersion: { fieldName: 'CurrentScenarioVersion', text: '' },
  PrivacyPolicyDisclaimer: { fieldName: 'PrivacyPolicyDisclaimer', text: '' },
  PrivacyPolicyLink: { fieldName: 'PrivacyPolicyLink', text: '' },
  PrivacyPolicyDisclaimerHeader: { fieldName: 'PrivacyPolicyDisclaimerHeader', text: '' },
  AdminFeePassThroughText: { fieldName: 'AdminFeePassThroughText', text: '' },
  RebatePassThroughText: { fieldName: 'RebatePassThroughText', text: '' },
  RxDeductibleText: { fieldName: 'RxDeductibleText', text: '' },
  EditBaselinePopupHeaderText: { fieldName: 'EditBaselinePopupHeaderText', text: '' },
  EditBaselineReprojectText: { fieldName: 'EditBaselineReprojectText', text: '' },
  PayerControlText: { fieldName: 'PayerControlText', text: '' },
  "Co-Pay": { fieldName: 'Co-Pay', text: '' },
  RedemptionRate: { fieldName: 'Redemption Rate', text: '' },
  Impact: { fieldName: 'Impact', text: '' },
  Source: { fieldName: 'Source', text: '' },
  SpillOver: { fieldName: 'Spill Over', text: '' },
  "Impact Model Approach": { fieldName: 'Impact Model Approach', text: '' },
  Formulary: { fieldName: 'Formulary', text: '' },
  Rebate: { fieldName: 'Rebate', text: '' },
  Brand: { fieldName: 'Brand', text: '' },
  TherapyArea: { fieldName: 'Therapy_Area', text: '' },
  ClassCompressionText: { fieldName: 'ClassCompressionText', text: '' },
  AdditionalRebate: { fieldName: 'AdditionalRebate', text: '' },
  MandatedRebateLabel: { fieldName: 'MandatedRebateLabel', text: '' },
  PBCCustomLinkText: { fieldName: 'PBCCustomLinkText', text: '' },
  PBCCustomLink: { fieldName: 'PBCCustomLink', text: '' },
  BaselineVolumeHeader: { fieldName: 'BaselineVolumeHeader', text: '' },
  BaselineShareHeader: { fieldName: 'BaselineShareHeader', text: '' },
  BaselineSalesHeader: { fieldName: 'BaselineSalesHeader', text: '' },
  ModelingTypeShareImpact: { fieldName: 'ModelingTypeShareImpact', text: '' },
  ModelingTypeVolumeImpact: { fieldName: 'ModelingTypeVolumeImpact', text: '' },
  ROCGraphText: { fieldName: 'ROCGraphText', text: '' },
  BERGraphText: { fieldName: 'BERGraphText', text: '' },
  ROCGraphTextCopay: { fieldName: 'ROCGraphTextCopay', text: '' },
  BERGraphTextCopay: { fieldName: 'BERGraphTextCopay', text: '' },
  SelectAnalogButtonText: { fieldName: 'SelectAnalogButtonText', text: '' },
  ReviewAnalogButtonText: { fieldName: 'ReviewAnalogButtonText', text: '' },
  AcceptedFileFormatsUBC: { fieldName: 'AcceptedFileFormatsUBC', text: '' },
  AppHeaderColor: { fieldName: 'AppHeaderColor', text: '' },
  NationalForecastText: { fieldName: 'NationalForecastText', text: '' },
  ClassCompressionColumn: { fieldName: 'ClassCompressionColumn', text: '' },
  ImpactInput: { fieldName: 'ImpactInput', text: '' },
  LISZeroForQuarterErrrorMessage: { fieldName: 'LISZeroForQuarterErrrorMessage', text: '' },
  UOMDefintionMessage: { fieldName: 'UOMDefintionMessage', text: '' },
  AcceptedFileFormatsPBC: { fieldName: 'AcceptedFileFormatsPBC', text: '' },
  MaxLimitOfFilesPBC: { fieldName: 'MaxLimitOfFilesPBC', text: '' },
  APICallStatusCodesForErrorPage: { fieldName: 'APICallStatusCodesForErrorPage', text: '' },
  AcceptedFileFormatForUserGuide: { fieldName: 'AcceptedFileFormatForUserGuide', text: '' },
  AcceptedFileSizeForHelpTab: { fieldName: 'AcceptedFileSizeForHelpTab', text: '' },
  UBCAffiliationArtisLink: { fieldName: 'UBCAffiliationArtisLink', text: '' },
  UBCAffiliationVolumeDropdown: { fieldName: 'UBCAffiliationVolumeDropdown', text: '' },
  ReportsTabDateDescription: { fieldName: 'ReportsTabDateDescription', text: '' },
  EditBaselineVolumeHeader: { fieldName: 'EditBaselineVolumeHeader', text: '' },
  EditBaselineShareHeader: { fieldName: 'EditBaselineShareHeader', text: '' },
  EditBaselineSalesHeader: { fieldName: 'EditBaselineSalesHeader', text: '' },
  UBCTeamTogglePlaceholder: { fieldName: 'UBCTeamTogglePlaceholder', text: '' },
  PBCTeamTogglePlaceholder: { fieldName: 'PBCTeamTogglePlaceholder', text: '' },
  GTNTeamTogglePlaceholder: { fieldName: 'GTNTeamTogglePlaceholder', text: '' },
  UBCFinancialMetricQuestionReferenceKey: { fieldName: 'UBCFinancialMetricQuestionReferenceKey', text: '' },
  UBCGlobalInputsExpensesQuestionReferenceKey: { fieldName: 'UBCGlobalInputsExpensesQuestionReferenceKey', text: '' },
  PBCEditBaselineUOMQuestionReferenceKey: { fieldName: 'PBCEditBaselineUOMQuestionReferenceKey', text: '' }

}

resourcesGTN = {

  Create_Project: { ID: 1, Visible: 'N' },
  Create_Scenario: { ID: 2, Visible: 'N' },
  Save_Project: { ID: 3, Visible: 'N' },
  Save_Scenario: { ID: 4, Visible: 'N' },
  Delete_Scenario: { ID: 5, Visible: 'N' },
  Recalculate: { ID: 6, Visible: 'N' },
  ShowPrivacyPolicyLink: { ID: 7, Visible: 'N' },
  ShowPopupBasedPrivacyPolicyLink: { ID: 8, Visible: 'N' },

  Share_Scenario: { ID: 9, Visible: 'N' },
  Duplicate_Scenario: { ID: 10, Visible: 'N' },
  Export_Scenario: { ID: 11, Visible: 'N' },

  ApprovalWorkflow: { ID: 98, Visible: 'N' },
  Enable_PBC_Analogs: { ID: 88, Visible: 'N' },
  Enable_UBC_Analogs: { ID: 89, Visible: 'N' },
}

resourceFieldsGTN =  {
  GTNTeamTogglePlaceholder: { fieldName: 'GTNTeamTogglePlaceholder', text: '' },
  Client_Name: { fieldName: 'ClientName', text: '' },
  ClientLogo: { fieldName: 'ClientLogo', text: '' },
  CurrentScenarioVersion: { fieldName: 'CurrentScenarioVersion', text: '' },
  PrivacyPolicyDisclaimer: { fieldName: 'PrivacyPolicyDisclaimer', text: '' },
  PrivacyPolicyLink: { fieldName: 'PrivacyPolicyLink', text: '' },
  PrivacyPolicyDisclaimerHeader: { fieldName: 'PrivacyPolicyDisclaimerHeader', text: '' },
  UBCTeamTogglePlaceholder: { fieldName: 'UBCTeamTogglePlaceholder', text: '' },
  PBCTeamTogglePlaceholder: { fieldName: 'PBCTeamTogglePlaceholder', text: '' },
  RebateLaggedQuarters: { fieldName: 'RebateLaggedQuarters', text: '' },

  Brand: { fieldName: 'Brand', text: '' },
  TherapyArea: { fieldName: 'Therapy_Area', text: '' },
}
}
