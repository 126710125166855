import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor(public appService: AppService) { }

  ngOnInit() {
  }

  clearCookieOnSignout() {
  document.cookie = "code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  openHelpTabModal() {
    this.appService.openFAQSection('');
  }
  ngAfterViewInit(): void {
    let newLaunchbarRef = document.getElementById("newLaunchbar");
    if (newLaunchbarRef) {
      newLaunchbarRef.onmouseover = (function () {
        document.getElementById("launchbarPopup") && (document.getElementById("launchbarPopup").style.display = "block");
      });
      newLaunchbarRef.onmouseout = (function () {
        document.getElementById("launchbarPopup") && (document.getElementById("launchbarPopup").style.display = "none");
      });
    }
  }

}
