import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(items?: any[], filter?: any): any {
    if (!items || !filter) {
      return items;
    }
    if (typeof filter == 'function')
      return items = filter();
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    let key = Object.keys(filter)[0];

    if (key == 'customerPovDetailsFilter'){
      return items.filter(item => item["Visible"] == "Yes" || item["Line_Item"] == 'InflationRebate');
    }   
    if (!filter[key])
      return items;
    if (key == 'Therapy_Area' || (items[0]?.Line_Item && typeof items[0]?.Line_Item == 'string')){
      return items.filter(item => item[key] === filter[key]);
    }    
    if (items[0]){
      if (typeof items[0][key] === 'number'){
        return items.filter(item => item[key] == filter[key]);
      }
    }     
    return items.filter(item => item[key]?.indexOf(filter[key]) !== -1);
  }

}
