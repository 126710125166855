import { ServerApiService } from './../shared/services/server-api.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  serverHostUrl: string;

  constructor(
    private apisService: ServerApiService ) {
    this.serverHostUrl = this.apisService.getApi.serverHostUrl;
    //this.serverHostUrl = "http://app.zs.local:4200/api/";
  }

  //function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const updatedRequest = request.clone({
      url: `${this.serverHostUrl}${request.url}`,
      withCredentials: true,
    });

    return next.handle(updatedRequest).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // call sucess handler
          }
        },
        (error) => {
          //redirecting to IDM in case of a failure
          if (error.status === 401) {
            window.location.href = environment.idmUrl;
            // TODO::GS to get these URLS from backend
            // for dev
            // window.location.href = 'https://idm.dev.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://dev.cds.zsservices.com/projects';
            // for QA
            // window.location.href='https://idm.qa.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://dev.cds.zsservices.com/#/home';
            // for local
            // window.location.href = 'http://ev-qa02.zs.local/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=http://app.zs.local/projects';
            // for test
            // window.location.href = 'https://idm.qa.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://test.cds.zsservices.com/projects';
            // for staging
            // window.location.href = 'https://idm.staging.zsservices.com/Merck/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://cds.stg.merck.zsservices.com/projects';
            // for demoOld (demo)
            // window.location.href='https://idm.dev.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://demo.cds.zsservices.com/projects';
            // for demoNew (demo-branch-pfizer-2023)
            // window.location.href='https://idm.dev.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://cds-shrd.demo.zsservices.com/projects';
            // for production
            // window.location.href = 'https://idm.zsservices.com/Merck/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://cds.merck.zsservices.com/projects';
            // for pfzer
             //window.location.href = 'https://idm.qa.zsservices.com/CDS/IdentityManager/app/Web/Login.aspx?ReturnUrl=https://test-app.cds.zsservices.com/projects';
          } else if (error.status === 400) {
             document.cookie = "code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            // window.location.href = error.error.logoutUrl + "?ReturnUrl=" + window.location.origin.toString() + "/projects";
            window.location.href = error.error.logoutUrl;
          }
        }
      )
    );
  }
}
