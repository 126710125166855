import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  resources;
  resourceFields;
  constructor(public appService: AppService) {
    this.resources = this.appService.resources;
    this.resourceFields = this.appService.resourceFields;
   }

  ngOnInit() {
  }

}
