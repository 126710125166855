import { Interceptor } from './core/interceptor';
import { ProjectsModule } from './modules/common/projects/projects.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ScenarioComparisonModule } from './modules/common/scenario-comparison/scenario-comparison.module';
import { HelpTabComponent } from './modules/common/help-tab/help-tab.component';
import { BnNgIdleService } from 'bn-ng-idle'

@NgModule({
  declarations: [
    AppComponent,
    HelpTabComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,

    AppRoutingModule,
    SharedModule,
    ProjectsModule,
    ScenarioComparisonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
