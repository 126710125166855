<div [appLoading]="isLoading" class="scenario-comparison">
    <div class="scenario-comparison__header-row">
        <div class="button--cta" id="buttonAddScenario">
            <button class="zs-button zs-button-solid zs-border-rounded-0" (click)="addScenarioForComparison()">Add
                Scenario</button>
        </div>
    </div>
    <div class="scenario-comparison__scenario-table cds-bg-white">
        <table class="zs-table zs-table-condense zs-margin-2-0-0-0">
            <thead>
                <tr>
                    <th>Remove</th>
                    <th>Scenario Name</th>
                    <th>Deal</th>
                    <th>Anchor</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let scenario of scenariosToCompare; let row = index;">
                    <tr>
                        <td>
                            <span class="zs-icon zs-size-xl zs-icon-subtract"
                                (click)="removeScenarioFromComparison(scenario.ScenarioID)"></span>
                        </td>
                        <td>{{scenario.ScenarioName}}</td>
                        <td><label class="zs-checkbox">
                                <input type="checkbox" [(ngModel)]="scenario.deal"><span checkbox></span>
                            </label></td>
                        <td><label class="zs-checkbox">
                                <input type="checkbox" [(ngModel)]="scenario.anchor"
                                    (change)=anchorScenarioChecked(row)><span checkbox></span>
                            </label></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="button--cta">
            <button [ngClass]="{'zs-disabled': disableCompareScenariosButton()}"
                class="zs-button zs-button-solid zs-border-rounded-0" (click)="compareScenarios()">Compare
                Scenarios</button>
        </div>
    </div>
    <div *ngIf="showComparisonOutput"
        class="scenario-comparison__output-section cds-bg-white zs-width-100 zs-margin-2-0-0-0">
        <div class="filter zs-text-align-left">
            <p-is-zs-select #brandDpDwn *ngIf="productList?.length>0" class="zs-action-field zs-select" mode="action"
                hide-on-scroll label="Product:"
                (change)="selectedProductName = brandDpDwn.getValue(); createOutputGridForComparison(); fillScenarioComparisonGraph();"
                clear>
                <select>
                    <option *ngFor="let brand of productList" [value]="brand">{{brand}} </option>
                </select>
            </p-is-zs-select>
        </div>
        <div #chartContainer class="chart">
        </div>
        <div class="zs-display-flex zs-margin-1-0">
            <div class="counter-text">No. of selected scenarios for selected Brand and Channel
                :
                {{counterForCheckedScenariosCount}}
            </div>
            <div class="copy-clipboard">
                <span>
                    <a href="javascript:void(0)" title="Copy to Clipboard"
                        (click)="copyToExcel('scenarioComparison', true)" class="zs-icon zs-size-xl zs-icon-note"></a>
                    <a href="javascript:void(0)" (click)="copyToExcel('scenarioComparison', true)">Copy</a>
                </span>
            </div>
        </div>
        <div class="scenario-comparison__calculation-table zs-padding-0-0-2-0">
            <table class="zs-table zs-table-condense">
                <thead>
                    <tr>
                        <th>Scenario Name</th>
                        <th>Total Scenario Net Sales</th>
                        <th>Delta Scenario Net Sales (Compared to Anchor)</th>
                        <th>ROC (Compared to respective No Deal)</th>
                        <th>Exit Share %</th>
                        <th>Discretionary Rebate %</th>
                        <th>Total Rebate %</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let scenario of comparisonOutputGrid; let row = index;">
                        <tr mark *ngIf="row == 0">
                            <td><a href="javascript:void(0)"
                                    (click)=openScenario(scenario.ScenarioId)>{{scenario.ScenarioName}} - No Deal</a>
                            </td>
                            <td>{{scenario.NetSales | currency:"$"}}</td>
                            <td>{{scenario.DeltaNetSales}}</td>
                            <td>{{scenario.ROC}}</td>
                            <td>{{scenario.ExitShare/100 | percent: '1.2-2'}}</td>
                            <td>{{scenario.DiscretionaryRebates/100 | percent: '1.2-2'}}</td>
                            <td>{{scenario.TotalRebate/100 | percent: '1.2-2'}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let scenario of comparisonOutputGrid; let row = index;">
                        <tr *ngIf="row != 0">
                            <td><a href="javascript:void(0)"
                                    (click)=openScenario(scenario.ScenarioId)>{{scenario.ScenarioName}} - Deal</a></td>
                            <td>{{scenario.NetSales | currency:"$"}}</td>
                            <td>{{scenario.DeltaNetSales | currency:"$"}}</td>
                            <td>{{scenario.ROC}}</td>
                            <td>{{scenario.ExitShare/100 | percent: '1.2-2'}}</td>
                            <td>{{scenario.DiscretionaryRebates/100 | percent: '1.2-2'}}</td>
                            <td>{{scenario.TotalRebate/100 | percent: '1.2-2'}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <!-- add scenario modal -->
    <zs-dialog modal class="zs-dialog modal" #addScenarioRef role="dialog">
        <header>
            <span close="" onclick="this.parentNode.parentNode.close()">
                <a href="javascript:;" class="zs-icon zs-icon-close-circle-fill zs-size-xl"></a>
            </span>
        </header>
        <section class="comparison-inputs">
            <div class="comparison-inputs__filters">
                <div class="search">
                    <p-is-zs-action-field class="search__search-box zs-action-field zs-action-field-solid zs-border"
                        type="text" placeholder="Search..." [(ngModel)]="searchComparisonScenarios"
                        (input)="getFilteredScenarios('search')" autocomplete="off" icon="search" action-icon="collapse"
                        ngDefaultControl>
                    </p-is-zs-action-field>

                </div>
                <div class="filter">
                    <p-is-zs-select #brandDpDwn *ngIf="brandList?.length>0" class="zs-action-field zs-select"
                        mode="action" hide-on-scroll label="Product:"
                        (change)="selectedBrandName = brandDpDwn.getValue(); getFilteredScenarios(null); resetSelectedScenarioCount();"
                        clear>
                        <select>
                            <option *ngFor="let brand of brandList" [value]="brand">{{brand}} </option>
                        </select>
                    </p-is-zs-select>
                </div>
                <div class="filter">
                    <p-is-zs-select #channelDpDwn *ngIf="ChannelList?.length>0" class="zs-action-field zs-select"
                        mode="action" hide-on-scroll label="Channel:"
                        (change)="selectedChannel = channelDpDwn.getValue(); getFilteredScenarios(null); resetSelectedScenarioCount();"
                        clear>
                        <select>
                            <option *ngFor="let channel of ChannelList" [value]="channel">{{channel}} </option>
                        </select>
                    </p-is-zs-select>
                </div>
            </div>
            <div class="comparison-inputs__table">
                <div class="counter-text">No. of selected scenarios for selected Brand and Channel :
                    {{counterForCheckedScenariosCount}}</div>
                <table class="zs-table zs-table-condense">
                    <thead>
                        <tr>
                            <th>Add</th>
                            <th>Scenarios</th>
                            <th>Project Name</th>
                            <th>Brand</th>
                            <th>Channel</th>
                            <th>Project Owner</th>
                            <th>Modified Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let scenario of scenarioListForComparison">
                            <tr>
                                <td>
                                    <label class="zs-checkbox"
                                        [ngClass]="{'zs-disabled': (scenario.checkedForComparison == false && counterForCheckedScenariosCount == 4)}">
                                        <input type="checkbox" [(ngModel)]="scenario.checkedForComparison"
                                            (change)=counterForCheckedScenarios(scenario)><span checkbox></span>
                                    </label>
                                </td>
                                <td>{{scenario.ScenarioName}}</td>
                                <td>{{scenario.ProjectName}}</td>
                                <td>{{scenario.BrandName}}</td>
                                <td>{{scenario.Channel}}</td>
                                <td>{{scenario.ProjectOwner}}</td>
                                <td>{{scenario.DateModified| date:'dd MMM yyyy'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="comparison-inputs__pagination">
                <span id="pagination-status">Showing {{(paginationOptions.currentPage - 1)*paginationOptions.pageSize +
                    1}}-{{(paginationOptions.currentPage*paginationOptions.pageSize)>paginationOptions.itemsCount?paginationOptions.itemsCount:(paginationOptions.currentPage*paginationOptions.pageSize)}}
                    of
                    {{paginationOptions.itemsCount}}&nbsp;&nbsp;&nbsp;</span>
                <zs-pagination (pagechange)="pageChange($event)" *ngIf="paginationOptions?.itemsCount > 0"
                    class="zs-pagination" mode="action" [itemsCount]="paginationOptions.itemsCount"
                    [maxDisplayPages]="paginationOptions.maxDisplayPages" [pageSize]="paginationOptions.pageSize"
                    [currentPage]="paginationOptions?.currentPage">
                </zs-pagination>
            </div>
        </section>
        <footer class="footer">
            <a class="footer__cancel-btn zs-button zs-border-rounded-0" href="javascript:;"
                onclick="this.parentNode.parentNode.close()">Cancel</a>
            <button class="zs-button zs-button-action zs-border-rounded-0"
                (click)="confirmScenarioComparison()">Confirm</button>
        </footer>
    </zs-dialog>
</div>