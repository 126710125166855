import { AppService } from './../app.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  implements CanActivate {

  constructor(public appService:AppService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(this.appService.loggedInUser.team == '')
      return false;
    if (route.data.team && this.appService.loggedInUser.team != route.data.team) {

      return false;
    }
    return true;
  }

}
