
<section id="page" *ngIf="appService?.loggedInUser?.name">
  <app-header style="z-index: 2147483647 !important;" class="zs-border-bottom zs-bg-standard"></app-header>
  <app-side-nav class="zs-layout-full-sidenav"></app-side-nav>
  <main class="main-page">

    <app-alert-box *ngIf="appService.showAlert.show"></app-alert-box>

    <div class="main-page__header zs-border-bottom zs-bg-standard">
      <div class="main-page__header__title"><h4>{{pageTitle| uppercase}}</h4></div>
      <div class="main-page__header__refreshDate">
        <span class="zs-icon zs-size-l zs-icon-info-fill"></span>
        <span class="zs-size-m">Current System Data: Through {{appService.lastDataRefreshDate | date: 'MMMM yyyy'}}</span>
      </div>
    </div>
    <app-help-tab *ngIf="appService.openFAQSectionFlag && appService.loggedInUser.team != '' && appService.loggedInUser.team != 'IPnC'"></app-help-tab>
    <div id="mainPageContent" class="main-page__content" [appLoading]="isLoading">
      <router-outlet (activate) = "updatePageTitle($event);"></router-outlet>
    </div>
  </main>
  <app-footer *ngIf="(pageTitle| lowercase) == 'projects & scenarios'"></app-footer>
</section>
