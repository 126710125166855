import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoadingDirective } from './directives/loading.directive';
import { ToggleAppComponent } from './components/toggle-app/toggle-app.component';
import { UniquePipe } from './pipes/unique.pipe';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { SafeHtmlPipe } from "./pipes/safeHtml";
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { DownloadPdfComponent } from './components/download-pdf/download-pdf.component';
import { ShareMatrixComponent } from './components/share-matrix/share-matrix.component';
import { PercentToNationComponent } from './components/percent-to-nation/percent-to-nation.component';
import { DynamicCalcComponent } from './components/dynamic-calc/dynamic-calc.component';
import { ScenarioApprovalComponent } from './components/approval-workflow/scenario-approval/scenario-approval.component';
import { PendingScenarioListComponent } from './components/approval-workflow/pending-scenario-list/pending-scenario-list.component';
import { MasterPriceProtectionComponent } from './components/master-price-protection/master-price-protection.component';
import { GridComponent } from './components/grid/grid.component';
import { TemplateComponent } from './components/template/template.component';
import { ReusableTableComponent } from './components/reusable-table/reusable-table.component';
import { CommaAdderPipe } from './pipes/comma-adder.pipe';
import { ReusableGraphComponent } from './components/reusable-graph/reusable-graph.component';

@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    SideNavComponent,
    PageNotFoundComponent,
    LoadingDirective,
    ToggleAppComponent,
    ArrayFilterPipe,
    UniquePipe,
    AlertBoxComponent,
    SafeHtmlPipe,
    DownloadPdfComponent,
    ShareMatrixComponent,
    PercentToNationComponent,
    DynamicCalcComponent,
    ScenarioApprovalComponent,
    PendingScenarioListComponent,
    MasterPriceProtectionComponent,
    GridComponent,
    TemplateComponent,
    ReusableTableComponent,
    CommaAdderPipe,
    ReusableGraphComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [HeaderComponent, FooterComponent, SideNavComponent, PageNotFoundComponent, LoadingDirective, ToggleAppComponent, SafeHtmlPipe,
    ArrayFilterPipe,
    UniquePipe,
    AlertBoxComponent,
    DownloadPdfComponent,
    ShareMatrixComponent,
    PercentToNationComponent,
    DynamicCalcComponent,
    ScenarioApprovalComponent,
    PendingScenarioListComponent,
    MasterPriceProtectionComponent,
    GridComponent,
    TemplateComponent,
    ReusableTableComponent,
    ReusableGraphComponent,
    CommaAdderPipe
  ],

})
export class SharedModule { }
