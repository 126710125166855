import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApprovalWorkflowService } from 'src/app/shared/services/approval-workflow.service';
import { PassMasterScenarioService } from 'src/app/shared/services/pass-master-scenario.service';
import { SortService } from 'src/app/shared/services/sort.service';

@Component({
  selector: 'app-pending-scenario-list',
  templateUrl: './pending-scenario-list.component.html',
  styleUrls: ['./pending-scenario-list.component.scss']
})
export class PendingScenarioListComponent implements OnInit {

  @Input() statusOptions: any;
  @ViewChild('listSection')
  listSection: ElementRef<any>;
  pendingScenariosList: any = [];
  completedScenariosList: any = [];
  sortOrder = false;
  sortColumn = 'date1';
  paginationOptionsPending = {
    itemsCount: 5,
    pageSize: 5,
    currentPage: 1,
    maxDisplayPages: 3
  };
  startIndexPending = 0;
  endIndexPending = 4;
  paginationOptionsActionTaken = {
    itemsCount: 5,
    pageSize: 5,
    currentPage: 1,
    maxDisplayPages: 3
  };
  startIndexActionTaken = 0;
  endIndexActionTaken = 4;
  masterScenario: any = {
    MasterID: 0,
    MasterScName: "",
    ChildScenarioCount: 0
  };

  constructor(public appService: AppService, private approvalWorkflowService: ApprovalWorkflowService, private route: Router, private sortService: SortService, private passMasterScenarioService: PassMasterScenarioService) { }

  ngOnInit(): void {
    this.approvalWorkflowService.getApproverScenarios().subscribe(data => {
      this.pendingScenariosList = data.filter(scenario => { return scenario.IsActive == 'Y' });
      this.completedScenariosList = data.filter(scenario => { return scenario.IsActive == 'N' });
      this.pendingScenariosList = this.pendingScenariosList.sort((a, b) => (a.DateModified > b.DateModified ? -1 : 1));
      this.completedScenariosList = this.completedScenariosList.sort((a, b) => (a.DateModified > b.DateModified ? -1 : 1));
    });
  }

  toggleListSection(event) {
    var sourceNode = <HTMLElement>event.target;
    //to make caret icon clickable
    if (sourceNode.className.includes("zs-icon"))
      sourceNode = <HTMLElement>sourceNode.nextSibling;
    var targetNode = this.listSection.nativeElement;
    var isOpen = !targetNode.classList.contains('zs-display-none');
    if (!isOpen) {
      sourceNode.previousElementSibling.classList.replace('zs-icon-carat-down', 'zs-icon-carat-up');
      targetNode.classList.remove('zs-display-none');
    } else {
      sourceNode.previousElementSibling.classList.replace('zs-icon-carat-up', 'zs-icon-carat-down');
      targetNode.classList.add('zs-display-none');
    }
  }

  openScenario(scenario) {
    if (this.appService.loggedInUser.team.toLowerCase() == 'ubc') {
      if (scenario.ParentType == 'Parent') {
        //TODO::RM- Remove pass service functionality later
        this.masterScenario.MasterID = scenario.ScenarioID;
        this.passMasterScenarioService.setScenario(this.masterScenario);
        this.route.navigate(['/ubc/masterScenario', btoa(this.masterScenario.MasterID)]);
      }
      else {
        this.route.navigate(['/ubc/scenario', btoa(scenario.ScenarioID)]);
      }
    }
    else {
      this.route.navigate(['/pbc/scenario', btoa(scenario.ScenarioID)]);
    }
  }

  getStatusFromID(statusID) {
    return this.statusOptions.filter((obj: any) => { return obj.Status_ID === statusID })[0].Status;
  }

  performSort(column) {
    this.sortOrder = this.sortService.sortData(column);
    this.sortColumn = this.sortService.getSortColumn();
  }

  pageChangePending($event) {
    this.paginationOptionsPending.currentPage = $event.detail.newPage;
    var newValue = $event.detail.newPage;
    var pageSize = this.paginationOptionsPending.pageSize;
    var startIndex = pageSize * (newValue - 1);
    var endIndex = startIndex + pageSize - 1;
    this.startIndexPending = startIndex;
    this.endIndexPending = endIndex;
  }

  pageChangeActionTaken($event) {
    this.paginationOptionsActionTaken.currentPage = $event.detail.newPage;
    var newValue = $event.detail.newPage;
    var pageSize = this.paginationOptionsActionTaken.pageSize;
    var startIndex = pageSize * (newValue - 1);
    var endIndex = startIndex + pageSize - 1;
    this.startIndexActionTaken = startIndex;
    this.endIndexActionTaken = endIndex;
  }

}
