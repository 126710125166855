<ng-content></ng-content>
<div class="header">
  <div class="header__icon">
    <a routerLink="projects">
      <img [src]="appService.resourceFields.ClientLogo.text" alt="logo not found">
    </a>
  </div>
  <div class="header__title zs-size-xl zs-text-data-color-3 zs-font-weight-semi-bold">Contract Decision Support</div>
  <div class="header__nav">
    <div class="header__nav__app--cta" *ngIf="appService.showToggleApp"><a routerLink="/selectApplication"> <span
          class="zs-icon zs-size-xl zs-icon-app-nav"></span><span> Applications</span></a>
    </div>
    <div class="header__nav__faq--cta" *ngIf="appService.loggedInUser.team != '' && appService.loggedInUser.team != 'IPnC'"><a (click)="openHelpTabModal()"> <span
          class="zs-icon zs-size-xl zs-icon-help-fill"></span> <span> FAQs</span></a></div>
    <div id="newLaunchbar" class="header__nav__faq--cta">
      <a> <span class="zs-icon zs-size-xl zs-icon-user-fill"></span> <span>
          {{appService.loggedInUser.name}}</span></a>
      <div id="launchbarPopup" class="launch-bar" *ngIf="appService.idmLaunchbar" style="display: none;">
        <div class="bullets">
          <a href="{{appService.idmLaunchbar.myAccount}}" *ngIf="appService.idmLaunchbar.myAccount"  id="lbl_ma" class="lbar-link"
            onclick="return ZSLB.LinkClick('ma')"><span class="zs-icon zs-size-xl zs-icon-home-fill"></span>My
            Account</a>
        </div>
        <div class="bullets">
          <a href="{{appService.idmLaunchbar.signOut}}"
            (click) ="clearCookieOnSignout();"
            id="lbl_lo" class="lbar-link"
            onclick="return ZSLB.LinkClick('ma')"> <span class="zs-icon zs-size-xl zs-icon-logout"> </span>Sign Out</a>
        </div>
      </div>
    </div>
  </div>
</div>
